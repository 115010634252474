<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex justify-center min-h-full p-4 text-center items-center">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl dark:bg-neutral-950 my-8 w-fit max-w-lg p-6">
              <div class="absolute top-0 right-0 pt-4 pr-4 block">
                <button type="button" class="text-gray-400 rounded-md hover:text-gray-500 dark:hover:text-neutral-300 dark:text-neutral-400 focus:outline-none" @click="open = false">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <!-- Content -->
              <div class="flex items-start px-5 pb-5">

                <!-- Check -->
                <div v-if="props.type == 'check'" class="flex flex-col justify-center h-fit w-full sm:w-[500px]">
                  <div class="primary_header mb-4">Check instructions</div>
                  <div class="relative flex flex-col gap-5 flex-wrap p-5 bg-white dark:bg-neutral-900 rounded-lg shadow-opera dark:shadow-none dark:ring-1 dark:ring-neutral-800">
                    <!-- Copy Button -->
                    <div class="absolute w-full flex justify-end items-start top-1 right-1">
                      <div class="button_secondary" @click="copyToClipboard(check_instructions)">
                        Copy
                        <CheckIcon v-if="copied" class="w-4 h-4 text-green-600" />
                        <ClipboardIcon v-else class="w-4 h-4" />
                      </div>
                    </div>

                    <!-- Fedex or UPS instructions -->
                    <div>
                      <dt class="text-sm font-medium text_color_primary">Mailing Instructions</dt>
                      <dd class="mt-1 text-sm text_color_tertiary">{{ mailing_instructions }}</dd>
                    </div>

                    <!-- Regular mail instructions -->
                    <div>
                      <dt class="text-sm font-medium text_color_primary">Make Check Payable To</dt>
                      <dd class="mt-1 text-sm text_color_tertiary">{{ payable_instructions }}</dd>
                    </div>
                  </div>

                </div>

                <!-- Wire -->
                <div v-else-if="props.type == 'wire'" class="flex flex-col justify-center h-fit w-full sm:w-[500px]">
                  <div class="primary_header mb-4">Wire instructions</div>
                  <div class="relative flex flex-col gap-10 flex-wrap p-5 bg-white dark:bg-neutral-900 rounded-lg shadow-opera dark:shadow-none dark:ring-1 dark:ring-neutral-800">
                    <!-- Copy Button -->
                    <div class="absolute w-full flex justify-end items-start top-1 right-1">
                      <div class="button_secondary" @click="copyToClipboard(wire_instructions)">
                        Copy
                        <CheckIcon v-if="copied" class="w-4 h-4 text-green-600" />
                        <ClipboardIcon v-else class="w-4 h-4" />
                      </div>
                    </div>

                    <div class="flex gap-10 items-center flex-wrap">
                      <!-- Account Number -->
                      <div>
                        <dt class="text-sm font-medium text_color_primary">Account Number</dt>
                        <dd class="mt-1 text-sm text_color_tertiary">{{ account_number }}</dd>
                      </div>

                      <!-- Routing Number -->
                      <div>
                        <dt class="text-sm font-medium text_color_primary">ABA (Routing Number)</dt>
                        <dd class="mt-1 text-sm text_color_tertiary">{{ routing_number }}</dd>
                      </div>
                    </div>

                    <div class="flex gap-10 items-center flex-wrap">
                      <!-- Wire To -->
                      <div>
                        <dt class="text-sm font-medium text_color_primary">Wire To</dt>
                        <dd class="mt-1 text-sm text_color_tertiary">{{ wire_to }}</dd>
                      </div>

                      <!-- FFCT -->
                      <div>
                        <dt class="text-sm font-medium text_color_primary">FFCT</dt>
                        <dd class="mt-1 text-sm text_color_tertiary">{{ ffct }}</dd>
                      </div>

                      <!-- Swift ID -->
                      <div>
                        <dt class="text-sm font-medium text_color_primary">Swift ID</dt>
                        <dd class="mt-1 text-sm text_color_tertiary">{{ swift_id }}</dd>
                      </div>
                    </div>

                    <!-- Custodian Account Name -->
                    <div>
                      <dt class="text-sm font-medium text_color_primary">Custodian Account Name</dt>
                      <dd class="mt-1 text-sm text_color_tertiary">{{ custodian_account_name }}</dd>
                    </div>
                  </div>

                </div>

              </div>
              <!-- Content -->
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// Essentials
import { ref } from 'vue'

// Libraries
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/20/solid'
import { CheckIcon, ClipboardIcon } from '@heroicons/vue/24/outline'

// Model
const open = defineModel()

// Props
const props = defineProps({
  type: { type: String, required: true, default: 'wire' }
})

// Globals
const copied = ref(false)

const mailing_instructions = 'NORTH CAPITAL PRIVATE SECURITIES, 623 E FORT UNION BLVD, SUITE 101 MIDVALE, UT 84047'
const payable_instructions = 'The North Capital Private Securities, FBO NC Account Number & Name on Account'
const check_instructions = ref(`Mailing Instructions: ${mailing_instructions}\n Make Payable To: ${payable_instructions}`)

const wire_to = 'TRISTATE CAPITAL BANK ONE OXFORD CENTRE, SUITE 2700 301 GRANT STREET, PIT TSBURGH, PA 15219'
const account_number = '0220004295'
const routing_number = '043019003'
const custodian_account_name = 'NORTH CAPITAL PRIVATE SECURITIES, 623 E FORT UNION BLVD, SUITE 101, MIDVALE, UT 84047'
const ffct = 'NC ACCOUNT NUMBER AND NAME ON ACCOUNT'
const swift_id = 'TRTTUS33'
const wire_instructions = ref(`Wire To: ${wire_to}\n Account Number: ${account_number}\n ABA (Routing Number): ${routing_number}\n FFCT: ${ffct}\n Swift ID: ${swift_id}\n Custodian Account Name: ${custodian_account_name}`)

const copyToClipboard = async (text) => {
  copied.value = true;
  navigator.clipboard.writeText(text);
  await new Promise(resolve => setTimeout(resolve, 1000));
  copied.value = false;
  copied.value = false;
};
</script>