<template>
  <main class="flex min-h-screen pt-24 bg-center justify-center" :style="{ backgroundImage: `url(${partnerStore.getBackground})` }">
    <div class="text-center flex flex-col items-center bg-white w-fit h-fit rounded-2xl py-8 px-8 bg-opacity-80">
      <h1 class="text-3xl font-bold tracking-tight sm:text-5xl">Thank You</h1>
      <p class="mt-6 text-base font-semibold leading-7 text-black dark:text-neutral-400">Please check your email for documents that may require your signature.</p>
      <div class="flex items-center justify-center mt-10 gap-x-6">
        <router-link to="/dashboard/holdings" class="button_primary">
          <ArrowLongLeftIcon class="w-4 h-4" />
          Sign Dashboard Now
        </router-link>
        <!-- <div @click="goToDocs()" class="button_secondary">Sign documents now <PencilIcon class="w-4 h-4" /> </div> -->
      </div>
    </div>
  </main>
</template>

<script setup>
// Essentials
import { onMounted } from 'vue'
import { usePartnerStore } from '@/stores/partner'
// Components
// Libraries
import { ArrowLongLeftIcon } from '@heroicons/vue/24/outline'
// Stores
const partnerStore = usePartnerStore()
// Globals
// Mounted
onMounted(async () => {
  // Check Stores
  if (!partnerStore.partner) await partnerStore.setPartner(); 
})
// Computed
// Functions
// function goToDocs() {
//   this.$router.push({ path: `/dashboard/hodlings/account-details/documents/${this.$route.params.id}` })
// }
// Watchers
</script>

