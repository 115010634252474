<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75 dark:bg-neutral-500 dark:bg-opacity-75" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex justify-center min-h-full p-4 text-center items-center">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-gray-50 rounded-lg shadow-xl dark:bg-neutral-900 my-8 w-fit max-w-lg p-6">
              <div class="absolute top-0 right-0 pt-4 pr-4 block">
                <button type="button" class="text-gray-400 rounded-md hover:text-gray-500 outline-none dark:hover:text-neutral-300 dark:text-neutral-400" @click="open = false">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="w-6 h-6" aria-hidden="true" />
                </button>
              </div>

              <!-- Content -->
              <div class="flex items-start w-fit">
                <div class="flex items-center justify-center flex-shrink-0 mx-auto dark:bg-yellow-950 bg-yellow-100 rounded-full h-10 w-10">
                  <ExclamationTriangleIcon class="w-6 h-6 text-yellow-600" aria-hidden="true" />
                </div>
                <div class="flex-shrink mt-2 mr-8 ml-4 text-left">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 dark:text-white">Change Role</DialogTitle>
                  <div class="flex flex-col gap-5 my-2">
                    <div class="rounded-lg bg-yellow-100 dark:bg-yellow-950 p-4 mt-3">
                      <div class="flex">
                        <div>
                          <h3 class="text-sm font-medium text-yellow-800 dark:text-yellow-300">Attention needed</h3>
                          <div class="mt-2 text-sm text-yellow-700 dark: dark:text-yellow-500">
                            <p>Please note that changing a user's role will greatly influence thier experience and permissions on the site. Don't make any changes to a user's role unless you are
                              certain!</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-col">
                      <!-- Role -->
                      <div class="select_menu_label">Role</div>
                      <SelectMenu v-model="selectedRole" :items="props.roles" />
                    </div>
                    <div class="flex flex-col gap-3">
                      <p class="text-sm text-gray-500 dark:text-neutral-400">Name: {{ props.user.parties?.first_name || '-' }} {{ props.user.parties?.last_name ?? '' }}</p>
                      <p class="text-sm text-gray-500 dark:text-neutral-400">Email: {{ props.user?.email ?? '-' }}</p>
                    </div>

                  </div>

                </div>
              </div>
              <div class="flex gap-4 w-full justify-end items-center mt-5">
                <button type="button" class="button_secondary" @click="open = false">Close</button>
                <button type="button" class="button_yellow" @click="updateRole()">Save Changes</button>
              </div>
              <!-- Content -->

            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// Essentials
import { ref } from 'vue';
import { useProfilesStore } from '@/stores/profiles'
// Components
import SelectMenu from '../applicationui/SelectMenu.vue';
// Libraries
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/20/solid'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
// Props
const props = defineProps({
  user: { type: Object, required: true, default: null },
  roles: { type: Array, required: true, default: null },
  currentRole: { type: Object, required: true, default: null }
})
// Emits
const emit = defineEmits(['role-updated'])
// Models
const open = defineModel()
// Stores
const profilesStore = useProfilesStore();
// Globals
const selectedRole = ref(props.currentRole);
// Functions
async function updateRole() {
  await profilesStore.updateRole(props.user.id, selectedRole.value.value);
  emit('role-updated');
  open.value = false;
};
</script>