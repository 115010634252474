<template>
  <div class="flex flex-col gap-5 p-5 mt-2">

    <div class="text-lg dark:text-white font-semibold">Signing Method</div>
    <RadioGroup label="Account Type" v-model="selected_signing_method" :options="availableSigningMethods" class="mt-2 mb-8 max-w-xl" />

    <div class="flex flex-row-reverse items-center justify-between">
      <button @click="handleClick('next')" class="self-end button_primary">
        Next
        <ArrowRightIcon class="w-4 h-4" />
      </button>
    </div>

  </div>
</template>

<script setup>
// Components
import RadioGroup from '@/components/applicationui/RadioGroup.vue';

// Libraries
import { ArrowRightIcon } from '@heroicons/vue/24/outline';
import { onMounted } from 'vue';

// Globals
const availableSigningMethods = [
  { name: 'Sign Now', value: 'sign-now', description: 'Sign now on this device (Recommended)' },
  { name: 'Sign Later', value: 'email', description: 'Sign later, we will send you and email with the documents that required you signature' }
]

// Models
const selected_signing_method = defineModel();

// Emits
const emit = defineEmits(['next']);

onMounted(() => {
  selected_signing_method.value = availableSigningMethods[0];
});

// Functions
const handleClick = (emitted) => { emit(emitted) }
</script>