<template>
  <div class="flex flex-col gap-5 p-5 mt-2">

    <div class="text-lg dark:text-white font-semibold">Account Type</div>
    <RadioGroup label="Account Type" v-model="selected_account_type" :options="availableAccountTypes" class="mt-2 mb-8 max-w-xl" />

    <div class="flex flex-row-reverse items-center justify-between">
      <button @click="handleClick('next')" class="self-end button_primary">
        Next
        <ArrowRightIcon class="w-4 h-4" />
      </button>
      <button v-if="props.showPreviousButton" @click="handleClick('previous')" class="self-end button_secondary">
        <ArrowLeftIcon class="w-4 h-4" />
        Previous
      </button>
    </div>

  </div>
</template>

<script setup>
// Components
import RadioGroup from '@/components/applicationui/RadioGroup.vue';

// Libraries
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/vue/24/outline';
import { onMounted } from 'vue';

// Globals
const availableAccountTypes = [
  { name: 'Individual', value: 'individual', description: 'An individual account is owned by a single person.' },
  { name: 'Entity', value: 'entity', description: 'An entity account represents a company or group but will still require the personal information of the agent.' }
]

// Models
const selected_account_type = defineModel();

// Props
const props = defineProps({
  showPreviousButton: { type: Boolean, required: true, default: false },
});

// Emits
const emit = defineEmits(['next', 'previous']);

onMounted(() => {
  selected_account_type.value = availableAccountTypes[0];
});

// Functions
const handleClick = (emitted) => { emit(emitted) }
</script>