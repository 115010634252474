<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex items-end justify-center min-h-full p-2 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative px-0 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl dark:bg-neutral-900">

              <!-- Content -->
              <div class="w-[500px] h-[400px] -mt-10 -mb-10 -mx-2">
                <iframe class="w-full h-full" :src="url" frameBorder="0"></iframe>
              </div>

            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// Essentials
import { ref, onMounted, watch } from 'vue'
import { useAccountsStore } from '@/stores/accounts';

// Libraries
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'

// Stores
const accountsStore = useAccountsStore();

// Props
const props = defineProps({
  account_id: { type: String, required: true, default: null },
  tapi_account_id: { type: String, required: true, default: null },
  update: { type: Boolean, required: false, default: false },
})

// Emits
const emit = defineEmits(['submitted', 'failure', 'closed'])

// Models
const open = defineModel()

// Globals
const url = ref('')

// Mounted
onMounted(() => {
  getStripeUrl(props.account_id, props.tapi_account_id, props?.update);
})

// Functions
async function getStripeUrl(account_id, tapi_account_id, update) {
  var endpoint = 'stripe'
  if (update) endpoint = 'update-stripe'

  // Get the URL
  let data = null;
  if (props.update) data = await accountsStore.updateStripe(account_id, tapi_account_id)
  else data = await accountsStore.getStripe(account_id, tapi_account_id)

  // Check if the data is null
  if (!data) {
    open.value = false
    emit('failure');
    return;
  }

  // Set the URL
  url.value = data.accountDetails;

  // Listen for the event message
  window.addEventListener("message", (event) => {
    if (!event.origin.includes('norcapsecurities.com')) return;
    if (!event) return
    if (!event.data) return
    if (!event.type) return
    if (event.type != 'message') return
    if (typeof event.data != 'string') return
    if (event.data == 'signing_complete') return // (This is needed since I never remove this event listener in some cases)
    let response = JSON.parse(event.data);
    if (response.statusCode == 101 || response.statusCode == '101') {
      open.value = false
      emit('submitted');
    }
    else if (response.errorCode == '404') open.value = false
    else {
      open.value = false
      emit('failure');
    }
  });
}

// Watchers
watch(open, async () => {
  if (!open.value) {
    await new Promise(resolve => setTimeout(resolve, 400))
    emit('closed')
  }
})
</script>