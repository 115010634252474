<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex justify-center min-h-full p-4 text-center items-center">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl dark:bg-neutral-900 my-8 w-fit max-w-lg p-6">
              <div class="absolute top-0 right-0 pt-4 pr-4 block">
                <button type="button" class="text-gray-400 rounded-md hover:text-gray-500 dark:hover:text-neutral-300 dark:text-neutral-400 focus:outline-none" @click="open = false">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
              <!-- Content -->
              <div class="flex items-start min-w-fit">
                <div class="flex items-center justify-center flex-shrink-0 mx-auto bg-blue-100 dark:bg-blue-950 rounded-full h-10 w-10">
                  <ClipboardDocumentListIcon class="w-6 h-6 text-blue-500 dark:text-blue-600" aria-hidden="true" />
                </div>
                <div class="flex-shrink mt-3 mr-8 ml-4 text-left">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 dark:text-white mr-10">Update Transaction Status</DialogTitle>

                  <div class="my-5 max-w-52">
                    <!-- Approval Status -->
                    <div class="select_menu_label">Status</div>
                    <SelectMenu v-model="status" :items="statuses" />
                  </div>

                  <div class="flex flex-col gap-2 my-5">
                    <p v-if="transaction?.offering_id" class="text-sm text-gray-500 dark:text-neutral-400">
                      <span class="text-gray-600 dark:text-neutral-300">Offering:</span> {{ transaction?.offerings?.name ?? '-' }}
                    </p>
                    <p class="text-sm text-gray-500 dark:text-neutral-400">
                      <span class="text-gray-600 dark:text-neutral-300">Transaction #:</span> {{ transaction?.tapi_trade_id ?? '-' }}
                    </p>
                    <p class="text-sm text-gray-500 dark:text-neutral-400">
                      <span class="text-gray-600 dark:text-neutral-300">Account:</span> {{ transaction?.accounts?.name ?? '-' }}
                    </p>
                    <p class="text-sm text-gray-500 dark:text-neutral-400">
                      <span class="text-gray-600 dark:text-neutral-300">Type:</span> {{ capitalizeFirstLetter(transaction?.type) ?? '-' }}
                    </p>
                    <p class="text-sm text-gray-500 dark:text-neutral-400">
                      <span class="text-gray-600 dark:text-neutral-300">Total Value:</span> {{ formatMoney(transaction?.amount * transaction?.price_per_unit) }}
                    </p>
                  </div>

                </div>
              </div>
              <div class="flex gap-4 mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <div class="button_primary" @click="handleSavedChanges(transaction, status)">Save
                  <LoadGifButton v-if="updating" />
                </div>
                <div class="button_secondary" @click="open = false">Cancel</div>
              </div>
              <!-- Content -->
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// Essentials
import { ref, onMounted, watch } from 'vue'
import { useTransactionsStore } from '@/stores/transactions'
import { usePositionsStore } from '@/stores/positions'
import { useEmailsStore } from '@/stores/emails'
// Components
import SelectMenu from '@/components/applicationui/SelectMenu.vue'
import LoadGifButton from '@/components/loading/LoadGifButton.vue'
// Libraries
import { capitalizeFirstLetter, formatMoney } from '@/helper/helper'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ClipboardDocumentListIcon, XMarkIcon } from '@heroicons/vue/24/outline'
// Props
const props = defineProps({
  show: { type: Boolean, required: true, default: false }
})
// Emits 
const emit = defineEmits(['closed', 'updated', 'error'])
// Models
const transaction = defineModel()
// Stores
const transactionsStore = useTransactionsStore()
const positionsStore = usePositionsStore()
const emailsStore = useEmailsStore()
// Globals
const open = ref(false)
const updating = ref(false)
const status = ref({ id: 2, label: 'Pending', value: 'pending' })
const statuses = [
  { id: 1, label: 'Settled', value: 'settled' },
  { id: 2, label: 'Pending', value: 'pending' },
  { id: 3, label: 'Refund Pending', value: 'refund_pending' },
  { id: 4, label: 'Refunded', value: 'refunded' },
  { id: 5, label: 'Canceled', value: 'canceled' },
  { id: 6, label: 'Disapproved', value: 'disapproved' }
]
// Mounted
onMounted(() => {
  open.value = props.show
  if (transaction.value.status == 'settled') status.value = statuses[0]
  else if (transaction.value.status == 'pending') status.value = statuses[1]
  else if (transaction.value.status == 'refund_pending') status.value = statuses[2]
  else if (transaction.value.status == 'refunded') status.value = statuses[3]
  else if (transaction.value.status == 'canceled') status.value = statuses[4]
  else if (transaction.value.status == 'disapproved') status.value = statuses[5]
})

// Functions
async function handleSavedChanges(currentTransaction, newStatus) {
  // Check if status is the same as current status and return if it is
  if (newStatus.value == currentTransaction.status) return

  updating.value = true
  // Send notification to the contact email 
  emailsStore.sendTransactionNotification(currentTransaction.accounts?.parties?.contact_email, newStatus.label, currentTransaction?.partners?.meta_name)

  // Update transaction status
  const updateSuccessful = await transactionsStore.updateTransactionStatus(currentTransaction.id, newStatus.value)
  if(!updateSuccessful) { emit('error'); return }

  // If the status is not settled return successful
  if (newStatus.value != 'settled') {
    updating.value = false
    emit('updated')
    open.value = false
    return
  }

  // Create position in supabase if the status is settled
  const successful = await createPositionInSupabase(currentTransaction)
  if (!successful) { emit('error'); return }
  updating.value = false
  emit('updated')
  open.value = false
}

async function createPositionInSupabase(currentTransaction) {
  let oldAmount = 0
  let old_id = null
  let cashAmount = 0
  let cash_id = null
  let isFirstCash = true
  let isNewPosition = true

  // Get all positions by account id if there are none return
  const positions = await positionsStore.getAllPositionsByAccountId(currentTransaction.account_id)
  if (!positions) return

  positions.forEach(position => {
    if (position.units == 'cash') {
      isFirstCash = false
      cashAmount = position.amount
      cash_id = position.id
    }
  })

  if (currentTransaction.units != 'cash') {
    positions.forEach(position => {
      if (position.offering_id == currentTransaction.offering_id) {
        isNewPosition = false
        oldAmount = position.amount
        old_id = position.id
      }
    })
  }

  // If the transaction is a cash transaction
  if (currentTransaction.units == 'cash') {
    // If there is no cash position create one
    if (isFirstCash) {
      const successful = await positionsStore.createCashPosition(currentTransaction.account_id, currentTransaction.amount, currentTransaction.units, currentTransaction.price_per_unit)
      if (!successful) return
      return true
    }
    // Check if this is not a holding account (This is not really necessary but it's here for future reference)
    if (currentTransaction.accounts?.accounts_protected?.is_holding_account) return
    // Update cash position (subtracting the transaction amount from the cash position)
    const successful = await positionsStore.updateCashPosition(cash_id, currentTransaction.type, cashAmount, currentTransaction.amount)
    if (!successful) return
    return true
  }

  // If the transaction is not a cash transaction and there is no position
  if (isNewPosition) {
    // If there is no position create one
    const successful = await positionsStore.createPosition(currentTransaction.account_id, currentTransaction.offering_id, currentTransaction.amount, currentTransaction.units, currentTransaction.price_per_unit)
    if (!successful) return
  }
  // If the transaction is not a cash transaction and there is a position
  else {
    // Update the position (adding the transaction amount to the position)
    const successful = await positionsStore.updatePosition(old_id, currentTransaction.type, oldAmount, currentTransaction.amount)
    if (!successful) return
  }

  // Check if this is not a holding account
  if (currentTransaction.accounts?.accounts_protected?.is_holding_account) return true

  // Update cash position (subtracting the transaction amount from the cash position)
  const successful = await positionsStore.updateCashPosition(cash_id, currentTransaction.type, cashAmount, currentTransaction.amount)
  if (!successful) return
  return true
}

// Watchers
watch(open, async () => {
  if (!open.value) {
    await new Promise(resolve => setTimeout(resolve, 400));
    emit('closed');
  }
})
</script>