<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <!-- Slideovers -->
    <!-- Investor Slideover -->
    <InvestorSlideover v-if="showInvestorSlideover" :show="showInvestorSlideover" v-model="selectedInvestor" :partner="selectedInvestor.partners" @closed="showInvestorSlideover = false"
      @updated="setInvestors()" />
    <!-- Slideovers -->

    <!-- Popups -->
    <!-- Notifactions -->
    <NotificationPopup :type="notificationType" :title="notificationTitle" :text="notificationText" ref="notification" />
    <!-- Investor Flow Popup -->
    <AlphaFlow v-if="showFlow" v-model="showFlow" need="create-party-for-other" @completed="showFlow = false" @closed="showFlow = false" />
    <!-- Popups -->

    <!-- Loading -->
    <div v-if="loading" class="flex flex-grow items-center justify-center h-[70vh]">
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Empty State -->
    <div v-else-if="investors && investors.length <= 0" class="text-center">
      <UsersIcon class="w-12 h-12 mx-auto text-gray-400" />
      <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300">No Clients</h3>
      <p class="mt-1 text-sm text-gray-500">You have now investors yet.<br />Once an investor has signed up you will see their information here.</p>
      <div @click="showFlow = true" class="mt-5 button_primary">Create Investor</div>
    </div>

    <!-- Show Parties -->
    <div v-else>
      <!-- Header -->
      <div>
        <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
          <!-- Tab Header -->
          <h3 class="primary_header">Investors</h3>

          <div class="flex items-center gap-4">
            <!-- Search -->
            <div class="flex items-center gap-2">
              <div>
                <div class="relative rounded-md">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <MagnifyingGlassIcon class="w-4 h-4 text-gray-400 dark:text-neutral-400" aria-hidden="true" />
                  </div>
                  <input type="text" name="search" id="search" class="pl-8 input" placeholder="Quick Search" v-model="query" />
                </div>
              </div>
            </div>
            <!-- Create Investor -->
            <button @click="showFlow = true" type="button" class="button_primary">
              Create Investor
              <UserPlusIcon class="w-4 h-4" />
            </button>
          </div>
        </div>
        <div class="flex">
          <SwitchGroup as="div" class="flex items-center justify-between w-fit">
            <Switch v-model="isTable"
              class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-operacolor focus:ring-offset-2 dark:focus:outline-none dark:focus:ring-0 dark:focus:ring-operacolor dark:focus:ring-offset-0">
              <span class="sr-only">Use setting</span>
              <span aria-hidden="true" class="pointer-events-none absolute h-full w-full rounded-md bg-gray-50 dark:bg-neutral-950"></span>
              <span aria-hidden="true"
                :class="[isTable ? 'bg-operacolor' : 'bg-gray-200 dark:bg-neutral-700', 'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out']"></span>
              <span aria-hidden="true"
                :class="[isTable ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out']"></span>
            </Switch>
            <SwitchLabel as="span" class="ml-3 text-sm">
              <span class="font-medium text_color_primary">Table View</span>
            </SwitchLabel>
          </SwitchGroup>
        </div>
      </div>

      <!-- Investors Table -->
      <TablePrimary v-if="isTable" :rows="filteredInvestors" :columns="columns" :isClickable="true" @click-primary="handleInvestorClicked" class="-mt-5" export_title="investors" />

      <!-- Investor Cards -->
      <div v-else role="list" class="flex gap-6 flex-wrap mt-4">
        <div v-for="i in filteredInvestors" :key="i.id" @click="handleInvestorClicked(i)"
          class="divide-y divide-gray-200 rounded-lg bg-white shadow-opera dark:shadow-none cursor-pointer border w-80 border-white hover:border-gray-300 hover:border dark:bg-neutral-800 dark:border-neutral-800 dark:hover:border-neutral-600">
          <InvestorCard :investor="i" :role="userStore.user.profiles_protected.role" />
        </div>
      </div>

    </div>


  </div>
</template>

<script setup>
// Essentials
import { ref, computed, onMounted } from 'vue';
import { useUserStore } from '@/stores/user';
import { usePartiesStore } from '@/stores/parties';
// Components
import NotificationPopup from '@/components/popups/NotificationPopup.vue';
import AlphaFlow from '@/components/flows/AlphaFlow.vue';
import SpinnerFlip from '@/components/loading/SpinnerFlip.vue';
import InvestorCard from '@/components/cards/InvestorCard.vue'
import InvestorSlideover from '@/components/slideovers/InvestorSlideover.vue'
import TablePrimary from '@/components/widgets/TablePrimary.vue'
// Libraries
import { UsersIcon } from '@heroicons/vue/24/outline'
import { MagnifyingGlassIcon, UserPlusIcon } from '@heroicons/vue/20/solid'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
// Stores
const userStore = useUserStore();
const partiesStore = usePartiesStore();
// Globals
const isTable = ref(true);
if (window.innerWidth < 1024) isTable.value = false
const loading = ref(true);
const selectedInvestor = ref({});
const investors = ref([]);
const showInvestorSlideover = ref(false);
const query = ref('');
const showFlow = ref(false);
const notification = ref(null);
const notificationType = ref('success');
const notificationText = ref('Success');
const notificationTitle = ref('');
const columns = [
  { key: 'first_name', key2: 'last_name', label: 'Name', type: 'double' },
  { key: 'contact_email', label: 'Contact Email' },
  { key: 'badge', label: 'Status', type: 'badge' },
  { key: 'phone', label: 'Phone' },
  { key: 'parties_protected.kyc', label: 'KYC', type: 'badge' },
  { key: 'parties_protected.aml', label: 'AML', type: 'badge' },
  { key: 'city', label: 'City' },
  { key: 'state', label: 'State' },
  { key: 'created_at', label: 'Created', type: 'date' },
];

// Mounted
onMounted(async () => {
  if (userStore.user.profiles_protected.role == 'super_admin') columns.splice(columns.length - 1, 0, { key: 'partners', label: 'Partner', type: 'partner-logo' });
  await setInvestors();
  loading.value = false;
});

// Computed
const filteredInvestors = computed(() => {
  return query.value === '' ? investors.value :
    investors.value.filter((investor) => {
      if (investor.contact_email?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor.first_name?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor.last_name?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor.id?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor.tapi_party_id?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor.partners.title?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor.phone?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor.zip?.toLowerCase().includes(query.value.toLowerCase())) return true;
    });
});

// Functions
async function setInvestors() {
  // Get All Parties
  const allParties = await partiesStore.getAllPartiesFull();
  if (!allParties) return;
  // Set Badge
  allParties.forEach((party) => {
    if (party.partners.partners_protected.partner_type == 'dab') {
      party.badge = 'Not Accredited'
      party.badge = party?.accounts[0]?.accounts_protected?.accredited_status
    }
    else {
      party.badge = 'Not Invested'
      if (party?.accounts[0]?.positions?.length > 0) party.badge = 'Invested'
      else if (party?.accounts[0]?.transactions?.length > 0) party.badge = 'Pending'
    }
  });
  // Set Investors
  investors.value = allParties;
};

// Simple Functions
const handleInvestorClicked = (investor) => {
  selectedInvestor.value = investor;
  showInvestorSlideover.value = true;
};
const investorCreated = () => {
  notificationType.value = 'success';
  notificationTitle.value = 'Investor Created';
  notificationText.value = 'The investor has been created successfully.';
  setInvestors();
};
</script>
