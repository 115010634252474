<template>
  <li
    class="overflow-hidden rounded-xl shadow-opera bg-white cursor-pointer border border-gray-100 dark:bg-neutral-950 dark:text-neutral-300 dark:border dark:border-neutral-800"
    :class="[clickable ? 'dark:hover:ring-1 dark:hover:ring-neutral-700 hover:border-gray-300 hover:border' : '']">
    <div class="flex items-center justify-between gap-x-4 border-b border-gray-900/5 bg-gray-100 dark:bg-neutral-800 p-5">
      <div class="flex items-center gap-4">
        <img :src="offeringUrl" :alt="props.transaction.offerings?.name"
          class="h-12 w-12 flex-none rounded-lg bg-white dark:bg-neutral-900 object-cover ring-1 ring-gray-900/10 dark:ring-neutral-700" />
        <div class="flex flex-col">
          <div class="text-sm font-semibold leading-6 text_color_primary">{{ props.transaction.offerings?.name }}</div>
          <div class="badge_gray w-fit">{{ props.transaction.tapi_trade_id }}</div>
        </div>
      </div>
      <div class="flex flex-col gap-2 items-end">
        <div :class="props.transaction.type" class="w-fit">{{ capitalizeFirstLetter(props.transaction.type) }}</div>
        <div :class="props.transaction.status" class="w-fit">{{ capitalizeFirstLetter(props.transaction.status) }}</div>
      </div>
    </div>
    <dl class="-my-3 divide-y divide-gray-100 dark:divide-neutral-700 px-6 py-4 text-sm leading-6">
      <div class="flex justify-between gap-x-4 py-3">
        <dt class="text_color_tertiary">Amount</dt>
        <dd class="flex items-start gap-x-2">
          <div class="font-medium text_color_primary">{{ formatMoney(props.transaction.amount * props.transaction.price_per_unit) }}</div>
          <div :class="props.transaction.units">{{ capitalizeFirstLetter(props.transaction.units) }}</div>
        </dd>
      </div>
      <div class="flex justify-between gap-x-4 py-3">
        <dt class="text_color_tertiary">Created</dt>
        <dd class="text_color_secondary">
          <div>{{ formatDate(props.transaction.created_at) }}</div>
        </dd>
      </div>
    </dl>
  </li>
</template>

<script setup>
// Essentials
import { ref, onMounted } from 'vue';
import { useOfferingsStore } from '@/stores/offerings';
// Libraries
import { capitalizeFirstLetter, formatMoney, formatDate } from '@/helper/helper';
// Props
const props = defineProps({
  transaction: {
    type: Object,
    required: true,
    default: null
  },
  clickable: {
    type: Boolean,
    default: false
  }
});
// Stores
const offeringsStore = useOfferingsStore()
// Globals
const offeringUrl = ref('https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/transactionIcon.png?t=2024-06-12T01%3A28%3A50.520Z')

// Mounted
onMounted(async () => {
  // Get offering logo
  await setOfferingLogo(props?.transaction?.offerings?.id, props?.transaction?.offerings?.tapi_offering_id)
})

// Funcitons
async function setOfferingLogo(id, tapi_offering_id) {
  if (!id || !tapi_offering_id) return
  const url = await offeringsStore.getOfferingFile(id, tapi_offering_id, 'logo')
  if (url) offeringUrl.value = url
}
</script>