<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <NotificationPopup :type="notificationType" :title="notificationTitle" :text="notificationText" ref="notification" />
    <!-- Create Offer Popup -->
    <div v-if="showCreateOffering" class="space-y-10 divide-y divide-gray-900/10">
      <div class="relative flex flex-wrap w-full gap-x-8 gap-y-8">
        <OfferingEdit :offering="newOffering" @back="showCreateOffering = false" @submitted="createOffering" />
      </div>
    </div>

    <!-- Edit Offering -->
    <div v-else-if="showEditOffering" class="space-y-10 divide-y divide-gray-900/10">
      <div class="relative flex flex-wrap w-full gap-x-8 gap-y-8">
        <OfferingEdit :offering="offeringInEdit" @back="showEditOffering = false" @submitted="editOffering" @archive="archiveOffering" />
      </div>
    </div>

    <!-- Offers Table -->
    <div v-else>
      <!-- Loading -->
      <div v-if="loading" class="flex flex-grow items-center justify-center h-[70vh]">
        <div class="w-32 h-32 -mt-10">
          <SpinnerJump />
        </div>
      </div>

      <!-- Empty State -->
      <div v-else-if="offerings.length <= 0">
        <div class="text-center">
          <CubeTransparentIcon class="h-12 w-12 mx-auto text-gray-400 dark:text-neutral-400" />
          <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300">No Offerings</h3>
          <p class="mt-1 text-sm text-gray-500 dark:text-neutral-400">Get started by creating a new offering.<br /></p>
          <div class="mt-6">
            <button type="button" @click="showCreateOffering = true" class="button_primary">
              New Offering
              <PlusIcon class="w-5 h-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>

      <!-- Offerings -->
      <div v-else>
        <!-- Header -->
        <div>
          <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
            <!-- Tab Header -->
            <h3 class="primary_header">Offerings</h3>

            <div class="flex items-center gap-4">
              <!-- Search -->
              <div class="flex items-center gap-2">
                <div>
                  <div class="relative rounded-md">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <MagnifyingGlassIcon class="w-4 h-4 text-gray-400 dark:text-neutral-400" aria-hidden="true" />
                    </div>
                    <input type="text" name="search" id="search" class="pl-8 input" placeholder="Quick Search" v-model="query" />
                  </div>
                </div>
              </div>
              <!-- Create Offering -->
              <button v-if="userStore.user.profiles_protected.role == 'super_admin'" class="button_primary" disabled>
                <InfoCircle info="Login as a partner admin to create an offering for that partner" />
                Super Admins Cannot Create Offerings
              </button>
              <button v-else-if="!(partnerStore.partner.partners_protected.partner_type == 'dib' && offerings.length > 0)" @click="showCreateOffering = true" type="button" class="button_primary">
                New Offering
                <PlusIcon class="w-4 h-4" />
              </button>
            </div>
          </div>
          <div class="flex">
            <SwitchGroup as="div" class="flex items-center justify-between w-fit">
              <Switch v-model="isTable"
                class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-operacolor focus:ring-offset-2 dark:focus:outline-none dark:focus:ring-0 dark:focus:ring-operacolor dark:focus:ring-offset-0">
                <span class="sr-only">Use setting</span>
                <span aria-hidden="true" class="pointer-events-none absolute h-full w-full rounded-md bg-gray-50 dark:bg-neutral-950"></span>
                <span aria-hidden="true"
                  :class="[isTable ? 'bg-operacolor' : 'bg-gray-200 dark:bg-neutral-700', 'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out']"></span>
                <span aria-hidden="true"
                  :class="[isTable ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out']"></span>
              </Switch>
              <SwitchLabel as="span" class="ml-3 text-sm">
                <span class="font-medium text_color_primary">Table View</span>
              </SwitchLabel>
            </SwitchGroup>
          </div>
        </div>

        <!-- Offering Table -->
        <TablePrimary v-if="isTable" :rows="filteredOfferings" :columns="columns" :isClickable="true" @click-primary="showEditOfferingPopup" class="-mt-5" export_title="offerings" />

        <!-- Offering Cards -->
        <div v-else class="flex flex-wrap gap-10 mt-4">
          <!-- Cards -->
          <div class="relative h-fit w-80 box-border flex justify-between flex-col background_secondary shadow-opera dark:shadow-none rounded-lg dark:text-neutral-300" v-for="(o) in filteredOfferings"
            :key="o.id">
            <OfferingCard :offering="o" :partner="partnerStore.partner" :allow_edit="true" @button-clicked="showEditOfferingPopup" />
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script setup>
// Essentials
import { ref, computed, onMounted } from 'vue';
import { useUserStore } from '@/stores/user';
import { usePartnerStore } from '@/stores/partner';
import { useOfferingsStore } from '@/stores/offerings';
import { useEmailsStore } from '@/stores/emails';
// Components
import NotificationPopup from '@/components/popups/NotificationPopup.vue'
import OfferingEdit from '@/components/widgets/OfferingEdit.vue'
import SpinnerJump from '@/components/loading/SpinnerJump.vue'
import OfferingCard from '@/components/cards/OfferingCard.vue'
import TablePrimary from '@/components/widgets/TablePrimary.vue';
// Libraries
import { PlusIcon } from '@heroicons/vue/24/outline'
import { CubeTransparentIcon, MagnifyingGlassIcon } from '@heroicons/vue/24/solid'
import { SwitchGroup, Switch, SwitchLabel } from '@headlessui/vue'
import InfoCircle from '@/components/ui/InfoCircle.vue';
// Stores
const userStore = useUserStore();
const partnerStore = usePartnerStore();
const offeringsStore = useOfferingsStore();
const emailsStore = useEmailsStore();
// Globals
const isTable = ref(false);
if (window.innerWidth < 1024) isTable.value = false
const loading = ref(true);
const query = ref('');
const offerings = ref([]);
const showCreateOffering = ref(false);
const showEditOffering = ref(false);
const offeringInEdit = ref({});
const newOffering = ref({
  offering_type: 'Equity',
  status: 'pending',
  visibility: 'public',
  links: [],
  key_values: [],
  content: [],
  gradient_start_color: partnerStore.partner.primary_color,
  gradient_end_color: partnerStore.partner.primary_color_light,
  accept_ach: true,
  accept_cc: true,
  accept_wire: true,
  accept_check: true,
  show_score: false,
  use_regcf_protocols: false,
  require_accreditation: false,
  show_forum: false,
  is_custody_only: false
});
const notification = ref(null)
const notificationType = ref('success')
const notificationTitle = ref('Offering Created Successfully')
const notificationText = ref("Don't forget to add any offering or subscription documents that are needed.")
const columns = [
  { key: 'name', label: 'Name' },
  { key: 'offering_type', label: 'Type', type: 'badge' },
  { key: 'market_sector', label: 'Sector', type: 'bold' },
  { key: 'status', label: 'Status', type: 'badge' },
  { key: 'visibility', label: 'Visibility', type: 'capitalize' },
  { key: 'slogan', label: 'Slogan', type: 'truncate' },
  { key: 'created_at', label: 'Created', type: 'date' }
];

// Mounted
onMounted(async () => {
  // Get Offerings
  await setOfferings();
  // Ready
  loading.value = false;
});

// Computed
const filteredOfferings = computed(() => {
  return query.value === '' ? offerings.value :
    offerings.value.filter((offering) => {
      if (offering?.name?.toLowerCase().includes(query.value.toLowerCase())) return true
      if (offering?.offering_type?.toLowerCase().includes(query.value.toLowerCase())) return true
      if (offering?.market_sector?.toLowerCase().includes(query.value.toLowerCase())) return true
      if (offering?.tapi_offering_id?.toLowerCase().includes(query.value.toLowerCase())) return true
    });
})

// Functions
async function setOfferings() {
  if (userStore.user.profiles_protected.role == 'super_admin') {
    offerings.value = await offeringsStore.getAllOfferings()
    if (!offerings.value) notify('failure', 'Sorry', 'We were unable to get your offerings. If the issue persits, please contact tech support.')
    return
  }
  offerings.value = await offeringsStore.getAllOfferingsForPartner(partnerStore.partner.id)
  if (!offerings.value) notify('failure', 'Sorry', 'We were unable to get your offerings. If the issue persits, please contact tech support.')
}

async function createOffering(newOffering) {
  // Get Images from newOffering (They will be deleted from newOffering object before being sent to supabase)
  const logoImage = newOffering.logoImage;
  const bannerImage = newOffering.bannerImage;

  // Create Offering in supabase
  newOffering.partner_id = partnerStore.partner.id;
  const supabaseOffering = await offeringsStore.createOffering(newOffering);
  if (!supabaseOffering) {
    notify('failure', 'Sorry', 'We were unable to create your offering. If the issue persists, please contact tech support.')
    return
  }

  // Upload Offering Images to Supabase
  let promises = [];
  promises.push(offeringsStore.uploadImagesToSupabase(supabaseOffering.id, logoImage, bannerImage))
  if (newOffering.content && newOffering.content.length > 0) {
    newOffering.content.forEach((content) => {
      if (content.type == 'image') {
        promises.push(offeringsStore.uploadOfferingImagesToSupabase(supabaseOffering.id, content.name, content.file));
        delete content.file; // Remove image from content object to avoid added excess data to Supabase
      }
    });
  }
  // Wait for all promises to resolve
  await Promise.all(promises);

  // Create Offering in TAPI
  createOfferingInTapi(newOffering, supabaseOffering.id)

  // Show notification, close popup and refresh offerings
  notify('success', 'Offering Created Successfully', "Don't forget to add any offering or subscription documents that are needed.")
  showCreateOffering.value = false;
  setOfferings();
};

async function createOfferingInTapi(newOffering, id) {
  const tapi_offering_id = await offeringsStore.createOfferingInTapi(newOffering)
  if (!tapi_offering_id) { emailsStore.notifyDevs(id); return }

  // Update Offering in TAPI
  newOffering.tapi_offering_id = tapi_offering_id
  const successful = await offeringsStore.updateOfferingToApproved(newOffering)
  if (!successful) emailsStore.notifyDevs(id)

  // Update Offering in supabase, add tapi_offering_id
  const successful2 = await offeringsStore.updateOfferingTapiId(id, tapi_offering_id)
  if (!successful2) emailsStore.notifyDevs(id)
}

const editOffering = async (o) => {
  // Upsert Offering Images to Supabase
  let promises = [];
  promises.push(offeringsStore.uploadImagesToSupabase(o.id, o.logoImage, o.bannerImage))

  console.log(o.content)
  if (o.content && o.content.length > 0) {
    o.content.forEach((content) => {
      if (content.type == 'image') {
        promises.push(offeringsStore.uploadOfferingImagesToSupabase(o.id, content.name, content.file));
      }
    });
  }
  // Wait for all promises to resolve
  const results = await Promise.all(promises);
  if (results.some(result => !result)) notify('failure', 'Sorry', 'It looks like some of you images did no upload correctly, Please refresh the page and review your offering images. If the issue persists, please contact tech support.');

  // Remove images from content object to avoid added excess data to Supabase
  if (o.content && o.content.length > 0) {
    o.content.forEach((content) => {
      if (content.type == 'image') {
        delete content.file
        delete content.url
      }
    });
  }

  // Update Offering
  const successful = await offeringsStore.updateOffering(o);
  if (!successful) {
    notify('failure', 'Sorry', 'We were unable to update your offering. If the issue persists, please contact tech support.')
    showEditOffering.value = false
    return
  }
  
  // Show notification and close popup
  setOfferings();
  notify('success', 'Offering Updated Successfully', "Please note that updated images make take a few moments to update on you browser. New images can be seen immediately by clearing the browser's cached images and refreshing the page")
  showEditOffering.value = false;
  // TODO make the changes go to TAPI as well
}

async function archiveOffering (offering) {
  const successful = await offeringsStore.archiveOffering(offering.id);
  if (!successful) {
    notify('failure', 'Sorry', 'We were unable to archive your offering. If the issue persists, please contact tech support.')
    showEditOffering.value = false;
    return
  } 
  setOfferings();
  notify('success', 'Archive Successful', "Your offering has been successfully archived. Please reach out to Tech Support to restore it")
  showEditOffering.value = false;
};

async function showEditOfferingPopup(offering) {
  offeringInEdit.value = offering;
  offeringInEdit.value.logoUrl = await offeringsStore.getOfferingFile(offering.id, offering.tapi_offering_id, 'logo');
  offeringInEdit.value.bannerUrl = await offeringsStore.getOfferingFile(offering.id, offering.tapi_offering_id, 'banner');
  showEditOffering.value = true;
};

// Simple Functions
const notify = (type, title, text) => {
  notificationType.value = type
  notificationTitle.value = title
  notificationText.value = text
  notification.value.show()
}
</script>
