<template>
  <div class="relative">
    <!-- Loading -->
    <div v-if="loading" class="w-full md:h-[60vh] h-[50vh] flex items-center justify-center">
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>
    <!-- Ready -->
    <div v-else>
      <div class="flex flex-wrap items-center justify-between gap-y-5">
        <!-- Pie Chart -->
        <div class="flex flex-wrap items-center gap-5">
          <div class="h-60 w-[400px]">
            <PieChart v-if="showPieChart" :chartData="pieChartData" :chartOptions="pieChartOptions" class="h-52 w-72" />
          </div>
        </div>

        <!-- Grey Info Section -->
        <div class="flex flex-wrap rounded-lg gap-y-2.5 shadow-opera gap-x-24 bg-white dark:bg-neutral-800 dark:text-neutral-300 ring-1 ring-gray-900/5 h-fit p-7">
          <div class="flex flex-col gap-5">
            <div class="flex items-center justify-between gap-5">
              <div class="input_label">Account Type:</div>
              <div :class="props.account.type" class="text-end whitespace-nowrap">{{ getAccountType(props.account.type) }}</div>
            </div>
            <div class="flex items-center justify-between gap-5">
              <div class="input_label">Account #:</div>
              <div class="text-end text_color_tertiary text-sm whitespace-nowrap">{{ props.account.tapi_account_id }}</div>
            </div>
            <div class="flex items-center justify-between gap-5">
              <div class="input_label">Accredited Status:</div>
              <div class="text-end whitespace-nowrap">
                <span :class="getBadgeClass(props.account.accounts_protected.accredited_status)">
                  {{ capitalizeFirstLetter(props.account.accounts_protected.accredited_status) }}
                </span>
              </div>
            </div>
            <div class="flex items-center justify-between gap-5">
              <div class="input_label">Account Status:</div>
              <div class="text-end whitespace-nowrap">
                <span :class="props.account.accounts_protected.approval_status">
                  {{ capitalizeFirstLetter(props.account.accounts_protected.approval_status) }}
                </span>
              </div>
            </div>
            <div class="flex items-center justify-between gap-5">
              <div class="input_label">Created:</div>
              <div class="text-end text_color_tertiary text-sm whitespace-nowrap">{{ formatDate(props.account.created_at) }}</div>
            </div>
          </div>
          <div class="flex flex-col gap-5">
            <div class="flex items-center justify-between gap-5">
              <div class="input_label">Account Name:</div>
              <div class="text-end text_color_tertiary text-sm whitespace-nowrap">{{ props.account.name }}</div>
            </div>
            <div class="flex items-center justify-between gap-5">
              <div class="input_label">Email:</div>
              <div class="text-end text_color_tertiary text-sm whitespace-nowrap">{{ props.account.parties.contact_email }}</div>
            </div>
            <div class="flex items-center justify-between gap-5">
              <div class="input_label">Owner:</div>
              <div class="text-end text_color_tertiary text-sm whitespace-nowrap">{{ `${props.account.parties.first_name} ${props.account.parties.last_name}` }}</div>
            </div>
            <div class="flex items-center justify-between gap-5">
              <div class="input_label">Phone:</div>
              <div class="text-end text_color_tertiary text-sm whitespace-nowrap">{{ props.account.parties.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') }}</div>
            </div>
            <div class="flex items-center justify-between gap-5">
              <div class="input_label">Networth:</div>
              <div class="text-end text_color_tertiary text-sm whitespace-nowrap">{{ formatMoney(props.account.parties.networth) }}</div>
            </div>
          </div>
        </div>

      </div>

      <div class="mt-10">
        <div class="p-5 font-semibold dark:text-neutral-300">Account Value</div>
        <div class="w-full h-[400px]">
          <LineChart v-if="showLineChart" :chartData="lineChartData" :chartOptions="lineChartOptions" />
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from 'vue';
// Components
import SpinnerFlip from '@/components/loading/SpinnerFlip.vue';
import PieChart from '@/components/charts/PieChart.vue';
import LineChart from '@/components/charts/LineChart.vue';
// Libaries
import { getBadgeClass, getAccountType, formatMoney, capitalizeFirstLetter, formatDate } from '@/helper/helper';
// Props
const props = defineProps({
  account: { type: Object, required: true, default: null },
})

const loading = ref(true);
const showPieChart = ref(false);
const showLineChart = ref(false);
const pieChartData = ref({});
const pieChartOptions = ref({});
const lineChartData = ref({});
const lineChartOptions = ref({});

onMounted(async () => {
  loading.value = false;
  loadPositionCharts();
});

function generateMonthArray(created_at) {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const currentDate = new Date();
  const createdAtDate = new Date(created_at);
  const startingMonth = createdAtDate.getMonth();
  const startingYear = createdAtDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const result = [];
  for (let year = startingYear; year <= currentYear; year++) {
    const start = (year === startingYear) ? startingMonth : 0;
    const end = (year === currentYear) ? currentMonth : 11;
    for (let month = start; month <= end; month++) {
      result.push(`${months[month]}, ${year}`);
    }
  }
  if (result.length > 1) result.pop();
  return result;
}

function getTotalAccountValue(positions) {
  let totalAccountValue = 0;
  positions.forEach(position => {
    totalAccountValue += (position.amount * position.price_per_unit);
  });
  return totalAccountValue;
}

async function loadPositionCharts() {
  let labels = [];
  let values = [];
  let backgroundColors = [
    '#016cb2', //operacolor
    '#059669', //Emerald 600
    '#00abe7', //operacolorlight
    '#d97706', //Amber 600
    '#9ca3af' //Gray 400
  ];
  props.account.positions.forEach(position => {
    const { units, amount, price_per_unit, offerings } = position;
    const index = labels.indexOf(units);
    if (index === -1) {
      labels.push(offerings?.name ?? capitalizeFirstLetter(units));
      values.push(amount * price_per_unit);
    } else {
      values[index] += (amount * price_per_unit);
    }
  });
  if (labels.length < 1) {
    labels.push('No Positions');
    values.push(1.0000);
    backgroundColors = ['#9ca3af'];
  }
  pieChartData.value = {
    labels: labels,
    datasets: [
      {
        backgroundColor: backgroundColors,
        data: values,
        borderWidth: 0,
      }
    ]
  };
  pieChartOptions.value = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: true, position: 'right' },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = '';
            if (context.parsed === 1.0000) {
              label = '';
            } else if (context.parsed !== null) {
              label = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed);
            }
            return label;
          }
        }
      }
    }
  };
  // if (labels.includes('Funds')) hasFunds.value = true;
  // if (labels.includes('Shares')) hasEquities.value = true;
  // if (labels.includes('Units')) hasBonds.value = true;
  // if (labels.includes('Cash')) hasCash.value = true;
  // if (labels.includes('No Positions')) hasNone.value = true;
  showPieChart.value = true;

  const month_labels = generateMonthArray(props.account.created_at);
  const account_value = getTotalAccountValue(props.account.positions);
  let data_values = [0];
  data_values.push(account_value);
  if (month_labels.length <= 1) {
    month_labels.unshift('');
    data_values.push(0);
  }

  lineChartData.value = {
    labels: month_labels,
    datasets: [
      {
        label: 'Total',
        backgroundColor: 'rgba(70, 134, 176, 0.4)',
        data: data_values,
        fill: true,
        borderColor: '#016cb2',
        tension: 0.2
      }
    ]
  };
  lineChartOptions.value = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = '';
            if (context.parsed.y === 0) {
              label = '$0.00';
            } else if (context.parsed.y !== null) {
              label = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
            }
            return label;
          }
        }
      }
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Account Value'
        },
        min: 0
      }
    },
    interaction: {
      mode: 'nearest',
      intersect: false
    },
  };
  showLineChart.value = true;
}
</script>
