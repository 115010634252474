<template>
  <div class="px-4 lg:px-8">
    <!-- Popups -->
    <!-- Update Accounts Popup -->
    <UpdateAccount v-if="open" v-model="open" :account="selected_account" @updated="accountsStore.setCustodyAccounts()" />
    <!-- Popups -->

    <!-- Loading -->
    <div v-if="loading" class="w-full md:h-[70vh] h-[50vh] flex items-center justify-center">
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <div v-else>
      <!-- Header -->
      <div>
        <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
          <!-- Tab Header -->
          <h3 class="primary_header">Custody Accounts</h3>

          <div class="flex items-center gap-4">
            <!-- Search -->
            <div class="flex items-center gap-2">
              <div>
                <div class="relative rounded-md">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <MagnifyingGlassIcon class="w-4 h-4 text-gray-400 dark:text-neutral-400" aria-hidden="true" />
                  </div>
                  <input type="text" name="search" id="search" class="pl-8 input" placeholder="Quick Search" v-model="query" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="flex">
          <SwitchGroup as="div" class="flex items-center justify-between w-fit">
            <Switch v-model="isTable"
              class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-operacolor focus:ring-offset-2 dark:focus:outline-none dark:focus:ring-0 dark:focus:ring-operacolor dark:focus:ring-offset-0">
              <span class="sr-only">Use setting</span>
              <span aria-hidden="true" class="pointer-events-none absolute h-full w-full rounded-md bg-gray-50 dark:bg-neutral-950"></span>
              <span aria-hidden="true"
                :class="[isTable ? 'bg-operacolor' : 'bg-gray-200 dark:bg-neutral-700', 'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out']"></span>
              <span aria-hidden="true"
                :class="[isTable ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out']"></span>
            </Switch>
            <SwitchLabel as="span" class="ml-3 text-sm">
              <span class="font-medium text_color_primary">Table View</span>
            </SwitchLabel>
          </SwitchGroup>
        </div> -->
      </div>

      <!-- Custody Accounts Table -->
      <TablePrimary :rows="filteredAccounts" :columns="columns" :isClickable="clickable" @click-primary="handleAccountClick" class="" export_title="custody_accounts" />
    </div>


  </div>
</template>

<script setup>
// Essentials
import { ref, computed, onMounted } from 'vue'
import { useAccountsStore } from '@/stores/accounts';
import { useUserStore } from '@/stores/user';
// Components
import UpdateAccount from '@/components/popups/UpdateAccount.vue';
import TablePrimary from '@/components/widgets/TablePrimary.vue';
import SpinnerFlip from '@/components/loading/SpinnerFlip.vue'
// Libaries
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
// Stores
const userStore = useUserStore()
const accountsStore = useAccountsStore()
// Globals
const loading = ref(true)
const open = ref(false)
const clickable = ref(false)
const selected_account = ref(null)
const query = ref('')
const columns = [
  { key: 'name', label: 'Name' },
  { key: 'tapi_account_id', label: 'Account #' },
  { key: 'type', label: 'Type', type: 'badge' },
  { key: 'accounts_protected.approval_status', label: 'Status', type: 'badge' },
  { key: 'accounts_protected.accredited_status', label: 'Accreditation', type: 'badge' },
  { key: 'created_at', label: 'Created', type: 'date'},
]

// Mounted
onMounted(async () => {
  // Get Custody Accounts
  await accountsStore.setCustodyAccounts()
  // Set Clickable
  if (userStore.user.profiles_protected.role == 'super_admin') clickable.value = true
  // Ready
  loading.value = false
})

// Computed
const filteredAccounts = computed(() => {
  return query.value === '' ? accountsStore.custodyAccounts :
    accountsStore.custodyAccounts.filter((account) => {
      if (account?.id.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (account?.tapi_account_id?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (account?.name?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (account?.type?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (account?.accounts_protected?.accredited_status?.toLowerCase().includes(query.value.toLowerCase())) return true;
    });
});

// Simple Functions
const handleAccountClick = (account) => {
  selected_account.value = account
  open.value = true
}
</script>
