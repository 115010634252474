<template>
  <div>
    <div v-if="partnerStore.partner.show_disclosure && partnerStore.partner.partners_protected.use_custom_disclosure" class="px-8 text-sm italic dark:text-neutral-400">
      <div>{{ partnerStore.partner.partners_protected.disclosure }}</div>
    </div>
    <div v-else-if="partnerStore.partner.show_disclosure" class="px-8 text-sm italic dark:text-neutral-400">
      Investments involve risk, including the risk of loss of principal. You should carefully consider your investment objectives, risks, transaction costs and other expenses before deciding to invest
      in
      futures or other investments. North Capital Private Securities Corporation is a registered broker dealer, member <a class="text-blue-800 dark:text-sky-400" href="http://www.finra.org/"
        target="_blank">FINRA</a> | <a class="text-blue-800 dark:text-sky-400" href="http://www.sipc.org/" target="_blank">SIPC</a> View the firm's <a class="text-blue-800 dark:text-sky-400"
        href="https://app.hubspot.com/documents/4042879/view/266393663?accessId=7d6a1d" target="_blank">Form CRS</a>.
      The relationship between North Capital Investment Technology, North Capital Inc., and North Capital Private Securities Corporation is described in our Disclosure Documents and <a
        class="text-blue-800 dark:text-sky-400" href="https://www.northcapital.com/disclosure-documents" target="_blank">Code of Ethics</a>. Check the background of this firm on FINRA's <a
        class="text-blue-800 dark:text-sky-400" href="https://brokercheck.finra.org/firm/summary/154559" target="_blank">BrokerCheck</a> © 1999-2024. All Rights Reserved. <a
        class="text-blue-800 dark:text-sky-400" href="https://www.northcapital.com/customer-complaint-form/" target="_blank">Submit a Complaint</a>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { onMounted } from 'vue'
import { usePartnerStore } from '@/stores/partner'
// Stores
const partnerStore = usePartnerStore()
// Mounted
onMounted(async () => {
  // Check Stores
  if (!partnerStore.partner) await partnerStore.setPartner();
});
</script>
  


  