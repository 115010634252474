import { defineStore } from 'pinia'
import { supabase } from '../lib/supabaseClient'
// import { ref } from 'vue'


export const usePositionsStore = defineStore('positions', () => {
  // State (refs)

  // Getters, (computed properties)

  // Actions, (functions)
  async function getAllPositionsByAccountId(account_id) {
    if (!account_id) return
    let { data, error } = await supabase.from('positions').select('*, offerings(*)').eq('account_id', account_id).order('created_at', { ascending: false })
    if (error) { console.log(error); return }
    return data
  }
  async function createCashPosition(account_id, amount, units, price_per_unit) {
    const { error } = await supabase.from('positions').insert({
      account_id: account_id,
      amount: amount,
      units: units,
      price_per_unit: price_per_unit,
    })
    if (error) { console.log(error); return }
    return true
  }
  async function createPosition(account_id, offering_id, amount, units, price_per_unit) {
    const { error } = await supabase.from('positions').insert({
      account_id: account_id,
      offering_id: offering_id,
      amount: amount,
      units: units,
      price_per_unit: price_per_unit,
    })
    if (error) { console.log(error); return }
    return true
  }
  async function updatePosition(id, type, currentAmount, transactionAmount) {
    if (type == 'buy') {
      const { error } = await supabase.from('positions').update({ amount: (currentAmount + transactionAmount) }).eq('id', id)
      if (error) { console.log(error); return }
      return true
    }
    else if (type == 'sell' || type == 'refund') {
      const { error } = await supabase.from('positions').update({ amount: (currentAmount - transactionAmount) }).eq('id', id)
      if (error) { console.log(error); return }
      return true
    }
  }
  async function updateCashPosition(id, type, currentAmount, transactionAmount) {
    if (type == 'deposit') {
      const { error } = await supabase.from('positions').update({ amount: (currentAmount + transactionAmount) }).eq('id', id)
      if (error) { console.log(error); return }
      return true
    } 
    else if (type == 'withdraw' || type == 'buy') {
      const { error } = await supabase.from('positions').update({ amount: (currentAmount - transactionAmount) }).eq('id', id)
      if (error) { console.log(error); return }
      return true
    }
    else if (type == 'sell' || type == 'refund') { //TODO verify this action
      const { error } = await supabase.from('positions').update({ amount: (currentAmount + transactionAmount) }).eq('id', id)
      if (error) { console.log(error); return }
      return true
    }
  }
  async function getAvailableCash(account_id) {
    const { data, error } = await supabase.from('positions').select('*').eq('account_id', account_id).eq('units', 'cash');
    if (error) { console.log(error); return }
    // Calculate the available cash
    let availableCash = 0
    data.forEach(position => { availableCash += position.amount * position.price_per_unit; });
    return availableCash;
  };

  // Not Callable Functions

  return {
    getAllPositionsByAccountId,
    createPosition,
    createCashPosition,
    updateCashPosition,
    updatePosition,
    getAvailableCash
  }
})