<template>
  <div class="mb-24">
    <!-- Loading -->
    <div v-if="loading" class="w-full md:h-[70vh] h-[50vh] flex items-center justify-center">
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- DealRoom -->
    <div v-else class="relative dark:text-neutral-300">
      <img class="object-cover w-[calc(100%_+_120px)] -mt-10 bg-gray-200 h-52 shadow" :src="offeringBanner" />

      <div class="flex flex-wrap mx-10 gap-x-10">
        <div class="flex flex-col w-11/12 gap-5 mx-5 md:mx-auto md:w-fit">

          <!-- Offering Logo -->
          <div class="-mt-12 overflow-hidden bg-white rounded-full w-fit ring-1 ring-border">
            <img :src="offeringLogo" class="object-contain w-24 h-24" />
          </div>

          <!-- Name & Slogan -->
          <div class="flex flex-col gap-2">
            <div class="max-w-3xl text-3xl font-bold">{{ offering.name }}</div>
            <div class="w-full max-w-3xl text-xl font-bold text-gray-500">{{ offering.slogan }}</div>
          </div>

          <!-- Description -->
          <div class="flex flex-col gap-1">
            <div class="font-semibold text-gray-400">Description</div>
            <div class="w-full max-w-3xl overflow-hidden text-sm">{{ offering.description }}</div>
          </div>

          <!-- Links -->
          <div class="">
            <div class="flex items-center space-x-6">
              <a v-for="item in socials" :key="item.name" :href="item.href" target="_blank" class="text-blue-500 hover:text-blue-600 flex">
                <span class="sr-only">{{ item.name }}</span>
                <component :is="item.icon" class="w-6 h-6" aria-hidden="true" />
                <div v-if="item.name == 'Site'" class="ml-1">Website</div>
                <div v-if="item.name == 'Fact Right'" class="ml-1">{{ item.name }}</div>
              </a>
            </div>
          </div>

          <!-- Navigation Bar -->
          <div class="flex items-center justify-between w-full max-w-3xl gap-4 p-1 overflow-x-scroll bg-gray-200 rounded-full sm:overflow-x-hidden dark:bg-neutral-600">
            <div class="flex items-center justify-center rounded-full cursor-pointer grow" :class="overviewClass" @click="setSection('overview')">
              <div class="flex items-center gap-1 px-2 py-1">
                <ListBulletIcon class="w-5 h-5" />
                <span class="whitespace-nowarp">Overview</span>
              </div>
            </div>
            <div v-if="offering.show_score" class="flex items-center justify-center rounded-full cursor-pointer grow" :class="scorecardsClass" @click="setSection('scorecards')">
              <div class="flex items-center gap-1 px-2 py-1">
                <Squares2X2Icon class="w-5 h-5" />
                <span class="whitespace-nowrap">Score Cards</span>
              </div>
            </div>
            <div class="flex items-center justify-center rounded-full cursor-pointer grow" :class="documentsClass" @click="setSection('documents')">
              <div class="flex items-center gap-1 px-2 py-1">
                <DocumentIcon class="w-5 h-5" />
                <span class="whitespace-nowarp">Documents</span>
              </div>
            </div>
          </div>

          <div class="md:w-[700px] w-full p-1">
            <div v-if="visibleSection == 'overview'" class="background shadow dark:shadow-none !rounded-xl w-full">
              <div class="">
                <dl class="divide-y divide-gray-100 dark:divide-neutral-800">
                  <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="tertiary_subheader dark:text-white">Security Type</dt>
                    <dd class="mt-1 text sm:col-span-2 sm:mt-0">{{ offering.offering_type }}</dd>
                  </div>
                  <div v-if="offering.minimum" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="tertiary_subheader dark:text-white">Min Investment</dt>
                    <dd class="mt-1 text sm:col-span-2 sm:mt-0">{{ formatter.format(offering.minimum) }}</dd>
                  </div>
                  <div v-if="offering.offering_type != 'Fund' && offering.target_amount" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="tertiary_subheader dark:text-white">Raise Goal</dt>
                    <dd class="mt-1 text sm:col-span-2 sm:mt-0">{{ formatter.format(offering.target_amount) }}</dd>
                  </div>
                  <div v-if="offering.offering_type != 'Fund' && offering.unit_price" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="tertiary_subheader dark:text-white">Price per Share</dt>
                    <dd class="mt-1 text sm:col-span-2 sm:mt-0">{{ formatter.format(offering.unit_price) }}</dd>
                  </div>
                  <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="tertiary_subheader dark:text-white">Sector</dt>
                    <dd class="mt-1 text sm:col-span-2 sm:mt-0">{{ offering.market_sector }}</dd>
                  </div>
                  <div v-if="offering.offering_type == 'Fund' && offering.ticker" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="tertiary_subheader dark:text-white">Ticker</dt>
                    <dd class="mt-1 text sm:col-span-2 sm:mt-0">{{ offering.ticker }}</dd>
                  </div>
                  <div v-if="offering.start_date" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt v-if="offering.offering_type == 'Fund'" class="tertiary_subheader dark:text-white">Inception Date</dt>
                    <dt v-else class="tertiary_subheader dark:text-white">Raise Start Date</dt>
                    <dd class="mt-1 text sm:col-span-2 sm:mt-0">{{ getDate(offering.start_date) }}</dd>
                  </div>
                  <div v-if="offering.offering_type != 'Fund' && offering.end_date" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="tertiary_subheader dark:text-white">Raise End Date</dt>
                    <dd class="mt-1 text sm:col-span-2 sm:mt-0">{{ getDate(offering.end_date) }}</dd>
                  </div>
                  <div v-if="offering.offering_type != 'Fund' && offering.show_pending_amount" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="tertiary_subheader dark:text-white">Pending Amount</dt>
                    <dd class="mt-1 text sm:col-span-2 sm:mt-0">{{ formatter.format(offeringDetails.pendingAmount) }}</dd>
                  </div>
                  <div v-if="offering.offering_type != 'Fund' && offering.show_funded_amount" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="tertiary_subheader dark:text-white">Funded Amount</dt>
                    <dd class="mt-1 text sm:col-span-2 sm:mt-0">{{ formatter.format(offeringDetails.fundedAmount) }}</dd>
                  </div>
                  <div v-if="offering.offering_type == 'Fund' && offering.gross_fees" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="tertiary_subheader dark:text-white">Gross Expense Ratio</dt>
                    <dd class="mt-1 text sm:col-span-2 sm:mt-0">{{ offering.gross_fees }}</dd>
                  </div>
                  <div v-if="offering.offering_type == 'Fund' && offering.net_fees" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="tertiary_subheader dark:text-white">Net Expense Ratio</dt>
                    <dd class="mt-1 text sm:col-span-2 sm:mt-0">{{ offering.net_fees }}</dd>
                  </div>
                  <div v-if="offering.show_score" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="tertiary_subheader dark:text-white">{{ scoreText }}</dt>
                    <dd class="mt-1 text sm:col-span-2 sm:mt-0">
                      <div v-if="offering.score && offering.score != 'Not yet available'" class="flex items-center gap-1">
                        <span class="mr-1 text-xs text-gray-400">({{ offering.score }})</span>
                        <OperaScore :score="offering.score" />
                      </div>
                      <span v-else class="text-operacolor">{{ offering.score }}</span>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>

            <!-- Scorecards Section -->
            <div v-else-if="visibleSection == 'scorecards'" class="flex flex-wrap gap-5 md:flex-nowrap">
              <div class="flex flex-col w-full gap-4 md:w-1/2">

                <div class="rounded-lg cursor-pointer ring-1 ring-border hover:ring-gray-400" @click="showFinances = !showFinances">
                  <div class="flex items-center justify-between p-4">
                    <div class="flex items-center gap-2">
                      <BanknotesIcon class="w-5 h-5 text-operacolor" />
                      <span class="font-medium">Financials</span>
                    </div>
                    <ChevronDownIcon class="w-5 h-5 text-gray-500" />
                  </div>
                  <div v-if="showFinances" class="px-4 pb-4 flex flex-col">
                    <div class="flex gap-2">
                      <div class="font-bold">Score: </div>
                      <div v-if="offering.financials != 'Not yet available'" class="flex items-center gap-1">
                        <span class="mr-1 text-xs text-gray-400">({{ offering.financials }})</span>
                        <OperaScore :score="offering.financials" />
                      </div>
                      <span v-else class="text-operacolor">{{ offering.financials }}</span>
                    </div>
                    <span v-if="offering.financials_explanation" class="mt-2 text-gray-600">{{ offering.financials_explanation }}</span>
                    <a class="flex gap-1 mt-1 text-xs text-blue-500 hover:text-blue-800 w-fit" href="https://operaalts.com/dashboard/education/article-ratingmethodology" target="_blank">See
                      Methodology
                      <ArrowTopRightOnSquareIcon class="w-3 h-4" />
                    </a>
                  </div>
                </div>

                <div class="rounded-lg cursor-pointer ring-1 ring-border hover:ring-gray-400" @click="showFees = !showFees">
                  <div class="flex items-center justify-between p-4">
                    <div class="flex items-center gap-2">
                      <CurrencyDollarIcon class="w-5 h-5 text-operacolor" />
                      <span class="font-medium">Fees & Expenses</span>
                    </div>
                    <ChevronDownIcon class="w-5 h-5 text-gray-500" />
                  </div>
                  <div v-if="showFees" class="px-4 pb-4 flex flex-col">
                    <div class="flex gap-2">
                      <div class="font-bold">Score: </div>
                      <div v-if="offering.fee_score != 'Not yet available'" class="flex items-center gap-1">
                        <span class="mr-1 text-xs text-gray-400">({{ offering.fee_score }})</span>
                        <OperaScore :score="offering.fee_score" />
                      </div>
                      <span v-else class="text-operacolor">{{ offering.fee_score }}</span>
                    </div>
                    <span v-if="offering.fee_explanation" class="mt-2 text-gray-600">{{ offering.fee_explanation }}</span>
                    <a class="flex gap-1 mt-1 text-xs text-blue-500 hover:text-blue-800 w-fit" href="https://operaalts.com/dashboard/education/article-ratingmethodology" target="_blank">See
                      Methodology
                      <ArrowTopRightOnSquareIcon class="w-3 h-4" />
                    </a>
                  </div>
                </div>

                <div class="rounded-lg cursor-pointer ring-1 ring-border hover:ring-gray-400" @click="showAUM = !showAUM">
                  <div class="flex items-center justify-between p-4">
                    <div class="flex items-center gap-2">
                      <FolderOpenIcon class="w-5 h-5 text-operacolor" />
                      <span class="font-medium">Assets Under Management</span>
                    </div>
                    <ChevronDownIcon class="w-5 h-5 text-gray-500" />
                  </div>
                  <div v-if="showAUM" class="px-4 pb-4 flex flex-col">
                    <div class="flex gap-2">
                      <div class="font-bold">Score: </div>
                      <div v-if="offering.aum != 'Not yet available'" class="flex items-center gap-1">
                        <span class="mr-1 text-xs text-gray-400">({{ offering.aum }})</span>
                        <OperaScore :score="offering.aum" />
                      </div>
                      <span v-else class="text-operacolor">{{ offering.aum }}</span>
                    </div>
                    <span v-if="offering.aum_explanation" class="mt-2 text-gray-600">{{ offering.aum_explanation }}</span>
                    <a class="flex gap-1 mt-1 text-xs text-blue-500 hover:text-blue-800 w-fit" href="https://operaalts.com/dashboard/education/article-ratingmethodology" target="_blank">See
                      Methodology
                      <ArrowTopRightOnSquareIcon class="w-3 h-4" />
                    </a>
                  </div>
                </div>


                <div class="rounded-lg cursor-pointer ring-1 ring-border hover:ring-gray-400" @click="showLiquidity = !showLiquidity">
                  <div class="flex items-center justify-between p-4">
                    <div class="flex items-center gap-2">
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" class="fill-operacolor" viewBox="0 0 384 512">
                        <path
                          d="M192 512C86 512 0 426 0 320C0 228.8 130.2 57.7 166.6 11.7C172.6 4.2 181.5 0 191.1 0h1.8c9.6 0 18.5 4.2 24.5 11.7C253.8 57.7 384 228.8 384 320c0 106-86 192-192 192zM96 336c0-8.8-7.2-16-16-16s-16 7.2-16 16c0 61.9 50.1 112 112 112c8.8 0 16-7.2 16-16s-7.2-16-16-16c-44.2 0-80-35.8-80-80z" />
                      </svg>
                      <span class="font-medium">Liquidity</span>
                    </div>
                    <ChevronDownIcon class="w-5 h-5 text-gray-500" />
                  </div>
                  <div v-if="showLiquidity" class="px-4 pb-4 flex flex-col">
                    <div class="flex gap-2">
                      <div class="font-bold">Score: </div>
                      <div v-if="offering.liquidity != 'Not yet available'" class="flex items-center gap-1">
                        <span class="mr-1 text-xs text-gray-400">({{ offering.liquidity }})</span>
                        <OperaScore :score="offering.liquidity" />
                      </div>
                      <span v-else class="text-operacolor">{{ offering.liquidity }}</span>
                    </div>
                    <span v-if="offering.liquidity_explanation" class="mt-2 text-gray-600">{{ offering.liquidity_explanation }}</span>
                    <a class="flex gap-1 mt-1 text-xs text-blue-500 hover:text-blue-800 w-fit" href="https://operaalts.com/dashboard/education/article-ratingmethodology" target="_blank">See
                      Methodology
                      <ArrowTopRightOnSquareIcon class="w-3 h-4" />
                    </a>
                  </div>
                </div>

              </div>

              <div class="flex flex-col w-full gap-4 md:w-1/2">

                <div class="rounded-lg cursor-pointer ring-1 ring-border hover:ring-gray-400" @click="showManagement = !showManagement">
                  <div class="flex items-center justify-between p-4">
                    <div class="flex items-center gap-2">
                      <UserGroupIcon class="w-5 h-5 text-operacolor" />
                      <span class="font-medium">Management</span>
                    </div>
                    <ChevronDownIcon class="w-5 h-5 text-gray-500" />
                  </div>
                  <div v-if="showManagement" class="px-4 pb-4 flex flex-col">
                    <div class="flex gap-2">
                      <div class="font-bold">Score: </div>
                      <div v-if="offering.management != 'Not yet available'" class="flex items-center gap-1">
                        <span class="mr-1 text-xs text-gray-400">({{ offering.management }})</span>
                        <OperaScore :score="offering.management" />
                      </div>
                      <span v-else class="text-operacolor">{{ offering.management }}</span>
                    </div>
                    <span v-if="offering.management_explanation" class="mt-2 text-gray-600">{{ offering.management_explanation }}</span>
                    <a class="flex gap-1 mt-1 text-xs text-blue-500 hover:text-blue-800 w-fit" href="https://operaalts.com/dashboard/education/article-ratingmethodology" target="_blank">See
                      Methodology
                      <ArrowTopRightOnSquareIcon class="w-3 h-4" />
                    </a>
                  </div>
                </div>

                <div class="rounded-lg cursor-pointer ring-1 ring-border hover:ring-gray-400" @click="showLongevity = !showLongevity">
                  <div class="flex items-center justify-between p-4">
                    <div class="flex items-center gap-2">
                      <ChartBarIcon class="w-5 h-5 text-operacolor" />
                      <span class="font-medium">Fund Longevity</span>
                    </div>
                    <ChevronDownIcon class="w-5 h-5 text-gray-500" />
                  </div>
                  <div v-if="showLongevity" class="px-4 pb-4 flex flex-col">
                    <div class="flex gap-2">
                      <div class="font-bold">Score: </div>
                      <div v-if="offering.longevity != 'Not yet available'" class="flex items-center gap-1">
                        <span class="mr-1 text-xs text-gray-400">({{ offering.longevity }})</span>
                        <OperaScore :score="offering.longevity" />
                      </div>
                      <span v-else class="text-operacolor">{{ offering.longevity }}</span>
                    </div>
                    <span v-if="offering.longevity_explanation" class="mt-2 text-gray-600">{{ offering.longevity_explanation }}</span>
                    <a class="flex gap-1 mt-1 text-xs text-blue-500 hover:text-blue-800 w-fit" href="https://operaalts.com/dashboard/education/article-ratingmethodology" target="_blank">See
                      Methodology
                      <ArrowTopRightOnSquareIcon class="w-3 h-4" />
                    </a>
                  </div>
                </div>

                <div class="rounded-lg cursor-pointer ring-1 ring-border hover:ring-gray-400" @click="showDocs = !showDocs">
                  <div class="flex items-center justify-between p-4">
                    <div class="flex items-center gap-2">
                      <ClipboardDocumentIcon class="w-5 h-5 text-operacolor" />
                      <span class="font-medium">Offering Documents</span>
                    </div>
                    <ChevronDownIcon class="w-5 h-5 text-gray-500" />
                  </div>
                  <div v-if="showDocs" class="px-4 pb-4 flex flex-col">
                    <div class="flex gap-2">
                      <div class="font-bold">Score: </div>
                      <div v-if="offering.documents_score != 'Not yet available'" class="flex items-center gap-1">
                        <span class="mr-1 text-xs text-gray-400">({{ offering.documents_score }})</span>
                        <OperaScore :score="offering.documents_score" />
                      </div>
                      <span v-else class="text-operacolor">{{ offering.documents_score }}</span>
                    </div>
                    <span v-if="offering.documents_explanation" class="mt-2 text-gray-600">{{ offering.documents_explanation }}</span>
                    <a class="flex gap-1 mt-1 text-xs text-blue-500 hover:text-blue-800 w-fit" href="https://operaalts.com/dashboard/education/article-ratingmethodology" target="_blank">See
                      Methodology
                      <ArrowTopRightOnSquareIcon class="w-3 h-4" />
                    </a>
                  </div>
                </div>

              </div>
            </div>

            <!-- Documents Section -->
            <div v-else>
              <!-- Doc Pill -->
              <div v-if="isLoadingOfferingDocs" class="flex flex-col gap-4">
                <div v-for="index in 3" :key="index" class="flex items-center justify-between h-10 gap-10 bg-gray-200 rounded-lg shadow-sm dark:bg-neutral-700 animate-pulse"></div>
              </div>

              <div v-else class="flex flex-col gap-4">
                <div v-if="docs.length <= 0" class="flex items-center justify-center">No Documents Found</div>
                <div v-else v-for="d in docs" :key="d.documentId" class="flex items-center justify-between gap-10 rounded-lg ring-1 ring-border">
                  <div class="flex items-center gap-2 p-2 w-fit">
                    <div class="p-2 text-sm font-medium text-blue-600 bg-blue-100 rounded-md">PDF</div>
                    <span class="">{{ d.documentTitle }}</span>
                  </div>

                  <a class="flex items-center justify-end gap-3 p-2 px-4 mr-2 font-medium text-blue-600 rounded-md cursor-pointer w-fit hover:bg-gray-100" :href="d.url" target="_blank">
                    <span>Download</span>
                    <ArrowDownTrayIcon class="w-5 h-5" />
                  </a>
                </div>
              </div>

            </div>

          </div>

        </div>

        <!-- Checkout Form -->
        <div class="flex flex-col gap-8 p-6 w-full sm:w-fit sm:mx-auto mt-16 divide-y rounded-lg divide-white/50 divide-dashed h-fit bg-gradient-to-r from-operacolor to-operacolorlight">
          <div class="flex flex-col gap-6">
            <!-- Title -->
            <span class="text-xl font-bold text-white">Invest Form</span>
            <!-- Account Select -->
            <!-- Shares -->
            <div class="sm:w-96 w-full">
              <label for="numShares" class="block text-sm font-medium leading-6 text-white">{{ getType(offering.offering_type) }}</label>
              <div class="input_wrapper">
                <input v-model="numShares" type="text" name="numShares" id="numShares"
                  class="block w-full rounded-md border-0 bg-[rgba(255,255,255,.2)] py-1.5 text-gray-200 shadow-sm placeholder:text-gray-200 focus:ring-1 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                  :placeholder="getPlaceholderValue(offering.offering_type)" required="" />
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-8">
            <!-- Total -->
            <div class="flex items-center justify-between pt-6 sm:w-96 w-full">
              <div class="font-semibold text-white text-md">Total Investment<br> Amount</div>
              <div class="text-lg font-bold text-white w-fit">{{ formatter.format(investmentTotal) }}</div>
            </div>

            <!-- Invest Button -->
            <router-link :to="`/login?partner=${offering.partners.meta_name}`" class="button_invest justify-center">
              Login to Invest
              <CurrencyDollarIcon class="w-5 h-5" />
            </router-link>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted, watch, h, markRaw, defineComponent } from 'vue'
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router'
import { supabase } from '@/lib/supabaseClient'

// Components
import SpinnerFlip from '@/components/loading/SpinnerFlip.vue';
import OperaScore from '@/components/ui/OperaScore.vue'
// Libraries
import { ListBulletIcon, Squares2X2Icon, DocumentIcon, ChevronDownIcon, BanknotesIcon, CurrencyDollarIcon, FolderOpenIcon, UserGroupIcon, ChartBarIcon, ClipboardDocumentIcon, ArrowDownTrayIcon, ArrowTopRightOnSquareIcon, GlobeAltIcon, LinkIcon } from '@heroicons/vue/24/outline'
// Stores
// Globals
const route = useRoute()
const router = useRouter()
const offeringLogo = ref('')
const offeringBanner = ref('')
const numShares = ref('')
const investmentTotal = ref('')
const offering_id = ref('')
const offering = ref({})
const offeringDetails = ref({})
const docs = ref([])
const loading = ref(true)
const isLoadingOfferingDetails = ref(true)
const isLoadingOfferingDocs = ref(true)
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})
const socials = ref([])
const overviewClass = ref('bg-white text-operacolor')
const scorecardsClass = ref('')
const documentsClass = ref('')
const visibleSection = ref('overview')
const showFinances = ref(false)
const showFees = ref(false)
const showAUM = ref(false)
const showLiquidity = ref(false)
const showManagement = ref(false)
const showLongevity = ref(false)
const showDocs = ref(false)
const scoreText = ref('Opera Score')
// Computed
// Mounted
onMounted(async () => {
  // Check Stores
  // Get Offering
  offering_id.value = route.params.id
  await getOffering()
  if(!offering.value) {
    router.push('/login')
    return
  }
  // Setup
  offeringLogo.value = getLogoUrl()
  offeringBanner.value = getBannerUrl()
  setSocials()
  // Ready
  loading.value = false
  // lazy loaded
  getOfferingDetails()
  getOfferingDocuments()
})

// Functions
async function getOffering() {
  const { data, error } = await supabase.from('offerings').select('*, partners(*)').eq('id', offering_id.value)
  if (error) {
    console.log(error)
  } else {
    offering.value = data[0]
  }
}

function getLogoUrl() {
  const { data } = supabase.storage.from('offerings').getPublicUrl(`${offering.value.id}/logo`)
  return data.publicUrl
}

function getBannerUrl() {
  const { data } = supabase.storage.from('offerings').getPublicUrl(`${offering.value.id}/banner`)
  return data.publicUrl
}

async function getOfferingDetails() {
  const { data, error } = await supabase.functions.invoke('get-offering-details', { body: { 'offeringId': offering_id.value } })
  if (data) {
    offeringDetails.value = data['Offering purchased details'][0]
  } else {
    console.log('Failure: ', error)
  }
  isLoadingOfferingDetails.value = false
}

async function getOfferingDocuments() {
  const { data, error } = await supabase.functions.invoke('get-offering-documents', { body: { 'offeringId': offering_id.value } })
  if (data) {
    docs.value = data.document_details
  } else {
    console.log('Failure: ', error)
  }
  isLoadingOfferingDocs.value = false
}

function setSection(selectedSection) {
  if (selectedSection == 'overview') {
    visibleSection.value = 'overview'
    overviewClass.value = 'bg-white text-operacolor'
    scorecardsClass.value = ''
    documentsClass.value = ''
  }
  else if (selectedSection == 'scorecards') {
    visibleSection.value = 'scorecards'
    overviewClass.value = ''
    scorecardsClass.value = 'bg-white text-operacolor'
    documentsClass.value = ''
  }
  else {
    visibleSection.value = 'documents'
    overviewClass.value = ''
    scorecardsClass.value = ''
    documentsClass.value = 'bg-white text-operacolor'
  }
}

function getDate(d) {
  if (!d) return 'Not Applicable';
  const date = new Date(d);
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = months[date.getMonth()]; // Get the month
  const day = date.getDate(); // Get the day
  const year = date.getFullYear(); // Get the year
  let daySuffix;
  switch (day % 10) {
    case 1:
      daySuffix = "st";
      break;
    case 2:
      daySuffix = "nd";
      break;
    case 3:
      daySuffix = "rd";
      break;
    default:
      daySuffix = "th";
      break;
  }
  if (day >= 11 && day <= 13) {
    daySuffix = "th";
  }
  return `${month} ${day}${daySuffix}, ${year}`; // Combine all the parts into the desired format
}

function getType(issueType) {
  switch (issueType) {
    case 'Equity':
      return 'Shares';
    case 'Fund':
      return 'Investment Amount';
    default:
      return 'Units';
  }
}

const getPlaceholderValue = () => {
  switch (offering.value.offering_type) {
    case 'Equity':
      return 'Number of Shares';
    case 'Fund':
      return 'Dollar amount';
    default:
      return 'Number of Units';
  }
}

function setSocials() {
  if (offering.value.linkedin) {
    socials.value.push(
      {
        name: 'LinkedIn',
        href: offering.value.linkedin,
        icon: markRaw(defineComponent({
          render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 20 20' }, [
              h('path', {
                d: 'M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z',
                'fill-rule': 'evenodd',
                'clip-rule': 'evenodd',
              },),
            ]),
        })),
      },
    )
  }
  if (offering.value.instagram) {
    socials.value.push(
      {
        name: 'Instagram',
        href: offering.value.instagram,
        icon: markRaw(defineComponent({
          render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
              h('path', {
                'fill-rule': 'evenodd',
                d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
                'clip-rule': 'evenodd',
              }),
            ]),
        })),
      }
    )
  }
  if (offering.value.facebook) {
    socials.value.push(
      {
        name: 'Facebook',
        href: offering.value.facebook,
        icon: markRaw(defineComponent({
          render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
              h('path', {
                'fill-rule': 'evenodd',
                d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
                'clip-rule': 'evenodd',
              }),
            ]),
        })),
      }
    )
  }
  if (offering.value.twitter) {
    socials.value.push(
      {
        name: 'Twitter',
        href: offering.value.twitter,
        icon: markRaw(defineComponent({
          render: () =>
            h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
              h('path', {
                d: 'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84',
              }),
            ]),
        })),
      },
    )
  }
  if (offering.value.website) {
    socials.value.push(
      {
        name: 'Site',
        href: offering.value.website,
        icon: GlobeAltIcon,
      },
    )
  }
  if (offering.value.additional_link) {
    socials.value.push(
      {
        name: 'Fact Right',
        href: offering.value.additional_link,
        icon: LinkIcon,
      },
    )
  }
}

// Watchers
watch(numShares, () => {
  numShares.value = numShares.value.replace(/[^,0-9]/g, '')
  numShares.value = numShares.value.replace(/,/gi, "")
  numShares.value = numShares.value.split(/(?=(?:\d{3})+$)/).join(",")
  if (numShares.value) {
    investmentTotal.value = parseInt(numShares.value.replace(/[^0-9]/g, '')) * parseInt(offering.value.unit_price)
  } else {
    investmentTotal.value = 0
  }
})
</script>