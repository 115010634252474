<template>
  <div v-if="props.steps.length > 0">
    <nav class="flex items-center justify-center" aria-label="Progress">
      <p class="text-sm font-medium dark:text-neutral-300">Step {{ steps.findIndex((step) => step.status === 'current') + 1 }} of {{ steps.length }}</p>
      <ol role="list" class="ml-8 flex items-center space-x-5">
        <li v-for="step in steps" :key="step.name">
          <div v-if="step.status === 'complete'" class="block h-2.5 w-2.5 rounded-full bg-operacolor">
            <span class="sr-only">{{ step.name }}</span>
          </div>
          <div v-else-if="step.status === 'current'" class="relative flex items-center justify-center" aria-current="step">
            <span class="absolute flex h-5 w-5 p-px" aria-hidden="true">
              <span class="h-full w-full rounded-full bg-operacolor opacity-30" />
            </span>
            <span class="relative block h-2.5 w-2.5 rounded-full bg-operacolor" aria-hidden="true" />
            <span class="sr-only">{{ step.name }}</span>
          </div>
          <div v-else class="block h-2.5 w-2.5 rounded-full bg-operacolor opacity-30">
            <span class="sr-only">{{ step.name }}</span>
          </div>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script setup>
// Props
const props = defineProps({
  steps: {
    type: Array,
    required: true,
  },
})
</script>