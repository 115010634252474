import { defineStore } from 'pinia'
import { supabase } from '../lib/supabaseClient'
import { logErrorMessage } from '@/helper/helper'
// import { ref } from 'vue'


export const useCommentsStore = defineStore('comments', () => {
  // State (refs)

  // Getters, (computed properties)

  // Actions, (functions)
  async function insertComment(offering_id, partner_id, comment, commentor_name = 'Anonymous', comment_mood = 'nothing') {
    const { error } = await supabase.from('forum_comments').insert({ offering_id, partner_id, comment, commentor_name, comment_mood })
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function updateComment(offering_id, comment, commentor_name = 'Anonymous', comment_mood = 'nothing') {
    const { error } = await supabase.from('forum_comments').update({ comment, commentor_name, comment_mood }).eq('offering_id', offering_id)
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function deleteComment(id) {
    const { error } = await supabase.from('forum_comments').delete().eq('id', id)
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function getOfferingComments(offering_id) {
    const { data, error } = await supabase.from('forum_comments').select('*').eq('offering_id', offering_id).order('created_at', { ascending: true })
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  }

  // Not Callable Functions

  return {
    insertComment,
    updateComment,
    deleteComment,
    getOfferingComments
  }
})