<template>
    <div class="load-gif">
        <div class="circle-loader" :class="loadComplete">
            <div class="checkmark draw" :class="checkmarkClass"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoadGif',
    data() {
        return {
            loadComplete: '',
            checkmarkClass: 'checkmarkHide'
        }
    },
    methods: {
        completed() {
            this.loadComplete = 'load-complete';
            this.checkmarkClass = '';
        },
        resetLoadGif() {
          this.loadComplete = '';
          this.checkmarkClass = 'checkmarkHide';
        }
    }
}
</script>

<style scoped>
.circle-loader {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 70px;
  height: 70px;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #5cb85c;
  transition: border 500ms ease-out;
}

.checkmarkHide {
  display: none;
}
.checkmark.draw:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}
.checkmark:after {
  opacity: 1;
  height: 35px;
  width: 17px;
  transform-origin: left top;
  border-right: 2px solid #5cb85c;
  border-top: 2px solid #5cb85c;
  content: "";
  left: 15px;
  top: 35px;
  position: absolute;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 17px;
    opacity: 1;
  }
  40% {
    height: 35px;
    width: 17px;
    opacity: 1;
  }
  100% {
    height: 35px;
    width: 17px;
    opacity: 1;
  }
}
</style>