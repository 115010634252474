<template>
  <svg class="animate-spin mx-1 h-4 w-4 text-black button_spinner" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
</template>

<style>
.button_spinner {
  @apply text-black dark:text-white
}
.button_primary .button_spinner {
  @apply dark:!text-white !text-white
}
</style>