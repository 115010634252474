<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <NotificationPopup ref="notification" :type="notificationType" :title="notificationTitle" :text="notificationText" />
    <DocumentsSlideover v-if="showDocumentsSlideover" :show="showDocumentsSlideover" :party="party" @closed="showDocumentsSlideover = false" :user_id="userStore.user.id"
      @success="notify('success', 'Updated Successfully', 'The investors kyc and aml statuses have been updated successfully'); setPartiesWithAccountData()"
      @error="notify('failure', 'Sorry', 'Something went wrong and we were unable to update the investors kyc and aml status')" />

    <!-- Header -->
    <div>
      <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
        <!-- Tab Header -->
        <h3 class="primary_header">Investor Verification</h3>

        <div class="flex items-center gap-4">
          <!-- Search -->
          <div class="flex items-center gap-2">
            <div>
              <div class="relative rounded-md">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <MagnifyingGlassIcon class="w-4 h-4 text-gray-400 dark:text-neutral-400" aria-hidden="true" />
                </div>
                <input type="text" name="search" id="search" class="pl-8 input" placeholder="Quick Search" v-model="query" />
              </div>
            </div>
          </div>
          <!-- Transaction Action -->
          <!-- <button type="button" class="button_primary">
              Transaction Action
            </button> -->
        </div>
      </div>
      <div class="flex">
        <SwitchGroup as="div" class="flex items-center justify-between w-fit">
          <Switch v-model="isTable"
            class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-operacolor focus:ring-offset-2 dark:focus:outline-none dark:focus:ring-0 dark:focus:ring-operacolor dark:focus:ring-offset-0">
            <span class="sr-only">Use setting</span>
            <span aria-hidden="true" class="pointer-events-none absolute h-full w-full rounded-md bg-gray-50 dark:bg-neutral-950"></span>
            <span aria-hidden="true"
              :class="[isTable ? 'bg-operacolor' : 'bg-gray-200 dark:bg-neutral-700', 'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out']"></span>
            <span aria-hidden="true"
              :class="[isTable ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out']"></span>
          </Switch>
          <SwitchLabel as="span" class="ml-3 text-sm">
            <span class="font-medium text_color_primary">Table View</span>
          </SwitchLabel>
        </SwitchGroup>
      </div>
    </div>
    <!-- Investor Verification Table -->
    <TablePrimary v-if="isTable" :rows="filteredInvestors" :columns="columns" :isClickable="true" @click-primary="handleInvestorClick" export_title="needs_verification" class="-mt-5" />

    <!-- Investor Verification Cards -->
    <div v-else role="list" class="flex gap-6 flex-wrap mt-4">
      <div v-for="i in filteredInvestors" :key="i.id" @click="handleInvestorClick(i)"
        class="divide-y divide-gray-200 rounded-lg bg-white shadow-opera dark:shadow-none cursor-pointer border w-96 border-white hover:border-gray-300 hover:border dark:bg-neutral-800 dark:border-neutral-800 dark:hover:border-neutral-600">
        <VerificationCard :investor="i" :role="userStore.user.profiles_protected.role" />
      </div>
    </div>

  </div>
</template>

<script setup>
// Essentials
import { onMounted, computed, ref } from 'vue';
import { usePartiesStore } from '@/stores/parties';
import { useUserStore } from '@/stores/user';
// Components
import TablePrimary from '@/components/widgets/TablePrimary.vue';
import DocumentsSlideover from '@/components/slideovers/DocumentsSlideover.vue';
import NotificationPopup from '@/components/popups/NotificationPopup.vue';
import VerificationCard from '@/components/cards/VerificationCard.vue';
// Libraries
// import { UsersIcon, EllipsisVerticalIcon } from '@heroicons/vue/24/outline'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
import { SwitchGroup, Switch, SwitchLabel } from '@headlessui/vue';
// Stores
const userStore = useUserStore();
const partiesStore = usePartiesStore();
// Props
// Globals
const isTable = ref(true);
if (window.innerWidth < 1024) isTable.value = false
const showDocumentsSlideover = ref(false)
const party = ref(null)
const loading = ref(true)
const investors = ref([])
const query = ref('')
const notification = ref(null)
const notificationType = ref('succes')
const notificationTitle = ref('Success')
const notificationText = ref('')
const columns = [
  { key: 'first_name', label: 'Name', type: 'double', key2: 'last_name' },
  { key: 'contact_email', label: 'Email' },
  { key: 'parties_protected.kyc', label: 'KYC Status', type: 'badge-alt' },
  { key: 'parties_protected.aml', label: 'AML Status', type: 'badge-alt' },
  { key: 'parties_protected.aml_updated_at', label: 'AML Updated', type: 'date' },
  { key: 'accounts[0].accounts_protected.accredited_status', label: 'Accredited Status', type: 'badge' },
  { key: 'created_at', label: 'Created', type: 'date' }
]

// Mounted
onMounted(async () => {
  // Get all investors who failed kyc or aml
  await setPartiesWithAccountData()
  loading.value = false
})

// Computed
const filteredInvestors = computed(() => {
  return query.value === '' ? investors.value :
    investors.value.filter((investor) => {
      if (investor?.contact_email?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor?.first_name?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor?.last_name?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor?.id?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor?.phone?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor?.zip?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor?.parties_protected?.kyc?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor?.parties_protected?.aml?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (investor?.accounts[0]?.accounts_protected?.accredited_status?.toLowerCase().includes(query.value.toLowerCase())) return true;
    });
});

// Functions
async function setPartiesWithAccountData() {
  investors.value = await partiesStore.getAllPartiesWithAccountData()
  if (!investors.value) notify('success', 'No Investors', 'There are no investors that need verification')
}

// Simple Functions
const handleInvestorClick = (p) => {
  party.value = p
  showDocumentsSlideover.value = true
}

const notify = (nType, nTitle, nText) => {
  notificationType.value = nType
  notificationTitle.value = nTitle
  notificationText.value = nText
  notification.value.show()
}
// Watchers
</script>
