<template>
  <div class="flow-root mt-4">
    <div>
      <div class="inline-block w-full align-middle">
        <div class="overflow-x-scroll rounded-lg shadow dark:shadow-none sm:overflow-x-hidden ring-1 ring-black ring-opacity-5">
          <table class="min-w-full divide-y divide-gray-300 dark:divide-neutral-700">
            <thead class="bg-gray-50 dark:bg-neutral-800">
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-neutral-300 sm:pl-6">Name</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-neutral-300">Group</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-neutral-300">Total Account Value</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-neutral-300">Available Cash</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-neutral-300">Account Type</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-neutral-300">Status</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-neutral-300">%</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900">
              <tr v-for="account in props.accounts" :key="account.id" class="cursor-pointer hover:bg-gray-100 dark:hover:bg-neutral-950" @click="handlePrimaryClick(account)">
                <td class="flex py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-neutral-100 sm:pl-6 whitespace-nowrap">{{ account.name }}</td>
                <td v-if="account.group" class="px-3 py-4 text-sm text-operacolor whitespace-nowrap">
                  <span @click.stop="handleSecondaryClick(account)" class="badge_gray">
                    <UserGroupIcon class="w-4 h-4 mr-1" />{{ account.group }}
                  </span>
                </td>
                <td v-else class="px-3 py-4 text-xs text-blue-300 dark:text-neutral-400 whitespace-nowrap">
                  <span @click.stop="handleSecondaryClick(account)" class="flex gap-1 items-center w-fit hover:text-blue-600">
                    <UserPlusIcon class="w-4 h-4" />Add Group
                  </span>
                </td>
                <td class="px-3 py-4 text-sm text-gray-500 dark:text-neutral-300 whitespace-nowrap">{{ getTotalAccountValue(account?.positions) }}</td>
                <td class="px-3 py-4 text-sm text-gray-500 dark:text-neutral-300 whitespace-nowrap">{{ getAccountCash(account?.positions) }}</td>
                <td class="px-3 py-4 text-sm text-gray-500 dark:text-neutral-300 whitespace-nowrap">
                  <span :class="getBadgeClass(account?.type)">{{ getAccountType(account.type) }}</span>
                </td>
                <td class="px-3 py-4 text-sm text-gray-500 dark:text-neutral-300 whitespace-nowrap">
                  <span :class="getBadgeClass(account?.accounts_protected?.approval_status)">{{ capitalizeFirstLetter(account?.accounts_protected?.approval_status) }}</span>
                </td>
                <td class="px-3 py-4 text-sm text-gray-500 dark:text-neutral-300 whitespace-nowrap">{{ getAccountPercent(account?.positions) }}</td>
              </tr>
            </tbody>
          </table>
          <div class="items-center justify-center w-full" v-if="accounts.length <= 0">
            <div class="flex items-center justify-center w-full py-5 dark:bg-neutral-700 dark:text-neutral-300">No Accounts</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref } from 'vue'
// Libraries
import { getBadgeClass, formatMoney, capitalizeFirstLetter, getAccountType } from '@/helper/helper';
import { UserGroupIcon, UserPlusIcon } from '@heroicons/vue/20/solid'

// Props
const props = defineProps({
  accounts: { type: Array, required: true, default: null },
})
// Emits 
const emit = defineEmits(['click-primary', 'click-secondary'])
// Globals
const allAccountsValue = ref(0);

// Functions
const getTotalAccountValue = (positions) => {
  let totalAccountValue = 0;
  positions?.forEach((position) => {
    totalAccountValue += position.amount * position.price_per_unit;
  });
  return formatMoney(totalAccountValue);
};

const getAccountCash = (positions) => {
  let totalAcountCash = 0;
  positions?.forEach((position) => {
    if (position.units === 'cash') totalAcountCash += position.amount * position.price_per_unit;
  });
  return formatMoney(totalAcountCash);
};

const getAccountPercent = (positions) => {
  let accountValue = 0;
  positions?.forEach((position) => {
    accountValue += position.amount * position.price_per_unit;
  });

  if (accountValue === 0 || allAccountsValue.value === 0) return '0%';

  const percentage = ((accountValue / allAccountsValue.value) * 100).toFixed(0);
  return `${percentage}%`;
};

const handlePrimaryClick = (row) => {
  emit('click-primary', row)
}

const handleSecondaryClick = (row) => {
  emit('click-secondary', row)
}
</script>