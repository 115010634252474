<template>
  <div>

    <!-- LOADING -->
    <div v-if="loading" class="w-full md:h-[70vh] h-[50vh] flex items-center justify-center">
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- CONTENT -->
    <div v-else class="flex flex-col gap-10">
      <!-- Account Documents -->
      <AccreditedDocuments :account="props.account" :user_id="userStore.user.id" :partner_type="partnerStore.partner.partners_protected.partner_type" />

      <!-- Subscription Documents -->
      <SubscriptionDocuments :account_id="props.account.id" :tapi_account_id="props.account.tapi_account_id" />
    </div>

  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from 'vue'
// import { supabase } from '@/lib/supabaseClient'
import { usePartnerStore } from '@/stores/partner'
import { useUserStore } from '@/stores/user'
// Components
import AccreditedDocuments from '@/components/documents/AccreditedDocuments.vue'
import SubscriptionDocuments from '@/components/documents/SubscriptionDocuments.vue'
import SpinnerFlip from '@/components/loading/SpinnerFlip.vue'
// Props
const props = defineProps({
  account: {
    type: Object,
    required: true,
    default: null
  },
})
// Stores
const partnerStore = usePartnerStore()
const userStore = useUserStore()
// Globals
const loading = ref(true)

onMounted(async () => {
  loading.value = false
})
</script>