<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <!-- Popups -->
    <NotificationPopup :type="notificationType" :title="notificationTitle" :text="notificationText" ref="notification" />
    <!-- End Popups -->

    <!-- Loading -->
    <div v-if="loading" class="flex flex-grow items-center justify-center h-[70vh]">
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Ready -->
    <div v-else>

      <div>
        <!-- Empty State -->
        <div v-if="holdingPositions.length <= 0 && holdingTransactions.length <= 0">
          <div class="text-center">
            <WalletIcon class="w-12 h-12 mx-auto text-gray-400" />
            <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300">No Holdings</h3>
            <p class="mt-1 text-sm text-gray-500">Get started by investing with the invest tab.<br />You must fill out your profile information first.</p>
            <div class="mt-6">
              <button v-if="userStore.user?.party_id" type="button" @click="navigateToInvest()" class="button_primary">
                Invest now
                <ArrowLongRightIcon class="w-5 h-5" aria-hidden="true" />
              </button>
              <button v-else type="button" @click="navigateToSettings()" class="button_primary">
                Add Profile
                <ArrowLongRightIcon class="w-5 h-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        <!-- Has Holdings -->
        <div v-else>
          <!-- Header -->
          <div>
            <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
              <!-- Tab Header -->
              <h3 class="primary_header">Holdings</h3>
            </div>
          </div>

          <div class="flex flex-col gap-10">
            <!-- Holdings Table -->
            <div v-if="holdingPositions.length > 0">
              <div class="secondary_header mb-4">Positions</div>
              <TablePrimary :rows="holdingPositions" :columns="positionsColumns" :isClickable="false" :allow_export="false" />
            </div>

            <!-- Transactions Table -->
            <div v-if="holdingTransactions.length > 0">
              <div class="secondary_header mb-4">Transactions</div>
              <TablePrimary v-if="holdingTransactions.length > 0" :rows="holdingTransactions" :columns="transactionColumns" :isClickable="false" :allow_export="false" />
            </div>

            <!-- Unsgined Docs -->
            <div>
              <SubscriptionDocuments :account_id="accountsStore?.holdingAccount?.id" :tapi_account_id="accountsStore?.holdingAccount?.tapi_account_id" />
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useAccountsStore } from '@/stores/accounts'
import { usePositionsStore } from '@/stores/positions'
import { useTransactionsStore } from '@/stores/transactions'
// Components
import SubscriptionDocuments from '@/components/documents/SubscriptionDocuments.vue'
import NotificationPopup from '@/components/popups/NotificationPopup.vue'
import SpinnerFlip from '@/components/loading/SpinnerFlip.vue';
import TablePrimary from '@/components/widgets/TablePrimary.vue'
// Libraries
import { ArrowLongRightIcon, WalletIcon } from '@heroicons/vue/24/outline'
// Stores
const userStore = useUserStore()
const accountsStore = useAccountsStore()
const positionsStore = usePositionsStore()
const transactionsStore = useTransactionsStore()
// Globals
const router = useRouter()
const loading = ref(true);
const holdingPositions = ref([]);
const holdingTransactions = ref([]);
const notification = ref(null)
const notificationType = ref('success');
const notificationTitle = ref('Success');
const notificationText = ref('');
const transactionColumns = [
  { key: 'offerings.name', label: 'Name' },
  { key: 'amount', label: 'Total Value', type: 'total', key2: 'price_per_unit' },
  { key: 'transaction_method', label: 'Transaction Type', type: 'badge' },
  { key: 'units', label: 'Unit Type', type: 'badge' },
  { key: 'price_per_unit', label: 'Unit Price', type: 'money' },
  { key: 'status', label: 'Status', type: 'badge' },
  { key: 'created_at', label: 'Created Date', type: 'date' },
];
const positionsColumns = [
  { key: 'offerings.name', label: 'Name' },
  { key: 'amount', label: 'Total Value', type: 'total', key2: 'price_per_unit' },
  { key: 'units', label: 'Unit Type', type: 'badge' },
  { key: 'price_per_unit', label: 'Unit Price', type: 'money' },
  { key: 'created_at', label: 'Date', type: 'date' },
];

// Mounted
onMounted(async () => {
  // Check that profile has an associated party
  if (!userStore?.user?.party_id) { loading.value = false; return }
  
  // Get holding account positions
  holdingPositions.value = await positionsStore.getAllPositionsByAccountId(accountsStore?.holdingAccount?.id);

  // Get holding account transactions
  holdingTransactions.value = await transactionsStore.getAllTransactionByAccountId(accountsStore?.holdingAccount?.id);

  // Ready
  loading.value = false;
});

// Simple Functions
const navigateToSettings = () => {
  router.push('/dashboard/settings');
};

const navigateToInvest = () => {
  router.push('/dashboard/investing');
};

</script>
