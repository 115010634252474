<template>
  <div class="flex flex-col gap-2 sm:p-6 p-4">
    <div class="flex-1 truncate">
      <div class="flex items-center space-x-3">
        <h3 v-if="props.user?.parties?.first_name" class="truncate text-sm font-medium text-gray-900 dark:text-white">
        {{ `${props.user?.parties?.first_name ?? '-'} ${props.user?.parties?.last_name ?? ''}` }}</h3>
        <span :class="props.user.profiles_protected.role">
          {{ capitalizeFirstLetter(props.user.profiles_protected.role) }}
        </span>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <p class="flex items-center gap-1 truncate text-sm">
        <EnvelopeIcon class="w-3 h-3" />
        {{ props.user.email }}
      </p>
      <div class="flex justify-between flex-wrap items-center">
        <div v-if="props.user?.parties?.phone" class="flex items-center gap-1 truncate text-sm">
          <PhoneIcon class="w-3 h-3" />
          {{ props.user?.parties?.phone }}
        </div>
        <div class="mt-1 text-xs text-gray-500 dark:text-neutral-400">{{ formatDate(props.user.created_at) }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Libraries
import { capitalizeFirstLetter, formatDate } from '@/helper/helper';
import { PhoneIcon, EnvelopeIcon } from '@heroicons/vue/20/solid';
// Props
const props = defineProps({
  user: {
    type: Object,
    required: true,
    default: null
  }
});
</script>