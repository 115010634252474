<template>
  <div class="w-full px-10 py-12 font-medium bg-gray-800 lg:px-32 h-fit rounded-2xl">
    <div class="flex flex-col">
      <!-- Logo -->
      <div class="flex items-center gap-1">
        <img class="h-10" :src="logo">
        <div class="text-xl text-white">OPERA</div>
      </div>
      <!-- Disclosure -->
      <div class="mt-2 text-sm font-light text-gray-200">
        Investments involve risk, including the risk of loss of principal. You should carefully consider your investment objectives, risks, transaction costs and other expenses before deciding to
        invest in
        futures or other investments. North Capital Private Securities Corporation is a registered broker dealer, member <a class="text-sky-400" href="http://www.finra.org/" target="_blank">FINRA</a>
        | <a class="text-sky-400" href="http://www.sipc.org/" target="_blank">SIPC</a> View the firm's <a class="text-sky-400"
          href="https://app.hubspot.com/documents/4042879/view/266393663?accessId=7d6a1d" target="_blank">Form CRS</a>.
        The relationship between North Capital Investment Technology, North Capital Inc., and North Capital Private Securities Corporation is described in our Disclosure Documents and <a
          class="text-sky-400" href="https://www.northcapital.com/disclosure-documents" target="_blank">Code of Ethics</a>. Check the background of this firm on FINRA's <a class="text-sky-400"
          href="https://brokercheck.finra.org/firm/summary/154559" target="_blank">BrokerCheck</a> <a class="text-sky-400" href="https://www.northcapital.com/customer-complaint-form/"
          target="_blank">Submit a Complaint</a> |
        <a class="text-sky-400" href="https://app.hubspot.com/documents/4042879/preview/6800565" target="_blank">Additional Disclosures</a>
      </div>

      <!-- Links -->
      <div class="flex flex-wrap justify-between gap-5 mt-8 text-white">
        <div class="flex flex-col gap-2">
          <div class="text-white">Platforms</div>
          <a class="text-sm font-light text-gray-200 cursor-pointer hover:text-operacolorlight" href="https://www.northcapital.com/" target="_blank">North Capital</a>
          <a class="text-sm font-light text-gray-200 cursor-pointer hover:text-operacolorlight" href="https://app.accredited.am/" target="_blank">Accredited</a>
          <a class="text-sm font-light text-gray-200 cursor-pointer hover:text-operacolorlight" href="https://transactapi.readme.io/" target="_blank">Transact API</a>
          <a class="text-sm font-light text-gray-200 cursor-pointer hover:text-operacolorlight" href="https://ats.ppex.com/" target="_blank">PPEX</a>
        </div>
        <div class="flex flex-col gap-2">
          <div class="text-white">Resources</div>
          <div class="text-sm font-light text-gray-200">Help Pages</div>
          <div class="text-sm font-light text-gray-200">Blog</div>
          <div class="text-sm font-light text-gray-200">Video Tutorials</div>
        </div>
        <div class="flex flex-col gap-2">
          <div class="text-white">Company</div>
          <div class="text-sm font-light text-gray-200 cursor-pointer hover:text-operacolorlight" @click="this.$emit('contact')">Contact Us</div>
          <a href="https://hubs.ly/Q01yYmG_0" target="_blank" class="text-sm font-light text-gray-200 cursor-pointer hover:text-operacolorlight">Terms of Use</a>
          <a href="https://app.hubspot.com/documents/4042879/view/473022400?accessId=2a9568" target="_blank" class="text-sm font-light text-gray-200 cursor-pointer hover:text-operacolorlight">Privacy
            Policy</a>
          <a target="_blank" href="http://www.aicpa.org/soc4so">
            <img :src="socLogo" alt="SOC Logo" class="w-10 h-10">
          </a>
        </div>
      </div>

      <!-- Divider -->
      <div class="w-full mt-8 mb-5 border-t border-gray-600"></div>

      <!-- Copyright -->
      <div class="text-xs text-center text-white font-extralight">&copy; 1999-2024. All Rights Reserved</div>


    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
// import { supabase } from '@/lib/supabaseClient'

const logo = computed(() => {
  return 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/logo.png'
})
const socLogo = computed(() => {
  return 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/assets/soc.png'
})
</script>