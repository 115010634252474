<template>
  <div> <!-- Leave this outer div empty -->
    <div class="flex flex-col w-fit max-w-[1000px]">
      <img class="w-full" :src="image" />
      <div class="flex flex-wrap items-end justify-between mt-10 gap-y-5">
        <div class="text-3xl font-bold">Private Investments-Not Without Risk</div>
        <div class="text-sm">by: Grant Nelson</div>
      </div>

      <div class="flex flex-col gap-5 my-10 w-full max-w-[1000px]">
        <!-- Paragraph 1 -->
        <div>
          Private investments have long been an attractive avenue for investors seeking returns and diversification outside of traditional markets. However, as with any investment, they come with a
          certain level of risk. Understanding and managing these risks is essential for anyone considering venturing into the world of private investments. In this article, we will explore a few of the
          key risks associated with investments outside of the public markets. These risks are broad
        </div>
        <!-- Paragraph 2 -->
        <div class="text-xl font-semibold">Lack of Liquidity</div>
        <div>
          One of the primary risks of private investments is the lack of liquidity compared to publicly traded assets. Unlike stocks and bonds, private investments often have longer lock-in periods, and
          it may take years before investors can access their capital. This illiquidity can be a significant concern, especially during unforeseen emergencies or financial hardships, leaving investors
          with limited options to liquidate their investments.
        </div>
        <!-- Paragraph 3 -->
        <div class="text-xl font-semibold">Volatility</div>
        <div>
          Private investments, particularly in early-stage startups and venture capital, are susceptible to higher volatility compared to established public companies. These ventures often lack a track
          record and can experience rapid fluctuations in value based on market trends, business performance, or regulatory changes. Just because an investment’s price doesn't change minute to minute
          like those in the public markets, does not mean it's exempt from extreme volatility.
        </div>
        <!-- Paragraph 4 -->
        <div class="text-xl font-semibold">Limited Transparency</div>
        <div>
          Unlike publicly traded companies that must adhere to strict regulatory requirements, private companies may provide limited financial and operational transparency. As a result, investors may
          face challenges in obtaining comprehensive and up-to-date information about their investments, making it difficult to assess the true health of the company.
        </div>
        <!-- Paragraph 5 -->
        <div class="text-xl font-semibold">Complete Loss of Capital</div>
        <div>
          Startups and companies with limited operating histories inherently carry a higher risk of failure due to the uncertainties surrounding their business models and the competitive landscape.
          While some may achieve significant success, others may struggle or even cease operations entirely.
        </div>
        <!-- Paragraph 6 -->
        <div class="text-xl font-semibold">Regulatory and Legal</div>
        <div>
          Private investments can be subject to unique regulatory and legal risks, depending on the jurisdiction and the type of investment. These risks may include changes in tax laws, compliance
          requirements, or unexpected legal disputes.
        </div>
        <!-- Conclusion -->
        <div class="text-xl font-semibold">Conclusion</div>
        <div>
          Private investments offer enticing opportunities for investors, but they also come with inherent risks that demand careful consideration and planning. It is essential for investors to educate
          themselves thoroughly about the risks associated with private investments and seek guidance from qualified professionals.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { supabase } from '@/lib/supabaseClient'
export default {
  data() {
    return {
      image: 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/privateInvestments/stock'
    }
  }
}
</script>