<template>
  <div class="px-4 dark:text-neutral-300 sm:px-6 lg:px-8">

    <div v-if="!isArticle" class="flex flex-col w-full border-box gap-7">
      <div class="flex flex-col w-full gap-10 border-box">

        <div class="flex flex-col flex-wrap gap-4">
          <div class="text-3xl">Insights & Education</div>
          <div class="pb-4 border-b-2 border-neutral-300 w-fit">Keeping you at the forefront of modern investing</div>
        </div>

        <!-- <div class="flex flex-col gap-4 pb-10 border-b-2 border-border">
          <div class="text-xl font-bold">This week's highlight</div>
          <div class="flex flex-wrap items-end gap-5">
            <div class="h-[300px] w-[500px] bg-[center_center] bg-no-repeat bg-cover" :style="{ backgroundImage: 'url(' + highlight.image + ')' }" ref="images"></div>
            <div class="flex flex-col gap-5">
              <div class="font-semibold cursor-pointer text-operacolordark w-fit">Markets and Economy</div>
              <div class="text-xl font-bold">{{ highlight.title }}</div>
              <div class="w-full max-w-[500px]">{{ highlight.description }}</div>
              <div>{{ highlight.date }} - {{ highlight.author }}</div>
              <router-link :to="highlight.link" class="button_primary w-fit">
                Learn more
                <ArrowSmallRightIcon class="w-4 h-4" />
              </router-link>
            </div>
          </div>
        </div> -->

        <div class="flex flex-wrap gap-10">
          <div v-for="article in articles" :key="article.id" class="flex flex-col w-72">
            <div class="border-t border-l border-r border-border dark:border-neutral-600 h-52 w-72 bg-[center_center] bg-no-repeat bg-cover"
              :style="{ backgroundImage: 'url(' + article.image + ')' }" ref="images"></div>
            <div class="border-b border-l border-r border-border dark:border-neutral-600 pt-2.5 flex flex-col gap-2.5">
              <div class="w-full h-12 px-4 overflow-hidden font-bold">{{ article.title }}</div>
              <div class="w-full h-24 px-4 overflow-hidden text-sm">{{ article.description }}</div>
              <div class="grow"></div>
              <div class="flex justify-end px-4 pb-4 text-center">
                <router-link :to="article.link" class="button_primary">
                  Learn more
                  <ArrowSmallRightIcon class="w-4 h-4" />
                </router-link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div v-else>
      <div class="flex flex-col gap-10">
        <router-link to="/dashboard/education" class="button_secondary w-fit">
          <ArrowSmallLeftIcon class="w-4 h-4" />
          Back
        </router-link>
        <!-- Articles -->
        <router-view v-slot="{ Component }" class="flex justify-center w-full">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </div>

    </div>


  </div>
</template>

<script setup>
import { ArrowSmallRightIcon, ArrowSmallLeftIcon } from '@heroicons/vue/24/outline'
</script>

<script>
// import { supabase } from '@/lib/supabaseClient'
export default {
  data() {
    return {
      isArticle: false,
      highlight: {
        link: '/dashboard/education/article-sipcimportance',
        title: 'The SIPC and Its Importance in Financial Markets',
        description: `Investors have enough to worry about. Investing is risky and investments can lose value for seemingly insignificant reasons. The Securities Investor Protection Corporation("SIPC") was created because investors shouldn't have to worry about losing all their assets if a brokerage firm becomes insolvent.`,
        date: 'August 17th, 2023',
        author: 'Grant Nelson',
        image: 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/sipc/buildings',
      },
      articles: [
        {
          link: '/dashboard/education/article-ratingmethodology',
          title: 'Fund Rating Methodology', 
          description: 'This document aims to provide a general description of the procedures and methodologies used to determine ratings of different private alternative funds listed on Opera.',
          image: 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/methodology/meetingLaptop',
        },
        {
          link: '/dashboard/education/article-investmentrisk',
          title: 'Private Investments-Not Without Risk',
          description: 'Private investments have long been an attractive avenue for investors. However, as with any investment, they come with a certain level of risk.',
          image: 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/privateInvestments/stock',
        },
        {
          link: '/dashboard/education/article-understandingregd',
          title: 'Understanding Rule 506 of Regulation D',
          description: 'Under this provision, companies can raise an unlimited amount of capital from accredited investors',
          image: 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/regD/suit',
        },
        {
          link: '/dashboard/education/article-sipcimportance',
          title: 'The SIPC and Its Importance in Financial Markets',
          description: `Investors have enough to worry about. Investing is risky and investments can lose value for seemingly insignificant reasons.`,
          image: 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/sipc/buildings',
        },
        {
          link: '/dashboard/education/article-custodianbd',
          title: 'Custodian Broker Dealers',
          description: 'The origins of Custodian BDs: As financial markets expanded and investment activity grew, the need for securestorage and trading of securities became apparent.',
          image: 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/custodianBrokerDealer/world',
        },
        {
          link: '/dashboard/education/article-moneymarketmutualfund',
          title: 'What is a Money Market Mutual Fund?',
          description: 'Money market funds are a specific type of mutual fund designed for investing short-term cash (funds that you will need access to within two years). Understanding their inner workings is key.',
          image: 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/moneyMarketMutualFund/moneyMarketMutualFund',
        },
        {
          link: '/dashboard/education/article-charitablegivings',
          title: 'The Many (Financial) Benefits of Charitable Giving',
          description: 'Many of us are charitably inclined and enjoy the idea of our money having a positive impact on the world. While good intentions should be the initial desire to donate and support causes, there are also several potential financial benefits.',
          image: 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/charitableGiving/charitableGivingBills',
        },
        {
          link: '/dashboard/education/article-moneymarketsavings',
          title: 'Government Money Market Funds VS Savings Accounts?',
          description: 'Investors have many choices when deciding where to park their short-term cash. Two preferred locations are government money market funds and bank savings accounts.',
          image: 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/moneyMarketAndSavings/moneyMarketAndSavingsWater',
        },
      ]
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler(to) {
        if (to.path == '/dashboard/education' || to.path == '/dashboard/education/') { this.isArticle = false; window.scrollTo(0, 0); }
        else { this.isArticle = true; window.scrollTo(0, 0); }
      }
    }
  },
};
</script>

<style>
.slide {
  width: 92px;
}
.slide:hover {
  width: 113px;
}
</style>