<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white dark:bg-neutral-950 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="open = false">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <!-- Content -->
              <div class="sm:flex sm:items-start">
                <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 dark:bg-blue-950 sm:mx-0 sm:h-10 sm:w-10">
                  <LockOpenIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 dark:text-neutral-100">Request Advisor Access</DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500 dark:text-neutral-400">
                      When you request an advisor account our team will begin the verification process to ensure you are in a
                      qualified financial advisor. This often
                      includes us reaching out via email for additional information.</p>
                  </div>
                </div>
              </div>
              <div class="mt-5 flex flex-row-reverse gap-3">
                <div class="button_primary" @click="sendAdvisorRequestEmail">Request</div>
                <div class="button_secondary" @click="open = false">Cancel</div>
              </div>
              <!-- Content -->
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// Essentials
import { useEmailsStore } from '@/stores/emails'
// Libraries
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon, LockOpenIcon } from '@heroicons/vue/20/solid'
// Props
const props = defineProps({
  party_id: { type: String, required: true, default: null },
  name: { type: String, required: false, default: null },
})
// Stores
const emailsStore = useEmailsStore();
// Models
const open = defineModel()
// Functions
const sendAdvisorRequestEmail = () => {
  open.value = false;
  emailsStore.sendAdvisorRequestEmail(props.party_id, props.name);
}
</script>