<template>
  <ul role="list" class="max-h-72 overflow-y-scroll rounded-b-lg w-full">
    <li v-for="document in documents" :key="document.name" class="flex justify-between sm:gap-x-6 p-2 mx-1 my-2 bg-white dark:bg-neutral-900 rounded-md ring-1 ring-gray-200 dark:ring-neutral-800">
      <div class="flex min-w-0 gap-x-4 items-center">
        <div class="bg-white ring-1 ring-gray-300 p-1.5 rounded-lg text-xs dark:bg-neutral-900 dark:ring-neutral-700 ml-1">
          <DocumentTextIcon class="text-operacolor w-5 h-5" />
        </div>
        <div class="min-w-0 flex flex-col items-start w-40">
          <p class="text-sm font-semibold text-gray-900 dark:text-neutral-300 truncate">{{ document.name }}</p>
          <p class="text-xs text-gray-500 dark:text-neutral-500 truncate">{{ formatFileSize(document.size) }}</p>
        </div>
      </div>
      <div class="flex gap-5 items-center">
        <div class="mr-2">
          <TrashIcon class="w-5 h-5 text-neutral-900 dark:text-neutral-400 hover:text-red-600 dark:hover:text-red-600 transition ease-in-out duration-500 cursor-pointer"
            @click="handleDeletIconClicked(document.name)" />
        </div>
      </div>
    </li>
  </ul>
</template>

<script setup>
// Libraries
import { formatFileSize } from '@/helper/helper'
import { TrashIcon } from '@heroicons/vue/24/outline'
import { DocumentTextIcon } from '@heroicons/vue/24/solid';
// Props
const props = defineProps({
  documents: { type: Array, required: true }, // Expects an array of file objects (DataTransfer files array)
})
// Emits
const emit = defineEmits(['remove'])
// Simple Functions
const handleDeletIconClicked = (document) => {
  emit('remove', document)
}
</script>
