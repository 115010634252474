<template>
  <div> <!-- Leave this outer div empty -->
    <div class="flex flex-col w-fit max-w-[1000px]">
      <img class="w-full" :src="image" />
      <div class="flex flex-wrap items-end justify-between mt-10 gap-y-5">
        <div class="text-3xl font-bold">The Many (Financial) Benefits of Charitable Giving</div>
        <div class="text-sm">by: North Capital</div>
      </div>

      <div class="flex flex-col gap-5 my-10 w-full max-w-[1000px]">
        <!-- Paragraph 1 -->
        <div>
          Many of us are charitably inclined and enjoy the idea of our money having a positive impact on the world. While good intentions should be the initial desire to donate and support causes, there
          are also several potential financial benefits.
          The main benefit comes from tax incentives that the IRS has created for taxpayers who want to give cash or assets to a charitable organization. Below are a few of the most popular and easily
          accessible ways to alleviate some tax burden whilst supporting one of your favorite causes.
          <br><br>
          Please note the tax aspects of charitable giving can be complex, so it is useful to consult a tax professional for guidance. A charitable donation will only qualify for a tax deduction if it
          goes to a tax-exempt organization as defined by section 501(c)(3) of the Internal Revenue Code.
        </div>
        <!-- Paragraph 2 -->
        <div class="text-xl font-semibold">Donations In-Kind</div>
        <div>
          Donating long term assets, such as securities that have appreciated, can be a tax-efficient way to support a charity. You can deduct the full fair market value of long-term capital assets that
          are donated while avoiding the need to pay capital gains taxes on the assets.
        </div>
        <!-- Paragraph 3 -->
        <div class="text-xl font-semibold">Example</div>
        <div>
          <span class="italic">
            John a salesman for a technology company, has had a banner year resulting in an adjusted gross income of $450,000 for the year. John is looking for ways to minimize his tax burden on this
            income. He has a brokerage account with a fair market value of $100,000. Within this account, he holds 1,000 shares of ABC Corp. that he purchased for $50 a share 3 years ago. The shares are
            now worth $100 per share. If John donates 1,000 shares to his favorite qualified charity, he will be able to claim a below-the-line tax deduction of $100,000 while also avoiding capital
            gains
            tax on the $50,000 gain of ABC Corp. This results in capital gains tax savings of $10,000 while additionally reducing taxable income by $100,000.
          </span>
          <br><br>
          There are a few key points to consider when making in-kind donations:
          <ul class="pl-8 list-disc">
            <li>Donations of long-term capital gain property are limited to 30% of adjusted gross income.</li>
            <li>You must itemize deductions on your tax return to take advantage of this strategy.</li>
            <li>The selected charity will need to have a brokerage account to accept the shares.</li>
          </ul>
        </div>
        <!-- Paragraph 4 -->
        <div class="text-xl font-semibold">Qualified Charitable Distribution (QCD)</div>
        <div>
          If you hold an Individual Retirement Account, you are required by the IRS to take an annual required minimum distribution (RMD) once you reach the age of 72. This distribution may result in
          more income than is needed or may create a higher tax obligation.
          <br><br>
          A direct transfer from your IRA to your favorite qualified charity can satisfy your RMD for the current year and help keep that distribution off your taxable income. Additionally, qualified
          charitable distributions can be made in-kind, thereby allowing you to avoid the deferred gain as illustrated above.
        </div>
        <!-- Paragraph 5 -->
        <div class="text-xl font-semibold">Donor Advised Fund (DAF)</div>
        <div>
          A donor-advised fund (DAF) is a charitable account that helps you organize your donations over a longer time period. A DAF can be useful if a taxpayer does not want to donate a large amount of
          money to charity in a single year or wants to spread contributions out over specific time period. A DAF allows you to contribute money and take a below-the-line deduction of that amount in the
          year the donation was made. One further advantage of a DAF is you do not have to select your charity immediately. You can donate to multiple qualified charities from your DAF for years to
          come. Each time you want to donate you simply make a grant request from your donor-advised fund, and it goes to the charity. Any funds that remain in the account can be invested with the
          potential to grow over time. There are some important considerations when considering a DAF:
          <ul class="pl-8 list-disc">
            <li>Once funds are in the DAF they must be given to a qualified charity.</li>
            <li>You can only take the charitable deduction in the year that the funds are put in the DAF.</li>
          </ul>
          <br><br>
          These are just a few of the charitable giving strategies that can help relieve your tax burden. Each of these strategies should be considered carefully and discussed with a tax professional.
          If you would like a more in-depth analysis of how charitable giving can help you, please feel free to contact us.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { supabase } from '@/lib/supabaseClient'
export default {
  data() {
    return {
      image: 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/charitableGiving/charitableGivingBills'
    }
  }
}
</script>