import { defineStore } from 'pinia'
import { supabase } from '../lib/supabaseClient'
import { logErrorMessage } from '@/helper/helper'
// import { ref } from 'vue'


export const useEmailsStore = defineStore('emails', () => {
  // State (refs)

  // Getters, (computed properties)

  // Actions, (functions)
  async function sendAdvisorRequestEmail(tapi_party_id, name = 'Unknown') {
    const { error } = await supabase.functions.invoke('send-advisor-request-email', { body: { partyId: tapi_party_id, name: name } })
    if (error) { console.log(await logErrorMessage(error)); return }
  }
  async function sendFeedback(feedback, location = '', name = 'Unknown', id = '', email = '') {
    var body = {
      feedback: feedback,
      location: location,
      name: name,
      user_id: id,
      email: email
    }
    const { error } = await supabase.functions.invoke('send-feedback-email', { body: body });
    if (error) console.error(error);
  }
  async function sendPartyInfoUpdatedNotification(email) {
    const { error } = await supabase.functions.invoke('send-party-update-email', { body: { 'email': email } })
    if (error) { console.log(await logErrorMessage(error)); return }
  }
  async function sendTransactionNotification(email, status, partner_meta_name = 'opera') {
    const { error } = await supabase.functions.invoke('send-transaction-notification-email', { body: { email: email, status: status, meta_name: partner_meta_name } })
    if (error) { console.log(await logErrorMessage(error)); return }
  }
  async function notifyDevs(offering_id) {
    const { error } = await supabase.functions.invoke('send-dev-email', { body: { offering_id: offering_id } })
    if (error) { console.log(await logErrorMessage(error)); return }
  }
  async function sendCustodyTransactionCreatedNotification(tapi_account_id, tapi_offering_id, offering_name, amount, tapi_trade_id) {
    const body = {
      tapi_account_id: tapi_account_id,
      offering_name: offering_name,
      tapi_offering_id: tapi_offering_id,
      amount: amount,
      tapi_trade_id: tapi_trade_id
    }
    const { error } = await supabase.functions.invoke('send-custody-transaction-request-email', { body: body })
    if (error) { console.log(await logErrorMessage(error)); return }
  }
  async function sendCustodyAccountCreatedNotification(tapi_account_id, first_name, last_name, email) {
    const body = {
      tapi_account_id,
      first_name,
      last_name,
      email,
    }
    const { error } = await supabase.functions.invoke('send-custody-account-created-email', { body: body })
    if (error) { console.log(await logErrorMessage(error)); return }
  }
  async function requestManualReview(party, partner_title) {
    const { error } = await supabase.functions.invoke('send-manual-kyc-review-email', { body: { party: party, partner_title: partner_title } })
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function sendDepositRequestEmail(account_id, tapi_account_id, amount, account_name) {
    const body = {
      id: account_id,
      name: account_name, 
      tapi_account_id: tapi_account_id,
      amount: amount
    };
    const { error } = await supabase.functions.invoke('send-deposit-request-email', { body });
    if (error) { console.log(await logErrorMessage(error)); return; }
    return true
  }
  async function sendWithdrawalRequestEmail(account_id, tapi_account_id, amount) {
    const body = {
      id: account_id,
      tapi_account_id: tapi_account_id,
      amount: amount
    };
    const { error } = await supabase.functions.invoke('send-withdrawal-request-email', { body });
    if (error) { console.log(await logErrorMessage(error)); return; }
    return true
  }
  async function sendThankYouEmailForInvesting(email, offering_name, investment_amount, partner, payment_method, number_of_shares) {
    const body = {
      email,
      offering_name,
      investment_amount,
      partner,
      payment_method,
      number_of_shares
    }
    const { error } = await supabase.functions.invoke('send-thank-you-email', { body: body })
    if (error) { console.log(await logErrorMessage(error)); return }
  }
  

  // Not Callable Functions
  
  return {
    sendAdvisorRequestEmail,
    sendFeedback,
    sendPartyInfoUpdatedNotification,
    sendTransactionNotification,
    notifyDevs,
    requestManualReview,
    sendDepositRequestEmail,
    sendWithdrawalRequestEmail,
    sendCustodyTransactionCreatedNotification,
    sendCustodyAccountCreatedNotification,
    sendThankYouEmailForInvesting
  }
})