<template>
  <form @submit.prevent="handleClick('next')" class="flex flex-col gap-5 p-5">
    <div class="flex flex-col gap-5 md:items-center md:flex-row">
      <div class="w-80">
        <label for="current_annual_income" class="input_label">{{ contextLabel }} Current Annual Income</label>
        <div class="relative mt-2 rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <span class="text-gray-500 dark:text-neutral-500 sm:text-sm">$</span>
          </div>
          <input class="input pl-6 pr-12" type="text" name="current_annual_income" id="current_annual_income" v-model="party.current_annual_income" required placeholder="0.00"
            aria-describedby="price-currency" aria-required />
          <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <span class="text-gray-500 dark:text-neutral-400 sm:text-sm">USD</span>
          </div>
        </div>
      </div>
      <div class="w-80">
        <label for="average_annual_income" class="input_label">{{ contextLabel }} Average Annual Income (past 2 years)</label>
        <div class="relative mt-2 rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <span class="text-gray-500 dark:text-neutral-500 sm:text-sm">$</span>
          </div>
          <input type="text" name="average_annual_income" id="average_annual_income" v-model="party.average_annual_income" required class="input pl-6 pr-12" placeholder="0.00"
            aria-describedby="price-currency" aria-required />
          <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <span class="text-gray-500 dark:text-neutral-400 sm:text-sm">USD</span>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-5 md:items-center md:flex-row">
      <div class="w-80">
        <label for="current_household_income" class="input_label">{{ contextLabel }} Current Household Income</label>
        <div class="relative mt-2 rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <span class="text-gray-500 dark:text-neutral-500 sm:text-sm">$</span>
          </div>
          <input type="text" class="input pl-6 pr-12" name="current_household_income" id="current_household_income" v-model="party.current_household_income" required placeholder="0.00"
            aria-describedby="price-currency" aria-required />
          <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <span class="text-gray-500 dark:text-neutral-400 sm:text-sm">USD</span>
          </div>
        </div>
      </div>
      <div class="w-80">
        <label for="average_household_income" class="input_label">{{ contextLabel }} Average Household Income (past 2 years)</label>
        <div class="relative mt-2 rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <span class="text-gray-500 dark:text-neutral-500 sm:text-sm">$</span>
          </div>
          <input type="text" class="input pl-6 pr-12" name="average_household_income" id="average_household_income" v-model="party.average_household_income" required placeholder="0.00"
            aria-describedby="price-currency" aria-required />
          <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <span class="text-gray-500 dark:text-neutral-400 sm:text-sm">USD</span>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-5 md:items-center md:flex-row">
      <div class="w-80">
        <label for="networth" class="input_label">{{ contextLabel }} Current Household Networth</label>
        <div class="relative mt-2 rounded-md shadow-sm">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <span class="text-gray-500 dark:text-neutral-500 sm:text-sm">$</span>
          </div>
          <input class="input pl-6 pr-12" type="text" name="networth" id="networth" v-model="party.networth" required placeholder="0.00" aria-describedby="price-currency" aria-required />
          <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <span class="text-gray-500 dark:text-neutral-400 sm:text-sm">USD</span>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row-reverse items-center justify-between mt-5">
      <button type="submit" class="self-end button_primary">Next
        <ArrowRightIcon class="w-4 h-4" />
      </button>
      <button @click="handleClick('previous')" class="self-end button_secondary">
        <ArrowLeftIcon class="w-4 h-4" />
        Previous
      </button>
    </div>
  </form>
</template>

<script setup>
// Essentials
import { computed, watch } from 'vue'
// Libraries
import { formatIncome } from '@/helper/helper'
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/vue/24/outline'

// Props
const props = defineProps({
  accountType: { type: String, required: false, default: 'individual' },
  offering: { type: Object, required: false, default: null },
  // isForOther: { type: Boolean, required: false, default: false },
  // allowAdvisors: { type: Boolean, required: false, default: false },
})

// Emits
const emit = defineEmits(['next', 'previous'])

// Computed
const contextLabel = computed(() => {
  if (props.accountType == 'entity') return "Agent's"
  return ''
});

// Models
const party = defineModel()

// Functions
const handleClick = (action) => { emit(action) }

// Watchers
watch(() => party.value.current_annual_income, (newValue) => {
  const value = formatIncome(newValue)
  party.value.current_annual_income = value;
});

watch(() => party.value.average_annual_income, (newValue) => {
  const value = formatIncome(newValue)
  party.value.average_annual_income = value;
});

watch(() => party.value.current_household_income, (newValue) => {
  const value = formatIncome(newValue)
  party.value.current_household_income = value;
});

watch(() => party.value.average_household_income, (newValue) => {
  const value = formatIncome(newValue)
  party.value.average_household_income = value;
});

watch(() => party.value.networth, (newValue) => {
  const value = formatIncome(newValue)
  party.value.networth = value;
});
</script>