<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 dark:bg-neutral-500 dark:bg-opacity-25 bg-opacity-25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-white dark:bg-neutral-950 py-6 shadow-xl">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-base font-semibold leading-6 text-gray-900 flex gap-4 items-end">
                        <UserAvatar :avatar="props.avatar_url" :user_id="party_editable.id" :allow_edit="false" :first_name="party_editable.first_name" :last_name="party_editable.last_name"
                          @failure="emit('upload-failure')" @success="emit('upload-success')" />
                        <div class="flex flex-col gap-3">
                          <div v-if="!props.partner.partners_protected.kyc_disabled">
                            <div v-if="party_editable.parties_protected.kyc == 'approved' && party_editable.parties_protected.aml == 'approved'" class="badge_green w-fit">
                              Verified
                              <CheckBadgeIcon class="ml-1 w-4 h-4" />
                            </div>
                            <div v-else-if="party_editable.parties_protected.kyc == 'disapproved' && party_editable.parties_protected.aml == 'disapproved'" class="badge_red w-fit">
                              Verification Failed
                              <XCircleIcon class="ml-1 w-4 h-4" />
                            </div>
                            <div v-else class="badge_yellow flex gap-2 w-fit">
                              Verification Pending
                              <ClockIcon class="ml-1 w-4 h-4" />
                            </div>
                          </div>

                        </div>

                      </DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" class="relative rounded-md text-gray-400 hover:text-gray-500 focus:outline-none" @click="open = false">
                          <span class="absolute -inset-2.5" />
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="relative mt-6 flex-1">
                    <!-- Your content -->
                    <form @submit.prevent="handleUpdateParty()" class="flex flex-col gap-y-4 h-full">

                      <div class="pb-6 px-4 sm:px-6">
                        <div>

                          <div class="flex items-end">
                            <div class="flex-1">
                              <div>
                                <div class="flex items-center">
                                  <h3 class="text-xl font-bold text-gray-900 dark:text-white sm:text-2xl flex items-center gap-1">{{ party_editable.first_name + ' ' + party_editable.last_name }}
                                    <InfoCircle info="Please reach out to us if you need to change your full name or your login email." />
                                  </h3>
                                </div>
                                <p class="text-sm text-gray-500 dark:text-neutral-400">{{ party_editable.contact_email }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col gap-y-4">
                        <!-- Profile Info -->
                        <dl class="space-y-4 px-4 sm:px-6">
                          <div class="">
                            <label for="email" class="block text-sm font-medium leading-6 text-gray-900 dark:text-neutral-300">Contact Email</label>
                            <div class="mt-2">
                              <input type="email" name="email" id="email" v-model="party_editable.contact_email" required class="input" placeholder="you@example.com" />
                            </div>
                          </div>
                          <div class="">
                            <label for="phone" class="block text-sm font-medium leading-6 text-gray-900 dark:text-neutral-300">Phone</label>
                            <div class="mt-2">
                              <input type="text" name="phone" id="phone" v-model="party_editable.phone" :required="true" class="input" placeholder="###-###-####" />
                            </div>
                          </div>
                          <div class="flex gap-5">
                            <div class="flex-1">
                              <label for="address" class="block text-sm font-medium leading-6 text-gray-900 dark:text-neutral-300">Address</label>
                              <div class="mt-2">
                                <input type="text" name="address" id="address" v-model="party_editable.address" required class="input" placeholder="221B Baker Street" />
                              </div>
                            </div>
                            <div class="w-12">
                              <label for="apt" class="block text-sm font-medium leading-6 text-gray-900 dark:text-neutral-300">Apt/Unit</label>
                              <div class="mt-2">
                                <input type="text" name="apt" id="apt" v-model="party_editable.unit" class="input" placeholder="B" />
                              </div>
                            </div>
                          </div>

                          <div class="flex flex-wrap gap-5">
                            <div class="flex-grow">
                              <label for="city" class="block text-sm font-medium leading-6 text-gray-900 dark:text-neutral-300">City</label>
                              <div class="mt-2">
                                <input type="text" name="city" id="city" v-model="party_editable.city" required class="input" placeholder="New York" />
                              </div>
                            </div>
                            <div class="flex-shrink">
                              <label for="state" class="block text-sm font-medium leading-6 text-gray-900 dark:text-neutral-300">State</label>
                              <div class="mt-2">
                                <input type="text" name="state" id="state" v-model="party_editable.state" required class="input" placeholder="New York" />
                              </div>
                            </div>
                          </div>

                          <div class="flex flex-wrap gap-5">
                            <div class="flex-grow">
                              <label for="zip" class="block text-sm font-medium leading-6 text-gray-900 dark:text-neutral-300">Zip</label>
                              <div class="mt-2">
                                <input type="text" name="zip" id="zip" v-model="party_editable.zip" required class="input" placeholder="New York" />
                              </div>
                            </div>
                            <div class="">
                              <label for="country" class="block text-sm font-medium leading-6 text-gray-900 dark:text-neutral-300">Country</label>
                              <div class="mt-2">
                                <input type="text" name="country" id="country" v-model="party_editable.country" required class="input" placeholder="U.S." />
                              </div>
                            </div>
                          </div>


                          <div class="">
                            <label for="dob" class="flex gap-1 items-center text-sm font-medium leading-6 text-gray-900 dark:text-neutral-300">
                              Date of Birth
                              <InfoCircle info="Please reach out to us if you need to change your date of birth" />
                            </label>
                            <div class="mt-2 text-sm font-medium text-gray-500 dark:text-neutral-400">
                              {{ formatDateNoTimeZone(party_editable.date_of_birth) }}
                            </div>
                          </div>
                        </dl>
                        <!-- Employer -->
                        <dl v-if="props.partner.collect_employer_data" class="space-y-4 px-4 sm:px-6">
                          <div v-if="party_editable.employment_status || party_editable.occupation || party_editable.employer_name || party_editable.employer_address"
                            class="p-4 rounded-lg bg-gray-100 dark:bg-neutral-900 space-y-3">
                            <div v-if="party_editable.employment_status">
                              <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 dark:text-neutral-400">Employment Status</dt>
                              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-white">{{ capitalizeFirstLetter(party_editable.employment_status) }}</dd>
                            </div>
                            <div v-if="party_editable.occupation">
                              <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 dark:text-neutral-400">Occupation</dt>
                              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-white">{{ party_editable.occupation }}</dd>
                            </div>
                            <div v-if="party_editable.employer_name">
                              <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 dark:text-neutral-400">Employer Name</dt>
                              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-white">{{ party_editable.employer_name }}</dd>
                            </div>
                            <div v-if="party_editable.employer_address">
                              <dt class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 dark:text-neutral-400">Employer Address</dt>
                              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-white">{{ party_editable.employer_address }}</dd>
                              <dd class="text-sm text-gray-900 sm:col-span-2 dark:text-white">{{ party_editable.employer_unit }}</dd>
                            </div>
                          </div>
                        </dl>
                        <!-- Income -->
                        <dl v-if="props.partner.collect_income_data" class="space-y-4 px-4 sm:px-6">
                          <div
                            v-if="party_editable.average_annual_income || party_editable.average_household_income || party_editable.current_annual_income || party_editable.current_household_income || party_editable.networth"
                            class="p-4 rounded-lg bg-gray-100 dark:bg-neutral-900 space-y-3">
                            <div v-if="party_editable.average_annual_income">
                              <dt class="text-sm font-medium text-gray-500 sm:flex-shrink-0 dark:text-neutral-400">Personal Average Annual Income</dt>
                              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-white">{{ formatMoney(party_editable.average_annual_income) }}</dd>
                            </div>
                            <div v-if="party_editable.average_household_income">
                              <dt class="text-sm font-medium text-gray-500 sm:flex-shrink-0 dark:text-neutral-400">Household Average Annual Income</dt>
                              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-white">{{ formatMoney(party_editable.average_household_income) }}</dd>
                            </div>
                            <div v-if="party_editable.current_annual_income">
                              <dt class="text-sm font-medium text-gray-500 sm:flex-shrink-0 dark:text-neutral-400">Current Personal Annual Income</dt>
                              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-white">{{ formatMoney(party_editable.current_annual_income) }}</dd>
                            </div>
                            <div v-if="party_editable.current_household_income">
                              <dt class="text-sm font-medium text-gray-500 sm:flex-shrink-0 dark:text-neutral-400">Current Average Annual Income</dt>
                              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-white">{{ formatMoney(party_editable.current_household_income) }}</dd>
                            </div>
                            <div v-if="party_editable.networth">
                              <dt class="text-sm font-medium text-gray-500 sm:flex-shrink-0 dark:text-neutral-400">Networth</dt>
                              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 dark:text-white">{{ formatMoney(party_editable.networth) }}</dd>
                            </div>
                          </div>
                        </dl>
                      </div>

                      <!-- Save & Update -->
                      <div class="h-full flex-1 flex justify-end items-end px-4 sm:px-6">
                        <div class="flex gap-5 items-center">
                          <div @click="open = false" class="button_secondary">Cancel</div>
                          <button type="submit" class="button_primary">
                            Save & Update
                            <SpinnerButton v-if="updating" />
                          </button>
                        </div>
                      </div>
                    </form>
                    <!-- Your content -->
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// Essentials
import { ref, watch, onMounted } from 'vue'
import { usePartiesStore } from '@/stores/parties';
import { useEmailsStore } from '@/stores/emails';
// Components
import InfoCircle from '@/components/ui/InfoCircle.vue'
import UserAvatar from '@/components/ui/UserAvatar.vue'
import SpinnerButton from '@/components/loading/SpinnerButton.vue';
// Libraries
import { formatDateNoTimeZone, formatMoney, capitalizeFirstLetter } from '@/helper/helper';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon, CheckBadgeIcon, ClockIcon, XCircleIcon } from '@heroicons/vue/24/outline'
// Props
const props = defineProps({
  show: { type: Boolean, required: true, default: false },
  party: { type: Object, required: true, default: null }, //This should be a party object (parties table and parties_protected)
  avatar_url: { type: String, required: false, default: null },
  partner: { type: Object, required: true, default: null }
})
// Emits
const emit = defineEmits(['closed', 'error', 'upload-failure', 'upload-success', 'success'])
// Stores
const partiesStore = usePartiesStore()
const emailsStore = useEmailsStore()
// Globals
const open = ref(false)
const updating = ref(false)
const party_editable = ref(JSON.parse(JSON.stringify(props.party))) //This is important to make a deep copy of the object
const oldEmail = ref(props.party.contact_email)

// Mounted
onMounted(async () => {
  open.value = props.show
})

// Functions
async function handleUpdateParty() {
  // Add updating boolean and spinner
  updating.value = true
  // Get just the party object
  const { parties_protected, ...party } = party_editable.value;
  // Update changes in TAPI
  partiesStore.updateTapiParty(party)
  // Update changes in Supabase
  const successful = await partiesStore.updatePartySupabase(party)
  if (!successful) { emit('error'); return }  
  // Send email out about the changes in personal information
  emailsStore.sendPartyInfoUpdatedNotification(party_editable.value.contact_email)
  if (oldEmail.value !== party_editable.value.contact_email) emailsStore.sendPartyInfoUpdatedNotification(oldEmail.value)
  // Emit success
  emit('success')
  // Close the slideover
  open.value = false
  updating.value = false
}

// Watchers
watch(open, async (value) => {
  if (!value) {
    await new Promise(resolve => setTimeout(resolve, 400))
    emit('closed')
  }
})
// TODO add watchers to format and verifiy fields
</script>
