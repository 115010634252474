<template>
  <div> <!-- Leave this outer div empty -->
    <div class="flex flex-col w-fit max-w-[1000px]">
      <img class="w-full" :src="image" />
      <div class="flex flex-wrap items-end justify-between mt-10 gap-y-5">
        <div class="text-3xl font-bold">How do Government Money Market Funds compare to Savings Accounts?</div>
        <div class="text-sm">by: North Capital</div>
      </div>

      <div class="flex flex-col gap-5 my-10 w-full max-w-[1000px]">
        <!-- Paragraph 1 -->
        <div>
          Investors have many choices when deciding where to park their short-term cash. Two preferred locations are government money market funds and bank savings accounts. Both of these options
          provide a relatively safe place to maintain cash balances, but there are some important differences that investors should be aware of. We will look at both government money market mutual funds
          and bank savings accounts to help you evaluate whether either is right for you.
          <br><br>
          Money market funds generally offer a higher rate of returns than bank savings accounts. Government money market funds invest in short-term debt securities issued by the U.S. Government and
          certain federal agencies backed by the full faith and credit of the U.S. Treasury, which can mean less credit risk compared to corporate debt securities. Because of the lower risk and the
          short duration of these underlying securities, government money market funds are considered to be a low risk investment option compared to corporate money market funds, bond funds, or equity
          mutual funds. Most government money market funds offer next day (T+1) subscriptions and redemptions, with some even providing same-day settlement. This means that you can withdraw your money
          quickly, and without penalty. In contrast, some bank savings accounts have withdrawal limits and penalties for withdrawing money too frequently.
          <br><br>
          Although government money market funds can offer a relatively secure investment option for short-term cash, it is important to note that investments in these funds are not insured or
          guaranteed by the Federal Deposit Insurance Corporation (FDIC) or any other government agency. This is where traditional bank savings accounts have an advantage, as they are covered by FDIC
          insurance up to $250,000 per account holder. A potential downside of these types of accounts is that they typically pay less interest than a money market fund.
          <br><br>
          Many investors fail to take advantage of the potential benefits of government money market funds, because it can seem like a lot of work to set up and fund an account. However, setting up and
          funding an account online is a streamlined process that can be completed fairly quickly and easily. If you are interested in looking into a government money market fund, our advisors can
          answer any questions.
          <br><br>
          Keep in mind that there are risks to investing in a government money market mutual fund. Before investing, investors should carefully consider the fund’s investment objectives, risks, charges
          and expenses.
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// import { supabase } from '@/lib/supabaseClient'
export default {
  data() {
    return {
      image: 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/moneyMarketAndSavings/moneyMarketAndSavingsWater'
    }
  }
}
</script>