<template>
  <div class="max-w-3xl p-5">
    <div>
      <RadioHorizontal v-model="hasTrustedContact" :items="[{ label: 'Yes', value: true }, { label: 'No', value: false }]" label="Would you like to provide a trusted contact person?"
        subLabel="We strongly encourage you to give serious consideration to the importance of naming a Trusted Contact Person (“Trusted Contact”). For more information on the benefits of naming a Trusted Contact and what that person can and cannot do, please refer to the Trusted Contact Person Fact Sheet attached to the Account Documents. " />
    </div>

    <div v-if="!hasTrustedContact" class="flex flex-row-reverse items-center justify-between mt-5">
      <button type="button" @click="handleClick('next')" class="self-end button_primary">Next
        <ArrowRightIcon class="w-4 h-4" />
      </button>
    </div>

    <form v-else @submit.prevent="handleSubmit()" class="flex flex-col gap-5 mt-5">
      <div class="flex flex-col gap-5 md:items-center md:flex-row">
        <!-- First Name -->
        <div class="grow">
          <label for="firstName" class="input_label">First Name</label>
          <div class="input_wrapper">
            <input v-model="trusted_contact.trusted_contact_first_name" type="text" name="firstName" id="firstName" class="input" placeholder="John" aria-required required />
          </div>
        </div>
        <!-- Middle Initial -->
        <div class="w-24">
          <label for="initial" class="input_label">Middle Initial</label>
          <div class="input_wrapper">
            <input v-model="trusted_contact.trusted_contact_initial" type="text" name="initial" id="initial" class="input" placeholder="J" />
          </div>
        </div>
        <!-- Last Name -->
        <div class="grow">
          <label for="lastName" class="input_label">Last Name</label>
          <div class="input_wrapper">
            <input v-model="trusted_contact.trusted_contact_last_name" type="text" name="lastName" id="lastName" class="input" placeholder="Smith" aria-required required />
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-5 md:items-center md:flex-row">
        <!-- Email -->
        <div class="grow">
          <label for="contactEmail" class="input_label">Contact Email</label>
          <div class="input_wrapper">
            <input v-model="trusted_contact.trusted_contact_email" type="email" autocomplete="email" name="contactEmail" id="contactEmail" class="input" placeholder="you@example.com" aria-required
              required />
          </div>
        </div>
        <!-- Phone -->
        <div class="grow">
          <label for="phone" class="input_label">Phone</label>
          <div class="input_wrapper">
            <input v-model="trusted_contact.trusted_contact_phone" type="tel" autocomplete="tel" name="phone" id="phone" class="input" placeholder="###-###-####" aria-required required />
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-5 md:items-center md:flex-row">
        <!-- Address -->
        <div class="grow">
          <label for="address" class="input_label">Address</label>
          <div class="input_wrapper">
            <input v-model="trusted_contact.trusted_contact_address" type="text" name="address" id="address" class="input" placeholder="221 Baker St." aria-required required />
          </div>
        </div>
        <!-- Apt/Unit -->
        <div class="w-24">
          <label for="apt" class="input_label">Apt/Unit</label>
          <div class="input_wrapper">
            <input v-model="trusted_contact.trusted_contact_unit" type="text" name="apt" id="apt" class="input" placeholder="B" />
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-5 md:items-center md:flex-row">
        <!-- City -->
        <div class="">
          <label for="city" class="input_label">City</label>
          <div class="input_wrapper">
            <input v-model="trusted_contact.trusted_contact_city" type="text" name="city" id="city" class="input" placeholder="Manhattan" aria-required required />
          </div>
        </div>
        <!-- State -->
        <div class="w-40">
          <label for="state" class="input_label">State</label>
          <div class="input_wrapper">
            <SelectMenu v-model="trusted_contact.trusted_contact_state" :items="states" />
          </div>
        </div>
        <!-- Country -->
        <div class="grow">
          <label for="country" class="input_label">Country</label>
          <div class="input_wrapper">
            <input v-model="trusted_contact.trusted_contact_country" type="text" name="country" id="country" class="input" placeholder="U.S." aria-required required />
          </div>
        </div>
        <!-- Zip -->
        <div class="max-w-24">
          <label for="zip" class="input_label">Zip</label>
          <div class="input_wrapper">
            <input v-model="trusted_contact.trusted_contact_zip" type="text" name="zip" id="zip" class="input" placeholder="10119" aria-required required />
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-5 md:items-center md:flex-row">
        <!-- Relationship -->
        <div class="grow">
          <label for="relationship" class="input_label">Relationship to Account Holder</label>
          <div class="input_wrapper">
            <input v-model="trusted_contact.trusted_contact_relationship" type="text" name="relationship" id="relationship" class="input" placeholder="Spouse" aria-required required />
          </div>
        </div>
      </div>

      <p class="text-sm leading-6 text-gray-600 dark:text-neutral-400">
        By choosing to provide information about a Trusted Contact, you authorize us to contact the trusted contact person listed below and disclose information about your account to that person in
        the following circumstances: to address possible financial exploitation, to confirm the specifics of your current contact information, health status, or the identity of any legal guardian,
        executor, trustee or holder of a power of attorney, or as otherwise permitted by FINRA Rule 2165 (Financial Exploitation of Specified Adults).
      </p>

      <div class="flex flex-row-reverse items-center justify-between mt-5">
        <button type="submit" class="self-end button_primary">Next
          <ArrowRightIcon class="w-4 h-4" />
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
// Essentials
import { ref, watch } from 'vue'
// Store
import { useAccountsStore } from '@/stores/accounts'
const accountsStore = useAccountsStore()
// Libraries
import { ArrowRightIcon } from '@heroicons/vue/24/outline'
import { states } from '@/helper/helper'
// Components
import SelectMenu from '@/components/applicationui/SelectMenu.vue'
import RadioHorizontal from '@/components/applicationui/RadioHorizontal.vue'

// Props
const props = defineProps({
  account_id: { type: String, required: true },
})

// Emits
const emit = defineEmits(['next', 'previous'])

// Computed

// Trusted Contact
const trusted_contact = defineModel()
const hasTrustedContact = ref(false)

// Methods
async function handleSubmit() {
  accountsStore.updateAccountTrustedContact(props.account_id, trusted_contact.value)
  emit('next')
}

const handleClick = (emitted) => { emit(emitted) }

// Watchers
watch(() => trusted_contact.value.phone, (newValue) => {
  // Remove non-digit characters
  const value = newValue.replace(/\D/g, '');

  // Determine if there's a country code (assume up to 2 digits)
  let countryCode = '';
  let phoneNumber = value.slice(0, 12); // Ensure max length is 12 digits

  if (phoneNumber.length > 10) {
    countryCode = `+${phoneNumber.slice(0, phoneNumber.length - 10)}`;
    phoneNumber = phoneNumber.slice(phoneNumber.length - 10);
  }

  // Format based on length of phoneNumber
  if (phoneNumber.length <= 3) {
    trusted_contact.value.phone = `${countryCode}${phoneNumber}`;
  } else if (phoneNumber.length <= 6) {
    trusted_contact.value.phone = `${countryCode} (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  } else {
    trusted_contact.value.phone = `${countryCode} (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }
});

watch(() => trusted_contact.value.country, (newValue) => {
  const trimmedValue = newValue.trim().toUpperCase(); // Trim and convert to uppercase

  // Format based on common country codes
  switch (trimmedValue) {
    case 'US':
    case 'us':
    case 'USA':
    case 'UNITED STATES':
    case 'AMERICA':
      trusted_contact.value.country = 'U.S.A';
      break;
    case 'CA':
    case 'CAN':
    case 'CANADA':
      trusted_contact.value.country = 'Canada';
      break;
    case 'UK':
    case 'GB':
    case 'UNITED KINGDOM':
      trusted_contact.value.country = 'UK';
      break;
    // Add more cases for other countries as needed
    default:
      // Do nothing or handle other cases here
      break;
  }
});
</script>