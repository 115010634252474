<template>
  <div>
    <!-- Popups -->
    <NotificationPopup :title="notificationTitle" :text="notificationText" ref="notification" :type="notificationType" />

    <PlaidForm v-if="showPlaidForm" :show="showPlaidForm" :update="hasExternalAccounts" :account_id="props.account.id" :tapi_account_id="props.account.tapi_account_id" @closed=" showPlaidForm=false"
      @submitted="getAllExternalAccounts(props.account.id, props.account.tapi_account_id); notify('success', 'Successfully Connected', 'Your external account has been succesfully connected.')"
      @existing="notify('failure', 'Existing External Account', 'Looks like you already have an external account. At the moment only one external account can be connected at a time. Please delete you current external account before adding a new one.')"
      @failure="notify('failure', 'Sorry', 'Something strange happened, if the issue persists please contact tech support.')" />

    <WithdrawalView v-if="showWithdrawalPopup" :show="showWithdrawalPopup" :account="props.account" :exAccount="externalAccountArr[0].statusDesc" @closed="showWithdrawalPopup = false;"
      @success="notify('success', 'Successful Withdrawal', 'Your withdrawal is being processed.')"
      @failure="notify('failure', 'Sorry', 'Something strange happened, if the issue persists please contact tech support.')"
      @overLimit="notify('failure', 'Sorry', 'You tried to withdraw more then the available cash in your account.')"
      @zero="notify('failure', 'Cannot Withdraw $0.00', 'Looks like you tried to transfer $0.00, please put in a non-zero amount that is less than your total available cash.')" />

    <DepositView v-if="showDepositPopup" :show="showDepositPopup" :account="props.account" :exAccount="externalAccountArr[0].statusDesc" @closed="showDepositPopup = false;"
      @success="notify('success', 'Successful Deposit', 'Your deposit is being processed.')"
      @failure="notify('failure', 'Sorry', 'Something strange happened, if the issue persists please contact tech support.')"
      @notapproved="notify('failure', 'Not Approved', 'It looks like your account has not been approved by a principal yet. This make take up to 3 buisness days after an account is created. If the issue persists please contact tech support.')"
      @zero="notify('failure', 'Cannot Depposit $0.00', 'Looks like you tried to depost $0.00, please put in a non-zero amount.')" />

    <WireCheckInstructions v-model="showWireCheckInstructions" :type="instructionType" />
    <!-- Popups -->

    <!-- Loading -->
    <div v-if="loading" class="w-full md:h-[70vh] h-[50vh] flex items-center justify-center">
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Ready -->
    <div v-else>
      <!-- Empty State -->
      <div v-if="!hasExternalAccounts">
        <div class="text-center flex flex-col justify-center">
          <svg class="w-12 h-12 mx-auto text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
          </svg>
          <h3 class="mt-2 text-sm font-semibold dark:text-neutral-300">No linked External Accounts</h3>
          <p class="mt-1 text-sm text-gray-500">Get started by linking your External Account.</p>
          <div class="mt-6 flex flex-col gap-6 items-center">
            <button type="button" @click="showPlaidForm = true;" class="button_primary">
              <LinkIcon class="h-5 w-5" aria-hidden="true" />
              Link External Account
            </button>

            <button type="button" @click="showWireCheckInstructions = true; instructionType = 'wire'" class="button_secondary">
              <BuildingLibraryIcon class="h-5 w-5" aria-hidden="true" />
              Wire Instructions
            </button>

            <button type="button" @click="showWireCheckInstructions = true; instructionType = 'check'" class="button_secondary">
              <EnvelopeOpenIcon class="h-5 w-5" aria-hidden="true" />
              Check Instructions
            </button>
          </div>
        </div>
      </div>

      <!-- Accounts Section -->
      <div v-else>
        <!-- Header -->
        <div class="flex items-center flex-wrap gap-10">
          <div class="sm:flex-auto">
            <h1 class="text-base font-semibold leading-6 text-gray-900 dark:text-neutral-300">External Accounts</h1>
          </div>
          <div class="flex items-center gap-5">
            <button type="button" @click="showWireCheckInstructions = true; instructionType = 'wire'" class="button_secondary">
              <BuildingLibraryIcon class="h-5 w-5" aria-hidden="true" />
              Wire Instructions
            </button>

            <button type="button" @click="showWireCheckInstructions = true; instructionType = 'check'" class="button_secondary">
              <EnvelopeOpenIcon class="h-5 w-5" aria-hidden="true" />
              Check Instructions
            </button>

            <button @click="showPlaidForm = true;" type="button" class="button_primary">
              Edit External Account
              <PencilSquareIcon class="size-4" />
            </button>
          </div>
        </div>
        <!-- Accounts Table -->
        <div class="flow-root mt-4">
          <div>
            <div class="inline-block w-full align-middle ">
              <div class="overflow-x-scroll rounded-lg shadow sm:overflow-x-hidden ring-1 ring-black ring-opacity-5">
                <table class="min-w-full divide-y divide-gray-300 dark:divide-neutral-600">
                  <thead class="bg-gray-50 dark:bg-neutral-800">
                    <tr>
                      <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 dark:text-neutral-300">Logo</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-neutral-300">Name</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-neutral-300">Nick Name</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-neutral-300">Routing #</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-neutral-300">Account #</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-neutral-300">Deposit</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-neutral-300">Withdraw</th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200 dark:divide-neutral-600 dark:bg-neutral-700">
                    <tr v-for="xa in externalAccountArr" :key="xa.accountId">
                      <td class="py-4 pl-4 text-sm text-gray-900 whitespace-nowrap">
                        <div class="h-5 bg-no-repeat bg-[center_center] bg-contain" :class="bankLogo"></div>
                      </td>
                      <td class="px-3 py-4 text-sm text-gray-500 dark:text-neutral-300 whitespace-nowrap">{{ xa.statusDesc.bankName }}</td>
                      <td class="px-3 py-4 text-sm text-gray-500 dark:text-neutral-300 whitespace-nowrap">{{ xa.statusDesc.AccountNickName }}</td>
                      <td class="px-3 py-4 text-sm text-gray-500 dark:text-neutral-300 whitespace-nowrap">{{ xa.statusDesc.AccountRoutingNumber }}</td>
                      <td class="px-3 py-4 text-sm text-gray-500 dark:text-neutral-300 whitespace-nowrap">*******{{ xa.statusDesc.AccountNumber }}</td>
                      <td class="px-3 py-4 text-sm text-gray-500 dark:text-neutral-300 whitespace-nowrap">
                        <div @click="showDepositPopup = true" class="button_primary">Deposit</div>
                      </td>
                      <td class="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        <div @click="showWithdrawalPopup = true" class="button_primary">Withdraw</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from 'vue'
import { useAccountsStore } from '@/stores/accounts'
// Components
import PlaidForm from '@/components/popups/PlaidForm.vue'
import SpinnerFlip from '@/components/loading/SpinnerFlip.vue'
import WithdrawalView from '@/components/popups/WithdrawalView.vue'
import DepositView from '@/components/popups/DepositView.vue'
import NotificationPopup from '@/components/popups/NotificationPopup.vue'
import WireCheckInstructions from '@/components/popups/WireCheckInstructions.vue'

// Libraries
// import { PlusIcon } from '@heroicons/vue/20/solid'
import { PencilSquareIcon, EnvelopeOpenIcon, BuildingLibraryIcon, LinkIcon } from '@heroicons/vue/24/outline'
// Props
const props = defineProps({
  account: {
    type: Object,
    required: true,
    default: null
  }
})
// Stores
const accountsStore = useAccountsStore()
// Globals
const externalAccountArr = ref([])
const hasExternalAccounts = ref(false)
const loading = ref(true)
const bankLogo = ref('default')
const showWithdrawalPopup = ref(false)
const showDepositPopup = ref(false)
const showPlaidForm = ref(false)
const showWireCheckInstructions = ref(false)
const instructionType = ref('wire')
const notification = ref(null)
const notificationType = ref('success')
const notificationTitle = ref('Success')
const notificationText = ref('')
// Mounted
onMounted(async () => {
  await getAllExternalAccounts(props.account.id, props.account.tapi_account_id)
})
// Functions
async function getAllExternalAccounts(account_id, tapi_account_id) {
  loading.value = true

  // Get External Account
  const data = await accountsStore.getAchAccount(account_id, tapi_account_id)
  if (!data || data.statusCode == 149 || data.statusCode == '149') { loading.value = false; return }

  // Set External Account
  externalAccountArr.value = [] // clear array for now (Since TAPI does not support multiple external accounts yet)
  externalAccountArr.value.push(data)

  // set correct bank logo
  if (data.statusDesc.bankName.toLowerCase().includes('fidelity')) bankLogo.value = 'fidelity'
  else if (data.statusDesc.bankName.toLowerCase().includes('schwab')) bankLogo.value = 'schwab'
  else if (data.statusDesc.bankName.toLowerCase().includes('bank of america')) bankLogo.value = 'boa'
  else if (data.statusDesc.bankName.toLowerCase().includes('chase')) bankLogo.value = 'chase'
  else if (data.statusDesc.bankName.toLowerCase().includes('wells fargo')) bankLogo.value = 'wellsfargo'
  else if (data.statusDesc.bankName.toLowerCase().includes('citibank')) bankLogo.value = 'citi'
  else if (data.statusDesc.bankName.toLowerCase().includes('goldman sachs')) bankLogo.value = 'gs'
  else if (data.statusDesc.bankName.toLowerCase().includes('truist')) bankLogo.value = 'truist'
  else if (data.statusDesc.bankName.toLowerCase().includes('citizens bank')) bankLogo.value = 'cf'
  else bankLogo.value = 'default'

  // set has external accounts
  hasExternalAccounts.value = true
  loading.value = false
}

const notify = (type, title, text) => {
  notificationType.value = type
  notificationTitle.value = title
  notificationText.value = text
  notification.value.show()
}
</script>

<style scoped>
.default {
  background-image: url('@/assets/icons/bank.svg');
}

.chase {
  background-image: url('@/assets/icons/chase.png');
}

.wellsfargo {
  background-image: url('@/assets/icons/wellsfargo.svg');
}

.boa {
  background-image: url('@/assets/icons/boa.png');
}

.citi {
  background-image: url('@/assets/icons/citi.png');
}

.gs {
  background-image: url('@/assets/icons/gs.png');
}

.truist {
  background-image: url('@/assets/icons/truist.png');
}

.schwab {
  background-image: url('@/assets/icons/schwab.png');
}

.fidelity {
  background-image: url('@/assets/icons/fidelity.png');
}

.cf {
  background-image: url('@/assets/icons/cf.png');
}
</style>