<template>
  <div> <!-- Leave this outer div empty -->
    <div class="flex flex-col w-fit  max-w-[1000px]">
      <img class="w-full" :src="image" />
      <div class="flex flex-wrap items-end justify-between mt-10 gap-y-5">
        <div class="text-3xl font-bold">The SIPC and Its Importance in Financial Markets</div>
        <div class="text-sm">by: Grant Nelson</div>
      </div>
      
      <div class="flex flex-col gap-5 my-10 w-full max-w-[1000px]">
        <!-- Paragraph 1 -->
        <div>
          Investors have enough to worry about. Investing is risky and investments can lose value for seemingly insignificant reasons. 
          The Securities Investor Protection Corporation (“SIPC”) was created because investors shouldn’t have to worry about losing all their assets if a brokerage firm becomes insolvent. 
          <br><br>
          SIPC, a self-regulatory organization that operates under the oversight of the Securities and Exchange Commission (“SEC”), 
          was created in 1970 as a response to the collapse of brokerage firms brought on by the <a class="text-blue-500">paperwork crunch</a>. 
          Its primary purpose is to maintain confidence in the securities industry and protect investors in case a brokerage firm fails.
        </div>
        <!-- Paragraph 2 -->
        <div class="text-xl font-semibold">How Does SIPC Work?</div>
        <div>
          SIPC provides limited protection to customers of member brokerage firms in the event of a broker-dealer's failure or insolvency. 
          Each customer account at a SIPC member firm is protected up to $500,000, which includes a maximum of $250,000 in cash holdings. 
          It is important to note that SIPC coverage is not the same as the Federal Deposit Insurance Corporation (“FDIC”) coverage, which is designed to protect bank deposits. 
          SIPC protection focuses specifically on securities and cash held in brokerage accounts.
        </div>
        <!-- Paragraph 3 -->
        <div class="text-xl font-semibold">What Does SIPC Cover?</div>
        <div>
          Brokerage Firm Insolvency: SIPC steps in when a SIPC member brokerage firm faces financial troubles or goes bankrupt. 
          In such cases, SIPC works to return securities and cash to customers, ensuring they do not lose their investments due to the broker-dealer's insolvency.
          <br><br>
          Misappropriation or Theft: SIPC covers the loss of securities and cash due to the fraudulent activities of a broker-dealer. 
          This protection provides a safety net for investors whose assets have been misused or stolen by unscrupulous individuals.
          <br><br>
          Unauthorized Trading: If a brokerage firm executes unauthorized trades in a customer's account, resulting in losses, SIPC can provide compensation to the investor.
        </div>
        <!-- Paragraph 4 -->
        <div class="text-xl font-semibold">What Doesn't SIPC Cover?</div>
        <div>
          Market Fluctuations: SIPC does not cover losses stemming from market fluctuations or investment decisions that result in a decline in the value of an investor's portfolio.
          <br><br>
          Bad Investment Advice: SIPC does not protect against losses due to poor investment advice from a broker or financial advisor.
          <br><br>
          Commodities and Futures: SIPC only covers securities and cash held in brokerage accounts and does not extend to commodities and futures contracts.
        </div>
        <!-- Conclusion -->
        <div class="text-xl font-semibold">The Importance of SIPC in Financial Markets</div>
        <!-- Paragraph 5 -->
        <div>
          SIPC plays a vital role in maintaining investor confidence and stability in the financial markets.
          By ensuring that customers' securities and cash are segregated and protected, SIPC contributes to the overall integrity of the financial markets. 
          By mitigating the impact of broker-dealer failures, SIPC helps prevent potential contagion effects that could spread across the broader financial markets. 
          Its existence fosters trust, encourages investment, and contributes to the overall stability and integrity of the financial system. While SIPC coverage has its limitations, it remains a critical safeguard for investors.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { supabase } from '@/lib/supabaseClient'
export default {
  data() {
    return {
      image: 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/sipc/buildings'
    }
  }
}
</script>