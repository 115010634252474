<template>
  <div> <!-- Leave this outer div empty -->
    <div class="flex flex-col w-fit max-w-[1000px]">
      <img class="w-full" :src="image" />
      <div class="flex flex-wrap items-end justify-between mt-10 gap-y-5">
        <div class="text-3xl font-bold">Understanding Rule 506 of Regulation D</div>
        <div class="text-sm">by: Grant Nelson</div>
      </div>

      <div class="flex flex-col gap-5 my-10 w-full max-w-[1000px]">
        <!-- Paragraph 1 -->
        <div>
          Regulation D is an exemption provided by the U.S. Securities and Exchange Commission (“SEC”) that allows companies to raise capital through private offerings without undergoing the costly and
          rigorous process of registering with the SEC. Rule 506 is a specific provision under Regulation D that provides a "safe harbor" for private placements of securities, allowing companies to
          raise unlimited capital without the need for public registration.
          <br>
          Rule 506 offers two distinct exemption categories: Rule 506(b) and Rule 506(c).
        </div>
        <!-- Paragraph 2 -->
        <div class="text-xl font-semibold">Rule 506(b)</div>
        <div>
          Under this provision, companies can raise an unlimited amount of capital from accredited investors (high-net-worth individuals or institutions) and up to 35 non-accredited investors but must
          have a preexisting substantive relationship with the investors it solicits. Issuers are permitted to solicit and market their securities to accredited investors but are prohibited from using
          any form of general solicitation or advertising to the masses. They must also provide potential non-accredited investors with detailed financial information and other disclosures.
        </div>
        <!-- Paragraph 3 -->
        <div class="text-xl font-semibold">Rule 506(c)</div>
        <div>
          This subsection of Rule 506 permits companies to conduct general solicitations and advertising to raise capital but restricts investment to accredited investors only. Issuers must take
          reasonable steps to verify the accredited status of investors, ensuring compliance with the SEC's guidelines. The advantage of Rule 506(c) lies in the ability to access a broader pool of
          potential investors and promote fundraising through various channels, including social media and online platforms
        </div>
        <!-- Paragraph 4 -->
        <div>
          Rule 506 of Regulation D, as amended by the JOBS Act, has transformed the landscape of capital formation in private markets. By easing restrictions, this provision has empowered startups and
          small businesses to raise capital efficiently and this access to capital is one of the reasons why companies are opting to stay private longer.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { supabase } from '@/lib/supabaseClient'
export default {
  data() {
    return {
      image: 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/regD/suit'
    }
  }
}
</script>