<template>
  <div :class="[(props?.selectedFundingMethod?.id != 'ach') ? 'p-5' : '']">
    <!-- Loading -->
    <div v-if="loading" class="flex flex-col items-center justify-center gap-5 p-5 sm:w-[500px] sm:h-[300px] h-[50vh] w-[90vw]">
      <div class="h-20 w-20">
        <SpinnerBounce />
      </div>
    </div>

    <!-- Content -->
    <div v-else class="dark:text-neutral-300">
      <!-- Fund via External Acccount -->
      <div v-if="props.selectedFundingMethod.id == 'ach'" class="w-96 h-[635px] -mt-2.5 -mx-3">
        <iframe class="w-full h-full" :src="plaidUrl" frameBorder="0"></iframe>
      </div>

      <!-- Fund via Check -->
      <div v-else-if="props.selectedFundingMethod.id == 'check'" class="flex flex-col justify-center h-fit w-full sm:w-[500px]">
        <div class="primary_header mb-4">Check instructions</div>
        <div class="relative flex flex-col gap-5 flex-wrap p-5 bg-white dark:bg-neutral-900 rounded-lg shadow-opera dark:shadow-none dark:ring-1 dark:ring-neutral-800">
          <!-- Copy Button -->
          <div class="absolute w-full flex justify-end items-start top-1 right-1">
            <div class="button_secondary" @click="copyToClipboard(custody_check_instructions)">
              Copy
              <CheckIcon v-if="copied" class="w-4 h-4 text-green-600" />
              <ClipboardIcon v-else class="w-4 h-4" />
            </div>
          </div>

          <!-- Fedex or UPS instructions -->
          <div>
            <dt class="text-sm font-medium text_color_primary">Fedex or UPS instructions</dt>
            <dd class="mt-1 text-sm text_color_tertiary">{{ props.offering?.custody_fedex_instructions }}</dd>
          </div>

          <!-- Regular mail instructions -->
          <div>
            <dt class="text-sm font-medium text_color_primary">Regular mail instructions</dt>
            <dd class="mt-1 text-sm text_color_tertiary">{{ props.offering?.custody_mail_instructions }}</dd>
          </div>
        </div>

        <div class="flex flex-row-reverse items-center justify-between mt-5">
          <button @click="createDeposit(props.account?.id, props.account?.tapi_account_id, props.trade.investmentTotal, props.selectedFundingMethod.id)" class="self-end button_primary">Next
            <ArrowRightIcon class="w-4 h-4" />
          </button>
          <button @click="handleClick('previous')" class="self-end button_secondary">
            <ArrowLeftIcon class="w-4 h-4" />
            Previous
          </button>
        </div>
      </div>

      <!-- Fund via Wire -->
      <div v-else-if="props.selectedFundingMethod.id == 'wire'" class="flex flex-col justify-center h-fit w-full sm:w-[500px]">
        <div class="primary_header mb-4">Wire instructions</div>
        <div class="relative flex flex-col gap-5 flex-wrap p-5 bg-white dark:bg-neutral-900 rounded-lg shadow-opera dark:shadow-none dark:ring-1 dark:ring-neutral-800">
          <!-- Copy Button -->
          <div class="absolute w-full flex justify-end items-start top-1 right-1">
            <div class="button_secondary" @click="copyToClipboard(custody_wire_instructions)">
              Copy
              <CheckIcon v-if="copied" class="w-4 h-4 text-green-600" />
              <ClipboardIcon v-else class="w-4 h-4" />
            </div>
          </div>

          <div class="flex gap-10 items-center flex-wrap">
            <!-- Account Number -->
            <div>
              <dt class="text-sm font-medium text_color_primary">Account Number</dt>
              <dd class="mt-1 text-sm text_color_tertiary">{{ props.offering?.custody_account_number_instructions }}</dd>
            </div>

            <!-- Routing Number -->
            <div>
              <dt class="text-sm font-medium text_color_primary">Routing Number</dt>
              <dd class="mt-1 text-sm text_color_tertiary">{{ props.offering?.custody_routing_number_instructions }}</dd>
            </div>
          </div>

          <div class="flex gap-10 items-center flex-wrap">
            <!-- Receiving Bank -->
            <div>
              <dt class="text-sm font-medium text_color_primary">Receiving Bank</dt>
              <dd class="mt-1 text-sm text_color_tertiary">{{ props.offering?.custody_bank_instructions }}</dd>
            </div>

            <!-- Reference -->
            <div>
              <dt class="text-sm font-medium text_color_primary">Reference</dt>
              <dd class="mt-1 text-sm text_color_tertiary">{{ props.offering?.custody_reference_instructions }}</dd>
            </div>
          </div>

          <!-- Address -->
          <div>
            <dt class="text-sm font-medium text_color_primary">Address</dt>
            <dd class="mt-1 text-sm text_color_tertiary">{{ props.offering?.custody_address_instructions }}</dd>
          </div>
        </div>

        <div class="flex flex-row-reverse items-center justify-between w-full mt-5">
          <button @click="createDeposit(props.account?.id, props.account?.tapi_account_id, props.trade.investmentTotal, props.selectedFundingMethod.id)" class="self-end button_primary">Next
            <ArrowRightIcon class="w-4 h-4" />
          </button>
          <button @click="handleClick('previous')" class="self-end button_secondary">
            <ArrowLeftIcon class="w-4 h-4" />
            Previous
          </button>
        </div>

      </div>
    </div>
  </div>

</template>

<script setup>
// Essentials
import { ref, onMounted } from 'vue'

// Stores
import { useAccountsStore } from '@/stores/accounts';
import { useTransactionsStore } from '@/stores/transactions';
import { useEmailsStore } from '@/stores/emails';
import { usePartnerStore } from '@/stores/partner';
const partnerStore = usePartnerStore();
const transactionsStore = useTransactionsStore();
const emailsStore = useEmailsStore();
const accountsStore = useAccountsStore();

// Components
import SpinnerBounce from '@/components/loading/SpinnerBounce.vue'

// Libraries
import { ArrowRightIcon, ArrowLeftIcon, ClipboardIcon, CheckIcon } from '@heroicons/vue/24/outline'

// Props
const props = defineProps({
  selectedFundingMethod: { type: Object, required: false, default: null },
  trade: { type: Object, required: true, default: null },
  offering: { type: Object, required: true, default: null },
  account: { type: Object, required: false, default: null },
})

// Emits
const emit = defineEmits(['next', 'previous'])

// Globals
const loading = ref(true)
const copied = ref(false)
const custody_check_instructions = ref(`Fedex or UPS instructions: ${props.offering?.custody_fedex_instructions ?? ''}\nRegular mail instructions: ${props.offering?.custody_mail_instructions ?? ''}`)
const custody_wire_instructions = ref(`Account Number: ${props.offering?.custody_account_number_instructions ?? ''}\nRouting Number: ${props.offering?.custody_routing_number_instructions ?? ''}\nBank: ${props.offering?.custody_bank_instructions ?? ''}\nReference: ${props.offering?.custody_reference_instructions ?? ''}\nAddress: ${props.offering?.custody_address_instructions ?? ''}`)

// Mounted
onMounted(async () => {
  if (!props.selectedFundingMethod) { emit('next'); return }
  if (props.selectedFundingMethod.id == 'ach') await getPlaid(props.account?.id, props.account?.tapi_account_id)
  loading.value = false
})

// Functions
const handleClick = (action) => { emit(action) }

const copyToClipboard = async (text) => {
  copied.value = true;
  navigator.clipboard.writeText(text);
  await new Promise(resolve => setTimeout(resolve, 1000));
  copied.value = false;
  copied.value = false;
};

// Plaid
const plaidUrl = ref('')
async function getPlaid(account_id, tapi_account_id) {
  // Get plaid iframe url
  const data = await accountsStore.getPlaid(account_id, tapi_account_id)
  if (!data) { emit('previous'); return }
  // User has already linked an account we can move on
  if (data.statusCode == '715') { emit('next'); return }
  // Change page to plaid
  plaidUrl.value = data.accountDetails;
  window.addEventListener("message", (event) => {
    if (!event.origin.includes('norcapsecurities.com')) return
    if (!event) return
    if (!event.data) return
    if (!event.type) return
    if (event.type != 'message') return
    if (typeof event.data != 'string') return
    if (event.data == 'signing_complete') return // (This is needed since I never remove this event listener in some cases)
    let response = JSON.parse(event.data)
    if (response.statusCode == 101 || response.statusCode == '101') createDeposit(props.account?.id, props.account?.tapi_account_id, props.trade.investmentTotal, props.selectedFundingMethod.id)
    else if (response.errorCode == '404') emit('previous')
    else emit('previous')
  });
}

// Create Deposit
function createDeposit(account_id, tapi_account_id, depositAmount, funding_method) {
  // Send deposit request email
  emailsStore.sendDepositRequestEmail(account_id, tapi_account_id, depositAmount, props?.account?.name ?? 'No Nickname')

  // Create transaction in supabase
  transactionsStore.createTransactionInSupabase(null, account_id, null, 'cash', 1, partnerStore.partner.id, depositAmount, 'deposit', funding_method)

  // Finished 
  emit('next')
}
</script>