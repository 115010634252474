<template>
  <div class="p-5">

    <div class="text-lg dark:text-white font-semibold">Funding Method</div>
    <RadioGroup label="Payment Method" v-model="selected_funding_method" :options="availableFundingOptions" class="mt-2 mb-8 max-w-xl" />

    <div class="flex flex-row-reverse items-center justify-between">
      <button @click="handleClick('next')" class="self-end button_primary">Next
        <ArrowRightIcon class="w-4 h-4" />
      </button>
      <button @click="handleClick('previous')" class="self-end button_secondary">
        <ArrowLeftIcon class="w-4 h-4" />
        Previous
      </button>
    </div>

  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from 'vue'

// Libraries
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/vue/24/outline'
import RadioGroup from '@/components/applicationui/RadioGroup.vue';

// Emits
const emit = defineEmits(['next', 'previous'])

// Models
const selected_funding_method = defineModel()

// Globals
const availableFundingOptions = ref([
  { name: 'ACH', id: 'ach', description: 'Connect directly to your bank securely using Plaid (Recommended)' },
  { name: 'Check', id: 'check', description: 'Mail a check (The old fashioned way)' },
  { name: 'Wire', id: 'wire', description: 'Receive wire instructions and pay directly (Fastest)' }
])

// Mounted 
onMounted(() => {
  selected_funding_method.value = availableFundingOptions.value[0]
})

// Functions
const handleClick = (action) => { emit(action) }
</script>