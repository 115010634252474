<template>
  <RadioGroup v-model="selected">
    <RadioGroupLabel v-if="props.label" class="sr-only">{{ props.label }}</RadioGroupLabel>
    <div class="-space-y-px rounded-md bg-white dark:bg-neutral-900">
      <RadioGroupOption as="template" v-for="(option, optionIdx) in props.options" :key="option.value" :value="option" v-slot="{ checked, active }">
        <div
          :class="[optionIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', optionIdx === props.options.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'z-10 border-blue-200 dark:border-blue-700 dark:bg-blue-950/50 bg-blue-50' : 'border-gray-200 dark:border-neutral-800', 'relative flex cursor-pointer border p-4 focus:outline-none']">
          <span
            :class="[checked ? 'bg-blue-600 border-transparent' : 'bg-white border-gray-300 dark:border-neutral-700', active ? '' : '', 'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center']"
            aria-hidden="true">
            <span class="rounded-full bg-white w-1.5 h-1.5" />
          </span>
          <span class="ml-3 flex flex-col">
            <RadioGroupLabel as="span" :class="[checked ? 'text-blue-600' : 'text-gray-900 dark:text-neutral-200', 'block text-sm font-medium']">{{ option.name }}</RadioGroupLabel>
            <RadioGroupDescription as="span" :class="[checked ? 'text-blue-700 dark:text-blue-500 opacity-80' : 'text-gray-500 dark:text-neutral-400', 'block text-sm']">
              {{ option.description }}
            </RadioGroupDescription>
          </span>
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>

<script setup>
// Libraies
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

// Models
const selected = defineModel()

// Props
const props = defineProps({
  label: { type: String, required: true },
  options: { type: Array, required: true, default: [] }, //Expected Object Structure { value: 'key', name: 'name', description: 'description' }
})
</script>