<template>
  <div>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-50" @close="open = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75 dark:bg-neutral-500 dark:bg-opacity-75"></div>
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel :class="[(isPlaidOrCC) ? '' : 'sm:p-6 pt-5 pb-4 px-4']"
                class="relative sm:my-8 overflow-hidden text-left transition-all transform bg-gray-50 rounded-lg shadow-xl dark:bg-neutral-950">

                <!-- Close Button -->
                <div v-if="!isPlaidOrCC" class="absolute top-0 right-0 pt-4 pr-4 block">
                  <button type="button" class="text-gray-400 rounded-md outline-none hover:text-gray-500 dark:hover:text-neutral-300 dark:text-neutral-400" @click="open = false">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="w-6 h-6" aria-hidden="true" />
                  </button>
                </div>

                <div>

                  <!-- Steps -->
                  <StepsVisual v-if="current_page != 'thank-you' && !isPlaidOrCC" :steps="steps" class="-mt-2" />

                  <!-- Content -->
                  <div v-if="current_page == 'loading_spinner'" class="flex flex-col items-center justify-center gap-5 p-5 sm:w-[500px] sm:h-[300px] h-[50vh] w-[90vw]">
                    <div class="h-28 w-28 -mt-8">
                      <SpinnerFlip />
                    </div>
                  </div>

                  <div v-else-if="current_page == 'create-party'" class="flex flex-col items-center justify-center gap-5 p-5 sm:w-[500px] sm:h-[300px] h-[50vh] w-[90vw]">
                    <div class="h-40 w-40 -mt-20">
                      <SpinnerJump />
                    </div>
                  </div>

                  <div v-else-if="current_page == 'create-trade' || current_page == 'create-custody-trade'"
                    class="flex flex-col items-center justify-center gap-5 p-5 sm:w-[500px] sm:h-[300px] h-[90vh] w-[90vw]">
                    <div class="h-20 w-20">
                      <SpinnerBounce />
                    </div>
                  </div>

                  <ConfirmInvestment v-else-if="current_page == 'investment-confirmation'" :offering="props.offering" :trade="props.trade" :account="props.custody_account" @canceled="open = false"
                    @confirmed="nextPage()" />

                  <InvestorType v-else-if="current_page == 'select-investor-type'" v-model="selected_account_type" :showPreviousButton="isInvesting" @next="nextPage()" @previous="previousPage()" />

                  <InvestorBasic v-else-if="current_page == 'collect-basic-info'" v-model="party" :isEntity="isEntity" :isForOther="isForOther" :allowAdvisors="partnerStore.partner.allow_advisors"
                    @next="handleInvestorBasicNext" @previous="previousPage()" />

                  <InvestorEmployment v-else-if="current_page == 'collect-employment-info'" v-model="party" @next="nextPage()" @previous="previousPage()" />

                  <InvestorIncome v-else-if="current_page == 'collect-income-info'" v-model="party" :offering="props.offering" :accountType="selected_account_type.value.value" @next="nextPage()"
                    @previous="previousPage()" />

                  <KycAml v-else-if="current_page == 'kyc'" :isEntity="isEntity" :isForOther="isForOther" @next="nextPage()" :party="party" />

                  <AccreditationCheck v-else-if="current_page == 'collect-accreditation-info'" :isEntity="isEntity" :isForOther="isForOther" :isInvesting="isInvesting"
                    :account="props.custody_account ?? account" @next="nextPage()" />

                  <RegcfValidation v-else-if="current_page == 'regcf-validation'" :offering="props.offering" :trade="props.trade" :party_id="accountsStore.holdingAccount?.primary_party_id"
                    @next="nextPage()" @previous="previousPage()" />

                  <PaymentMethod v-else-if="current_page == 'select-payment-method'" v-model="selected_payment_method" :offering="props.offering" @next="nextPage()" @previous="previousPage()" />

                  <ProcessPayment v-else-if="current_page == 'payment'" :selectedPaymentMethod="selected_payment_method" :offering="props.offering" :account="accountsStore.holdingAccount"
                    @next="nextPage()" @previous="previousPage()" />

                  <SignMethod v-else-if="current_page == 'select-signing-method'" v-model="selected_signing_method" @next="nextPage()" />

                  <SignDocuments v-else-if="current_page == 'sign'" :account="props.custody_account ?? account ?? accountsStore.holdingAccount" :tapi_trade_id="tapi_trade_id"
                    :offering="props.offering" :signing_method="selected_signing_method.value" @next="nextPage()" />

                  <CustodyAccount v-else-if="current_page == 'create-custody-account'" :user="userStore.user" @next="handleCustodyAccountCreated" />

                  <TrustedContact v-else-if="current_page == 'add-trusted-contact'" v-model="trusted_contact" :account_id="props.custody_account?.id ?? account?.id" @next="nextPage()" />

                  <FundingMethod v-else-if="current_page == 'select-funding-method'" v-model="selected_funding_method" @next="nextPage()" @previous="previousPage()" />

                  <FundAccount v-else-if="current_page == 'fund'" :selectedFundingMethod="selected_funding_method" :offering="props.offering" :account="props.custody_account ?? account"
                    :trade="props.trade" @next="nextPage()" @previous="previousPage()" />

                  <div v-else-if="current_page == 'thank-you'" class="flex flex-col items-center justify-center gap-5 p-5 sm:w-[500px] sm:h-[300px] h-[50vh] w-[90vw]">
                    <div class="font-bold text-2xl bg-gradient-to-r from-operacolordark via-operacolor to-operacolordark text-transparent bg-clip-text">Thank you</div>
                    <div class="h-32 w-32">
                      <CheckmarkAnimated />
                    </div>
                  </div>

                </div>
                <!-- Content -->
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
// Essentials
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { usePartnerStore } from '@/stores/partner';
// import { useOfferingsStore } from '@/stores/offerings';
import { usePartiesStore } from '@/stores/parties';
import { useProfilesStore } from '@/stores/profiles';
import { useAccountsStore } from '@/stores/accounts';
import { usePositionsStore } from '@/stores/positions';
import { useEmailsStore } from '@/stores/emails';
import { useTransactionsStore } from '@/stores/transactions';

// Components
import StepsVisual from '@/components/ui/StepsVisual.vue'
import ConfirmInvestment from '@/components/flows/ConfirmInvestment.vue';
import InvestorType from '@/components/flows/InvestorType.vue';
import InvestorBasic from '@/components/flows/InvestorBasic.vue';
import SpinnerFlip from '@/components/loading/SpinnerFlip.vue';
import SpinnerBounce from '@/components/loading/SpinnerBounce.vue';
import SpinnerJump from '@/components/loading/SpinnerJump.vue';
import InvestorEmployment from '@/components/flows/InvestorEmployment.vue';
import InvestorIncome from '@/components/flows/InvestorIncome.vue';
import KycAml from '@/components/flows/KycAml.vue';
import AccreditationCheck from '@/components/flows/AccreditationCheck.vue';
import CheckmarkAnimated from '@/components/ui/CheckmarkAnimated.vue';
import PaymentMethod from '@/components/flows/PaymentMethod.vue';
import ProcessPayment from '@/components/flows/ProcessPayment.vue';
import SignDocuments from '@/components/flows/SignDocuments.vue';
import CustodyAccount from '@/components/flows/CustodyAccount.vue';
import FundingMethod from '@/components/flows/FundingMethod.vue';
import FundAccount from '@/components/flows/FundAccount.vue';
import SignMethod from '@/components/flows/SignMethod.vue';
import TrustedContact from '@/components/flows/TrustedContact.vue';
import RegcfValidation from '@/components/flows/RegcfValidation.vue';

// Libraries
import { getUnits } from '@/helper/helper'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'

// Models
const open = defineModel()

// Props
const props = defineProps({
  need: { type: String, required: true, default: 'create-party-for-self' },
  // need types: create-party-for-self, create-party-for-other, create-account, create-custody-investment, create-direct-investment
  // party: { type: Object, required: false, default: null }, // Optional
  custody_account: { type: Object, required: false, default: null }, // Optional
  offering: { type: Object, required: false, default: null }, // Optional
  trade: { type: Object, required: false, default: null }, // Optional
})

// Emits
const emit = defineEmits(['completed', 'error', 'closed'])

// Stores
const router = useRouter()
const partnerStore = usePartnerStore();
const userStore = useUserStore();
// const offeringsStore = useOfferingsStore();
const partiesStore = usePartiesStore();
const profilesStore = useProfilesStore();
const accountsStore = useAccountsStore();
const positionsStore = usePositionsStore();
const emailsStore = useEmailsStore();
const transactionsStore = useTransactionsStore();

// Mounted
onMounted(async () => {
  // Decided on the needed pages
  await setFlow(props.need, partnerStore.partner, props.custody_account, props.trade, props.offering)

  // Create the steps
  createSteps()

  // Set the current page to the firs page of the flow
  current_page.value = flow.value[current_page_index.value]
})

// Set Flow
const flow = ref([])
const current_page = ref('loading_spinner')
const current_page_index = ref(0)

async function setFlow(need, partner, custody_account, trade, offering) {
  // Create a party for self
  if (need == 'create-party-for-self') {
    flow.value.push('select-investor-type')
    flow.value.push('collect-basic-info')
    if (partner.collect_employer_data) flow.value.push('collect-employment-info')
    if (partner.collect_income_data) flow.value.push('collect-income-info')
    flow.value.push('create-party')
    if (partner.partners_protected.partner_type == 'dab') flow.value.push('collect-accreditation-info')
    flow.value.push('thank-you')
    return
  }

  // Create a party for other
  if (need == 'create-party-for-other') {
    flow.value.push('select-investor-type')
    flow.value.push('collect-basic-info')
    if (partner.collect_employer_data) flow.value.push('collect-employment-info')
    if (partner.collect_income_data) flow.value.push('collect-income-info')
    flow.value.push('create-party')
    if (partner.partners_protected.partner_type == 'dab') flow.value.push('collect-accreditation-info')
    flow.value.push('thank-you')
    return
  }

  // Create an account
  if (need == 'create-account') {
    // Validate
    if (!partner.allow_custody_accounts) { emit('error'); return }
    // Check if the user needs to create a party first
    await partiesStore.setParties()
    if (!partiesStore.parties || partiesStore.parties.length == 0) {
      flow.value.push('select-investor-type')
      flow.value.push('collect-basic-info')
      if (partner.collect_employer_data) flow.value.push('collect-employment-info')
      if (partner.collect_income_data) flow.value.push('collect-income-info')
      flow.value.push('create-party')
      if (partner.partners_protected.partner_type == 'dab') flow.value.push('collect-accreditation-info')
    }
    // After creating a party or if the user already has a party
    flow.value.push('create-custody-account')
    flow.value.push('add-trusted-contact')
    flow.value.push('thank-you')
    return
  }

  // Create a custody investment
  if (need == 'create-custody-investment') {
    // Validate
    if (!trade) { emit('error'); return }
    if (!offering) { emit('error'); return }

    flow.value.push('investment-confirmation')
    // Check if the user needs to create a party first
    await partiesStore.setParties()
    if (!partiesStore.parties || partiesStore.parties.length == 0) {
      flow.value.push('select-investor-type')
      flow.value.push('collect-basic-info')
      if (partner.collect_employer_data) flow.value.push('collect-employment-info')
      if (partner.collect_income_data) flow.value.push('collect-income-info')
      flow.value.push('create-party')
    }
    // Check if the user needs to create a custody account
    if (!custody_account || custody_account?.accounts_protected?.is_holding_account) {
      flow.value.push('create-custody-account')
      flow.value.push('add-trusted-contact')
      flow.value.push('select-funding-method')
    }
    else {
      // Get the available cash balance
      const cash_balance = await positionsStore.getAvailableCash(custody_account.id)

      // Check if the user has enough cash to make the trade
      if (cash_balance < props.trade.investmentTotal) {
      // If the user does not have enough cash, add the fund page to the flow
        flow.value.push('select-funding-method')
      }
    }
    if (offering.use_regcf_protocols) flow.value.push('regcf-validation')
    flow.value.push('fund')
    flow.value.push('create-custody-trade')
    flow.value.push('select-signing-method')
    flow.value.push('sign')
    flow.value.push('thank-you')
    return
  }

  // Create a direct investment
  if (need == 'create-direct-investment') {
    // Validate
    if (!trade) { emit('error'); return }
    if (!offering) { emit('error'); return }

    flow.value.push('investment-confirmation')
    // Check if the user needs to create a party first
    await partiesStore.setParties()
    if (!partiesStore.parties || partiesStore.parties.length == 0) {
      flow.value.push('select-investor-type')
      flow.value.push('collect-basic-info')
      if (partner.collect_employer_data) flow.value.push('collect-employment-info')
      if (partner.collect_income_data) flow.value.push('collect-income-info')
      flow.value.push('create-party')
      if (offering.require_accreditation) flow.value.push('collect-accreditation-info')
    }
    else {
      if (offering.require_accreditation && accountsStore.holdingAccount.accounts_protected.accredited_status == 'Not Accredited') {
        flow.value.push('collect-accreditation-info')
      }
    }
    if (offering.use_regcf_protocols) flow.value.push('regcf-validation')
    flow.value.push('select-payment-method')
    flow.value.push('payment')
    flow.value.push('create-trade')
    flow.value.push('select-signing-method')
    flow.value.push('sign')
    flow.value.push('thank-you')
    return
  }
}

async function nextPage() {
  current_page_index.value++
  current_page.value = flow.value[current_page_index.value]
  updateSteps(current_page.value)

  // Handle Special Cases
  if (current_page.value == 'thank-you') {
    await new Promise(resolve => setTimeout(resolve, 4000));
    emit('completed')
    if (props.need == 'create-custody-investment') router.push({ path: '/dashboard/accounts', query: { page: 'Transactions', account_id: `${props.custody_account?.id ?? account.value.id}` } })
    if (props.need == 'create-direct-investment') router.push({ path: '/dashboard/holdings' })
  }
  if (current_page.value == 'collect-employment-info' && selected_account_type.value.value == 'entity') nextPage()
  if (current_page.value == 'create-party') {
    const passed_kyc_aml = await createParty()
    if (!passed_kyc_aml) { current_page.value = 'kyc'; return }
    nextPage()
  }
  if (current_page.value == 'create-trade') {
    tapi_trade_id.value = await createDirectTrade()
    nextPage()
  }
  if (current_page.value == 'create-custody-trade') {
    tapi_trade_id.value = await createCustodyTrade()
    nextPage()
  }
}

const previousPage = () => {
  current_page_index.value--
  current_page.value = flow.value[current_page_index.value]
  updateSteps(current_page.value)
}

// Party Info
const entity_name = ref('')
const isAdvisor = ref(false)
const party = ref({ isUSCitizen: true, partner_id: partnerStore.partner.id, employment_status: 'employed', is_associated_bd: false })
const account = ref({})

async function createParty() {
  // Validation
  if (party.value.employment_status != 'employed') party.value.employer_state = null

  // Create the party in TAPI
  party.value.tapi_party_id = await partiesStore.createPartyInTapi(party.value)
  if (!party.value.tapi_party_id) handleError('Error when creating tapi party', 'There was an issue, please try again. If the issue persists, please contact tech support.')

  // Create the party in Supabase
  party.value.id = await partiesStore.createParty(party.value);
  if (!party.value.id) handleError('Error when creating party', 'There was an issue, please try again. If the issue persists, please contact tech support.')

  // Run KYC & AMl
  await partiesStore.performKycAml(party.value.id, party.value.tapi_party_id);

  // Create Holding Account in TAPI
  let registration = `${party.value.first_name} ${party.value.last_name}`
  if (selected_account_type.value.value == 'entity') registration = entity_name.value
  const tapi_account_id = await accountsStore.createHoldingAccountInTapi(party.value.id, party.value, selected_account_type.value.value, registration)
  if (!tapi_account_id) handleError('Error when creating tapi account', 'There was an issue, please try again. If the issue persists, please contact tech support.')

  // Create Holding Account in Supabase
  let name = `${party.value.first_name} ${party.value.last_name} Holdings`
  if (selected_account_type.value.value == 'entity') name = `${entity_name.value} Holdings`
  console.log('party:', party.value)
  account.value = await accountsStore.createHoldingAccount({ tapi_account_id, partner_id: partnerStore.partner.id, name, primary_party_id: party.value.id, type: selected_account_type.value.value, tax_id: party.value?.tax_id })
  if (!account.value || account.value.length <= 0) handleError('Error when creating holding account', 'There was an issue, please try again. If the issue persists, please contact tech support.')

  // If the user is creating this first party for themselves, update their profile party_id
  if (!isForOther.value) {
    await profilesStore.updateProfileParty(userStore.user.id, party.value.id);
    // Update stores
    await userStore.setUser()
    await accountsStore.setHoldingAccount()
    account.value = accountsStore.holdingAccount
  }

  // Create link in TAPI (don't need to wait for this)
  accountsStore.createLinkInTapi(tapi_account_id, party.value.tapi_party_id, account.value.id)

  // If they are an advisor, notify the team (don't need to wait for this)
  if (isAdvisor.value && partnerStore.partner.allow_advisors) emailsStore.sendAdvisorRequestEmail(party.value.tapi_party_id, `${party.value.first_name} ${party.value.last_name}`)

  // Check if the new party passed kyc or aml
  // If they didn't pass and they do not have kyc disabled, go to kyc page
  const kyc_and_aml_approved = await partiesStore.checkInvestorVerification(party.value.id)
  if (!kyc_and_aml_approved && !partnerStore.partner.partners_protected.kyc_disabled) {
    current_page.value = 'kyc'
    // Return false if they did not pass kyc and aml
    return false
  }
  // Return true if they passed kyc and aml
  return true
}

// Create Trade
const tapi_trade_id = ref('')
async function createDirectTrade() {
  if (!account.value.id) {
    await accountsStore.setHoldingAccount()
    account.value = accountsStore.holdingAccount
  }

  const tapi_trade_id = await transactionsStore.createTradeInTapi(account.value.id, account.value.tapi_account_id, props.offering.tapi_offering_id, props.offering.name, props.trade.transactionUnits, selected_payment_method.value.id.toUpperCase())
  if (!tapi_trade_id) handleError('Failed to create trade in tapi', 'There was an issue, please try again. If the issue persists, please contact tech support.')

  // Create transaction in supabase
  const transaction_id = await transactionsStore.createTransactionInSupabase(tapi_trade_id, account.value.id, props.offering.id, getUnits(props.offering.offering_type), props.offering.unit_price, partnerStore.partner.id, props.trade.transactionUnits, 'buy', selected_payment_method.value.id.toLowerCase())
  if (!transaction_id) handleError('Failed to create transaction in supabase', 'There was an issue, please try again. If the issue persists, please contact tech support.')

  // Send ach request (external fund move) if the payment method is ach
  if (selected_payment_method.value.id.toLowerCase() == 'ach') transactionsStore.requestAchFundsTransferInTapi(account.value.id, tapi_trade_id, account.value.tapi_account_id, props.offering.tapi_offering_id, props.offering.name, props.trade.investmentTotal)

  // Send cc request (external fund move) if the payment method is cc
  if (selected_payment_method.value.id.toLowerCase() == 'creditcard') transactionsStore.requestCcFundsTransferInTapi(account.value.id, tapi_trade_id, account.value.tapi_account_id, props.trade.investmentTotal)

  // Send Thank You Email
  // TODO, makes this get the email from the account used to make the trade not the user's email
  emailsStore.sendThankYouEmailForInvesting(userStore.user.parties.contact_email, props.offering.name, props.trade.investmentTotal, partnerStore.partner, selected_payment_method.value.name, props.trade.transactionUnits)

  return tapi_trade_id
}

async function createCustodyTrade() {
  const custody_account = props.custody_account ?? account.value

  const tapi_trade_id = await transactionsStore.createTradeInTapi(custody_account.id, custody_account.tapi_account_id, props.offering.tapi_offering_id, props.offering.name, props.trade.transactionUnits, 'TBD')
  if (!tapi_trade_id) handleError('Failed to create trade in tapi', 'There was an issue, please try again. If the issue persists, please contact tech support.')

  // Create transaction in supabase
  const transaction_id = await transactionsStore.createTransactionInSupabase(tapi_trade_id, custody_account.id, props.offering.id, getUnits(props.offering.offering_type), props.offering.unit_price, partnerStore.partner.id, props.trade.transactionUnits, 'buy', 'custody')
  if (!transaction_id) handleError('Failed to create transaction in supabase', 'There was an issue, please try again. If the issue persists, please contact tech support.')

  // Send Thank You Email
  // TODO, makes this get the email from the account used to make the trade not the user's email
  emailsStore.sendThankYouEmailForInvesting(userStore.user.parties.contact_email, props.offering.name, props.trade.investmentTotal, partnerStore.partner, 'Custody Transaction', props.trade.transactionUnits)

  return tapi_trade_id
}

// Steps
const steps = ref([])
const createSteps = () => {
  flow.value.forEach((step, index) => {
    if (step !== 'create-party' && step !== 'create-trade' && step !== 'create-custody-trade' && step !== 'thank-you' && step !== 'select-signing-method') {
      steps.value.push({ name: step, status: index == 0 ? 'current' : 'upcoming' })
    }
  })
}
const updateSteps = (currentStepName) => {
  // Get the index of the currentStepName
  const currentIndex = flow.value.indexOf(currentStepName)

  if (currentIndex > steps.value.length - 1) return

  // If the currentStepName is not in the flow, do nothing
  if (!currentIndex) return

  // If the currentStepName is in the flow, update the steps
  steps.value.forEach((step, index) => {
    if (currentIndex == index) { step.status = 'current' }
    else if (index < currentIndex) step.status = 'complete'
    else step.status = 'upcoming'
  })
}

// Investor Type
// The selected_account_type must match one of the values in InvestorType.vue availableAccountTypes array
const selected_account_type = ref()
const selected_signing_method = ref()

// Investor Info Basic
const isEntity = computed(() => {
  if (selected_account_type.value?.value == 'entity') return true
  return false
})
const isForOther = computed(() => {
  if (props.need == 'create-party-for-other') return true
  return false
})
const isInvesting = computed(() => {
  if (props.need == 'create-custody-investment' || props.need == 'create-direct-investment') return true
  return false
})
const isPlaidOrCC = computed(() => {
  if (current_page.value == 'payment' && (selected_payment_method.value?.id == 'ach' || selected_payment_method.value?.id == 'creditcard')) return true
  if (current_page.value == 'fund' && (selected_payment_method.value?.id == 'ach' || selected_payment_method.value?.id == 'creditcard')) return true
  return false
})

const handleInvestorBasicNext = (additional_info) => {
  entity_name.value = additional_info.entity_name
  isAdvisor.value = additional_info.isAdvisor
  nextPage()
}

const handleCustodyAccountCreated = (custody_account) => {
  account.value = custody_account
  nextPage()
}

// Payment Method
const selected_payment_method = ref()

// Funding Method
const selected_funding_method = ref()

// Trusted Contact
const trusted_contact = ref({})

// Error Handling
const handleError = (error, errorMessage = 'Something went wrong, please try again. If the issue persists, please contact support.') => {
  console.log(error)
  emit('error', errorMessage)
  open.value = false
}
</script>