<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <!-- Popups -->
    <NotificationPopup :type="notificationType" :title="notificationTitle" :text="notificationText" ref="notification" />
    <!-- Confirm Trade Status Change Popup -->
    <UpdateTrade v-if="open" :show="open" v-model="transaction" @closed="open = false" @updated="notify('success', 'Success', 'Transaction updated successfully'); setAllTransactions()"
      @error="notify('failure', 'Sorry', 'Something unexpected happened, please try again, or contact tech support')" />
    <!-- Popups -->

    <!-- Loading -->
    <div v-if="loading" class="flex flex-grow items-center justify-center h-[70vh]">
      <div class="w-32 h-32 -mt-10">
        <SpinnerBounce />
      </div>
    </div>

    <!-- Transactions Table -->
    <div v-else>
      <!-- Header -->
      <div>
        <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 dark:border-neutral-700">
          <!-- Tab Header -->
          <h3 class="primary_header">Transactions</h3>

          <div class="flex items-center gap-4">
            <!-- Search -->
            <div class="flex items-center gap-2">
              <div>
                <div class="relative rounded-md">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <MagnifyingGlassIcon class="w-4 h-4 text-gray-400 dark:text-neutral-400" aria-hidden="true" />
                  </div>
                  <input type="text" name="search" id="search" class="pl-8 input" placeholder="Quick Search" v-model="query" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex">
          <SwitchGroup as="div" class="flex items-center justify-between w-fit">
            <Switch v-model="isTable"
              class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-operacolor focus:ring-offset-2 dark:focus:outline-none dark:focus:ring-0 dark:focus:ring-operacolor dark:focus:ring-offset-0">
              <span class="sr-only">Use setting</span>
              <span aria-hidden="true" class="pointer-events-none absolute h-full w-full rounded-md bg-gray-50 dark:bg-neutral-950"></span>
              <span aria-hidden="true"
                :class="[isTable ? 'bg-operacolor' : 'bg-gray-200 dark:bg-neutral-700', 'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out']"></span>
              <span aria-hidden="true"
                :class="[isTable ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out']"></span>
            </Switch>
            <SwitchLabel as="span" class="ml-3 text-sm">
              <span class="font-medium text_color_primary">Table View</span>
            </SwitchLabel>
          </SwitchGroup>
        </div>
      </div>

      <!-- Transactions Table -->
      <TablePrimary v-if="isTable" :rows="filteredTransactions" :columns="columns" :isClickable="clickable" @click-primary="handleTransactionClicked" class="-mt-5" export_title="transactions" />

      <!-- Transaction Card -->
      <div v-else>
        <ul role="list" class="flex gap-10 flex-wrap mt-4">
          <div v-for="transaction in filteredTransactions" :key="transaction.id" @click="handleTransactionClicked(transaction)" class="w-96 mt-2">
            <TransactionCard :transaction="transaction" :clickable="clickable" />
          </div>
        </ul>
      </div>

    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, computed, onMounted } from 'vue'
import { useUserStore } from '@/stores/user'
import { useTransactionsStore } from '@/stores/transactions'
// Components
import UpdateTrade from '@/components/popups/UpdateTrade.vue'
import NotificationPopup from '@/components/popups/NotificationPopup.vue'
import SpinnerBounce from '@/components/loading/SpinnerBounce.vue'
import TablePrimary from '@/components/widgets/TablePrimary.vue'
import TransactionCard from '@/components/cards/TransactionCard.vue'
// Libraries
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid'
// Stores
const userStore = useUserStore();
const transactionsStore = useTransactionsStore();
// Globals
const isTable = ref(true);
if (window.innerWidth < 1024) isTable.value = false
const transactions = ref([])
const transaction = ref({})
const loading = ref(true)
const query = ref('');
const clickable = ref(false)
const open = ref(false)
const newStatus = ref('settled')
const notification = ref(null)
const notificationType = ref('success')
const notificationTitle = ref('Success')
const notificationText = ref('Transaction updated successfully')
const columns = [
  { key: 'offerings.name', label: 'Offering' },
  { key: 'tapi_trade_id', label: 'Trade #', type: 'small' },
  { key: 'status', label: 'Status', type: 'badge' },
  { key: 'type', label: 'Type', type: 'badge' },
  { key: 'accounts.name', label: 'Account' },
  { key: 'transaction_method', label: 'Method', type: 'badge' },
  { key: 'amount', label: 'Amount' },
  { key: 'price_per_unit', label: 'Price', type: 'money' },
  { key: 'amount', key2: 'price_per_unit', label: 'Total', type: 'total' },
  { key: 'units', label: 'Units', type: 'badge' },
  { key: 'created_at', label: 'Created Date', type: 'date' },
]

// Mounted
onMounted(async () => {
  // Setup
  if(userStore.user.profiles_protected.role == 'super_admin') clickable.value = true
  // Get Transactions
  await setAllTransactions()
  // Ready
  loading.value = false
})

// Computed
const filteredTransactions = computed(() => {
  return query.value === '' ? transactions.value :
    transactions.value.filter((transaction) => {
      if (transaction?.id?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (transaction?.offerings?.name?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (transaction?.status?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (transaction?.type?.toLowerCase().includes(query.value.toLowerCase())) return true
      if (transaction?.tapi_trade_id?.includes(query.value)) return true;
      if (transaction?.account_id?.includes(query.value.toLowerCase())) return true;
      if (transaction?.accounts?.name?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (transaction?.units?.toLowerCase().includes(query.value.toLowerCase())) return true;
    });
});

// Functions
async function setAllTransactions() {
  const data = await transactionsStore.getAllTransactions()
  if (!data) {
    notify('failure', 'Sorry', 'Failed to get transactions, please try again later, or contact tech support')
    return
  }
  transactions.value = data
}

// Simple Functions
const handleTransactionClicked = (t) => {
  if (t.status == 'settled') return
  transaction.value = t
  newStatus.value = transaction.value.status
  if (userStore.user.profiles_protected.role == 'super_admin') open.value = true
}

const notify = (type, title, text) => {
  notificationType.value = type
  notificationTitle.value = title
  notificationText.value = text
  notification.value.show()
}
</script>
