<template>
  <div> <!-- Leave this outer div empty -->
    <div class="flex flex-col w-fit max-w-[1000px]">
      <img class="w-full" :src="image" />
      <div class="flex flex-wrap items-end justify-between mt-10 gap-y-5">
        <div class="text-3xl font-bold">Custodian Broker Dealers: A Historical Overview and their Role in Financial Markets</div>
        <div class="text-sm">by: Grant Nelson</div>
      </div>

      <div class="flex flex-col gap-5 my-10 w-full max-w-[1000px]">
        <!-- Paragraph 1 -->
        <div>
          Custodian broker dealers (“Custodians”) have been players in the financial markets for centuries, providing services that facilitate the safekeeping, trading, and settlement of securities.
          Their history is intertwined with the evolution of modern finance, witnessing transformative changes, and becoming integral to the functioning of global financial systems. This article
          explores the historical development of Custodians and highlights their importance in the contemporary financial landscape.
        </div>
        <!-- Paragraph 2 -->
        <div class="text-xl font-semibold">Early Origins and Development</div>
        <div>
          The origins of Custodian can be traced back to the emergence of modern stock exchanges in the 17th century. As financial markets expanded and investment activity grew, the need for secure
          storage and trading of securities became apparent. Early broker dealers acted as intermediaries, executing trades on behalf of clients and offering custodian services to safeguard assets.
          <br><br>
          In the United States, the establishment of the first formal stock exchange, the Philadelphia Stock Exchange, in 1790, marked a significant milestone for Custodians. As more stock exchanges
          sprouted across the country, these entities became vital in facilitating the transfer of ownership and enabling trade settlement.
        </div>
        <!-- Paragraph 3 -->
        <div class="text-xl font-semibold">The Evolution of Services</div>
        <div>
          As financial markets evolved and technology advanced, Custodians expanded their range of services. In the early 20th century, they played a critical role in helping investors access a broader
          array of financial instruments, such as bonds, commodities, and foreign securities. This diversification boosted market liquidity and encouraged greater investor participation.
          <br><br>
          During the mid-20th century, Custodians embraced the digitization of financial markets. With the advent of electronic trading systems and the rise of computer-based trading platforms, they
          became more efficient in executing trades and managing client portfolios. Additionally, custodian services saw automation, allowing for real-time tracking of positions and transactions.
        </div>
        <!-- Paragraph 4 -->
        <div class="text-xl font-semibold">The Importance of Custodian Broker Dealers</div>
        <div>
          Safekeeping of Assets: Custodians ensure the safekeeping of clients' assets, such as stocks, bonds, and mutual funds. By holding these assets or verifing a good control location, they protect
          investors from the risk of physical loss or theft.
          <br><br>
          Trade Settlement: These entities play a vital role in trade settlement, ensuring the smooth and timely transfer of securities between buyers and sellers. This function is essential in
          maintaining market stability and investor confidence.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { supabase } from '@/lib/supabaseClient'
export default {
  data() {
    return {
      image: 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/custodianBrokerDealer/world'
    }
  }
}
</script>