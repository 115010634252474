<template>
  <form @submit.prevent="handleSubmit()" class="flex flex-col gap-5 p-5 mt-2">

    <div v-if="props.isEntity" class="flex flex-col gap-5 md:items-center md:flex-row">
      <!-- Entity Account Name -->
      <div class="grow">
        <label for="entityName" class="input_label">Entity's Account Name</label>
        <div class="input_wrapper">
          <input v-model="entity_name" type="text" name="entityName" id="entityName" class="input" placeholder="Apple LLC" aria-required required />
        </div>
      </div>
      <div class="max-w-52">
        <label for="tax_id" class="input_label">Tax ID (EIN)</label>
        <div class="input_wrapper">
          <input v-model="party.tax_id" type="text" name="tax_id" id="tax_id" class="input" placeholder="##-#######" autocomplete="ein" aria-required required />
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-5 md:items-center md:flex-row">
      <!-- First Name -->
      <div class="grow">
        <label for="firstName" class="input_label">{{ contextLabel }} First Name</label>
        <div class="input_wrapper">
          <input v-model="party.first_name" type="text" name="firstName" id="firstName" class="input" placeholder="John" aria-required required />
        </div>
      </div>
      <!-- Middle Initial -->
      <div class="w-24">
        <label for="initial" class="input_label">Middle Initial</label>
        <div class="input_wrapper">
          <input v-model="party.initial" type="text" name="initial" id="initial" class="input" placeholder="J" />
        </div>
      </div>
      <!-- Last Name -->
      <div class="grow">
        <label for="lastName" class="input_label">{{ contextLabel }} Last Name</label>
        <div class="input_wrapper">
          <input v-model="party.last_name" type="text" name="lastName" id="lastName" class="input" placeholder="Smith" aria-required required />
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-5 md:items-center md:flex-row">
      <!-- Email -->
      <div class="grow">
        <label for="contactEmail" class="input_label">{{ contextLabel }} Contact Email</label>
        <div class="input_wrapper">
          <input v-model="party.contact_email" type="email" autocomplete="email" name="contactEmail" id="contactEmail" class="input" placeholder="you@example.com" aria-required required />
        </div>
      </div>
      <!-- Phone -->
      <div class="grow">
        <label for="phone" class="input_label">{{ contextLabel }} Phone</label>
        <div class="input_wrapper">
          <input v-model="party.phone" type="tel" autocomplete="tel" name="phone" id="phone" class="input" placeholder="###-###-####" aria-required required />
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-5 md:items-center md:flex-row">
      <!-- Date of Birth -->
      <div class="grow">
        <label for="birthdate" class="input_label">{{ contextLabel }} Date of Birth</label>
        <div class="input_wrapper">
          <input v-model="party.date_of_birth" type="date" name="birthdate" id="birthdate" class="input" placeholder="mm/dd/yyyy" autocomplete="birthdate" aria-required required />
        </div>
      </div>
      <!-- Social Security -->
      <div class="grow">
        <label for="ssn" class="input_label">{{ contextLabel }} Social Security Number</label>
        <div class="input_wrapper">
          <input v-model="party.social" type="text" name="ssn" id="ssn" class="input" placeholder="###-##-####" pattern="\d{3}-\d{2}-\d{4}" autocomplete="ssn" aria-required required />
        </div>
      </div>
    </div>

    <div class="flex items-center gap-10 flex-wrap">
      <!-- U.S. Citizen -->
      <div class="flex flex-wrap items-center gap-5 text-sm font-medium leading-6 dark:text-neutral-300">
        <RadioHorizontal v-model="party.isUSCitizen" :label="getCitizenLabel" :items="[{ label: 'Yes', value: true }, { label: 'No', value: false }]" />
      </div>

      <!-- Advisor -->
      <div v-if="props.allowAdvisors && !props.isForOther" class="flex flex-wrap items-center gap-5 text-sm font-medium leading-6 dark:text-neutral-300 min-w-36">
        <RadioHorizontal v-model="isAdvisor" label="Are you a financial advisor?"
          info="Most financial advisors have passed the Series 7, Series 63 and 65 (or 66) Exam, or have their CFP or CFA. If you select yes we will reach out to you soon to approve you as an advisor on our platform."
          :items="[{ label: 'Yes', value: true }, { label: 'No', value: false }]" />
      </div>
    </div>

    <div class="flex flex-col gap-5 md:items-center md:flex-row">
      <!-- Address -->
      <div class="grow">
        <label for="address" class="input_label">{{ contextLabel }} Address</label>
        <div class="input_wrapper">
          <input v-model="party.address" type="text" name="address" id="address" class="input" placeholder="221 Baker St." aria-required required />
        </div>
      </div>
      <!-- Apt/Unit -->
      <div class="w-24">
        <label for="apt" class="input_label">Apt/Unit</label>
        <div class="input_wrapper">
          <input v-model="party.unit" type="text" name="apt" id="apt" class="input" placeholder="B" />
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-5 md:items-center md:flex-row">
      <!-- City -->
      <div class="">
        <label for="city" class="input_label">{{ contextLabel }} City</label>
        <div class="input_wrapper">
          <input v-model="party.city" type="text" name="city" id="city" class="input" placeholder="Manhattan" aria-required required />
        </div>
      </div>
      <!-- State -->
      <div class="w-40">
        <label for="state" class="input_label">{{ contextLabel }} State</label>
        <div class="input_wrapper">
          <SelectMenu v-model="party.state" :items="states" :inverted="true" />
        </div>
      </div>
      <!-- Country -->
      <div class="w-24">
        <label for="country" class="input_label">Country</label>
        <div class="input_wrapper">
          <input v-model="party.country" type="text" name="country" id="country" class="input" placeholder="U.S." aria-required required />
        </div>
      </div>
      <!-- Zip -->
      <div class="max-w-24">
        <label for="zip" class="input_label">{{ contextLabel }} Zip</label>
        <div class="input_wrapper">
          <input v-model="party.zip" type="text" name="zip" id="zip" class="input" placeholder="10119" aria-required required />
        </div>
      </div>
    </div>

    <div class="flex flex-row-reverse items-center justify-between mt-5">
      <button type="submit" class="self-end button_primary">Next
        <ArrowRightIcon class="w-4 h-4" />
      </button>
      <button type="button" @click="handleClick('previous')" class="self-end button_secondary">
        <ArrowLeftIcon class="w-4 h-4" />
        Previous
      </button>
    </div>
  </form>
</template>

<script setup>
// Essentials
import { ref, computed, watch } from 'vue'
// Libraries
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/vue/24/outline'
import { states } from '@/helper/helper'
// Components
import SelectMenu from '@/components/applicationui/SelectMenu.vue'
import RadioHorizontal from '@/components/applicationui/RadioHorizontal.vue'

// Props
const props = defineProps({
  isEntity: { type: Boolean, required: false, default: false },
  isForOther: { type: Boolean, required: false, default: false },
  allowAdvisors: { type: Boolean, required: false, default: false },
})

// Emits
const emit = defineEmits(['next', 'previous'])

// Computed
const contextLabel = computed(() => {
  if (props.isEntity) return "Agent's"
  if (props.isForOther) return "Client's"
  return ''
});

// Party Data
const entity_name = ref('')
const isAdvisor = ref(false)
const party = defineModel()

// Citizen Label
const getCitizenLabel = computed(() => {
  if (props.isEntity) return "Is the agent a U.S. Citizen?"
  if (props.isForOther) return "Is this client a U.S. Citizen?"
  return "Are you a U.S. Citizen?"
});

// Previous
const handleClick = (emitted) => {
  emit(emitted)
}

// Create Investor
const handleSubmit = () => {
  emit('next', { entity_name: entity_name.value, isAdvisor: isAdvisor.value })
}

// Watchers
watch(() => party.value.social, (newValue) => {
  // Remove non-digit characters
  const value = newValue.replace(/\D/g, '');
  // Format based on length
  if (value.length <= 3) {
    party.value.social = value;
  } else if (value.length <= 5) {
    party.value.social = `${value.slice(0, 3)}-${value.slice(3)}`;
  } else {
    party.value.social = `${value.slice(0, 3)}-${value.slice(3, 5)}-${value.slice(5, 9)}`;
  }
})

watch(() => party.value.phone, (newValue) => {
  // Remove non-digit characters
  const value = newValue.replace(/\D/g, '');

  // Determine if there's a country code (assume up to 2 digits)
  let countryCode = '';
  let phoneNumber = value.slice(0, 12); // Ensure max length is 12 digits

  if (phoneNumber.length > 10) {
    countryCode = `+${phoneNumber.slice(0, phoneNumber.length - 10)}`;
    phoneNumber = phoneNumber.slice(phoneNumber.length - 10);
  }

  // Format based on length of phoneNumber
  if (phoneNumber.length <= 3) {
    party.value.phone = `${countryCode}${phoneNumber}`;
  } else if (phoneNumber.length <= 6) {
    party.value.phone = `${countryCode} (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  } else {
    party.value.phone = `${countryCode} (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }
});

watch(() => party.value.country, (newValue) => {
  const trimmedValue = newValue.trim().toUpperCase(); // Trim and convert to uppercase

  // Format based on common country codes
  switch (trimmedValue) {
    case 'US':
    case 'us':
    case 'USA':
    case 'UNITED STATES':
    case 'AMERICA':
      party.value.country = 'U.S.A';
      break;
    case 'CA':
    case 'CAN':
    case 'CANADA':
      party.value.country = 'Canada';
      break;
    case 'UK':
    case 'GB':
    case 'UNITED KINGDOM':
      party.value.country = 'UK';
      break;
    // Add more cases for other countries as needed
    default:
      // Do nothing or handle other cases here
      break;
  }
});
</script>