<template>  
  <router-view/>
</template>

<script>

export default {
  data() {
    return {

    }
  },
  async mounted() {
    if(this.$route.path == '/' || this.$route.path == '') { return; } //Saving Landing Page
  },
}
</script>

<style>
#app {
  font-family: "Myriad Pro", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-gray-900 dark:text-neutral-300
}
</style>
