<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl  bg-gray-50 dark:bg-neutral-950 sm:my-8 sm:p-6">
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button type="button" class="rounded-md text-gray-400 dark:text-neutral-300 hover:text-gray-500 hover:dark:text-neutral-400 outline-none" @click="open = false">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <!-- Content -->

              <form @submit.prevent="showNext()" v-if="page == 1" class="flex flex-col sm:p-5 gap-7">
                <!-- Top Row Page One -->
                <div class="w-40">
                  <!-- Account Type -->
                  <div class="select_menu_label">Account Type</div>
                  <SelectMenu v-model="registration" :items="registrationOptions" />
                </div>

                <div class="grow w-72">
                  <label for="name" class="input_label">Account Nickname</label>
                  <div class="input_wrapper">
                    <input v-model="name" type="text" name="name" id="name" class="input" placeholder="John's Brokerage Account" required />
                  </div>
                </div>

                <!-- Primary Select -->
                <div class="max-w-72">
                  <div class="select_menu_label">Primary Account Holder</div>
                  <SelectMenu v-model="primary" :items="partiesStore.parties ?? []" />
                </div>

                <!-- Secondary Select -->
                <div v-if="showSecondary" class="max-w-72">
                  <div class="select_menu_label">Secondary Account Holder</div>
                  <SelectMenu v-model="secondary" :items="partiesStore.parties ?? []" />
                </div>

                <!-- Accredited Check -->
                <div class="flex flex-wrap items-center gap-5 text-sm font-medium leading-6 mr-5">
                  <div class="flex items-center gap-1">
                    <div class="input_label">Are all account holders accredited investors?</div>
                    <InfoCircle
                      info="An accredited investor is if an individual with a gross income over $200,000 for the last two years, or joint income $300,000 and a reasonable expectation of the same income level for the current year. A person is also considered an accredited investor if they have a net worth over $1 million, either individually or jointly with their spouse. This amount cannot include a primary residence." />
                  </div>
                  <div class="flex items-center gap-1">
                    <div class="text_color_secondary">Yes</div>
                    <input type="radio" v-model="isAccreditedInvestor" :value="true">
                  </div>
                  <div class="flex items-center gap-1">
                    <div class="text_color_secondary">No</div>
                    <input type="radio" v-model="isAccreditedInvestor" :value="false">
                  </div>
                </div>

                <!-- Accreditation Section -->
                <div v-if="page == isAccreditedInvestor" class="max-w-2xl dark:text-neutral-300">
                  <div class="mb-5 dark:text-white">
                    Please choose one option and follow the instructions below to verify your accredited status:
                  </div>

                  <div class="flex items-center justify-between w-full cursor-pointer mb-2 py-1 px-2 rounded hover:bg-gray-100 hover:shadow-sm dark:hover:bg-neutral-800 dark:hover:shadow-none"
                    @click="expandIndividualInstructions = !expandIndividualInstructions">
                    <span class="font-semibold text_color_primary">
                      Individual Investors
                    </span>
                    <PlusIcon v-if="!expandIndividualInstructions" class="w-5 h-5 dark:text-neutral-300" />
                    <MinusIcon v-else class="w-5 h-5 dark:text-neutral-300" />
                  </div>

                  <div v-if="expandIndividualInstructions" class="px-5">
                    <div class="flex flex-col gap-1 mb-1 text-sm md:text-base">
                      <div class="secondary_subheader">1. Income Verification:</div>
                      <div class="pl-5 text_color_secondary">
                        W2's, 1099's, or tax returns for the past two years to show your individual income exceeds $200,000 (or joint income exceeds $300,000) for each year.
                      </div>
                    </div>
                    <div class="flex flex-col gap-1 mb-1 text-sm md:text-base">
                      <div class="secondary_subheader">2. Asset Verification:</div>
                      <div class="pl-5 text_color_secondary">
                        Provide recent account statements or third-party appraisals that show the value of your assets exceed $1,000,000 excluding your primary residence.
                      </div>
                    </div>
                    <div class="flex flex-col gap-1 mb-1 text-sm md:text-base">
                      <div class="secondary_subheader">3. Professional Attestation:</div>
                      <div class="pl-5 text_color_secondary">
                        Provide an official written communication from any of the following stating that the professional service provider has a reasonable belief that you are an Accredited Investor
                        (Must be dated within the last 3 months): A licensed CPA, Attorney, Investment Advisor, or Registered broker-dealer.
                      </div>
                    </div>
                  </div>

                  <div class="flex items-center justify-between w-full cursor-pointer py-1 px-2 rounded hover:bg-gray-100 hover:shadow-sm dark:hover:bg-neutral-800 dark:hover:shadow-none"
                    @click="expandEntityInstructions = !expandEntityInstructions">
                    <span class="font-semibold text_color_primary">
                      Entity Investors
                    </span>
                    <PlusIcon v-if="!expandEntityInstructions" class="w-5 h-5 dark:text-neutral-300" />
                    <MinusIcon v-else class="w-5 h-5 dark:text-neutral-300" />
                  </div>

                  <div v-if="expandEntityInstructions" class="px-5">
                    <div class="flex flex-col gap-1 mb-1 text-sm md:text-base">
                      <div class="secondary_subheader">1. Balance Sheet Verification:</div>
                      <div class="pl-5 text_color_secondary">
                        Provide recent financial statements showing the entity's total assets exceed $5,000,000.
                      </div>
                    </div>
                    <div class="flex flex-col gap-1 mb-1 text-sm md:text-base">
                      <div class="secondary_subheader">2. Equity Owners Verification:</div>
                      <div class="pl-5 text_color_secondary">
                        If the entity is owned by equity owners, provide documentation that each of the equity owners is an accredited investor. This can include the documentation listed above for
                        individual investors.
                      </div>
                    </div>
                    <div class="flex flex-col gap-1 mb-1 text-sm md:text-base">
                      <div class="secondary_subheader">3. Professional Attestation:</div>
                      <div class="pl-5 text_color_secondary">
                        Provide an official written communication from any of the following professionals stating that the professional service provider has a
                        reasonable belief that the
                        entity is an Accredited Investor (Must be dated within the last 3 months): A licensed CPA, Attorney, Investment Advisor, or Registered broker-dealer.
                      </div>
                    </div>
                  </div>

                  <div class="sm:m-7 m-1 mt-5">
                    <DragDrop @changedFiles="updateDTObj" />
                  </div>
                </div>

                <!-- Spacer so we can see the full dropdown options -->
                <div v-else class="h-20"></div>

                <!-- Next buttons -->
                <div class="flex flex-row-reverse items-center justify-between">
                  <button type="submit" class="button_primary">
                    Next
                    <ArrowRightIcon class="w-4 h-4" />
                  </button>
                </div>
              </form>

              <!-- Attestation PDF -->
              <div v-if="page == 2" class="flex flex-col pt-4 sm:p-5 gap-7">
                <div class="p-5 rounded-xl bg-gray-100 dark:bg-neutral-700">
                  <div class="overflow-y-scroll h-80 text_color_secondary ">
                    <div>By signing below, the account owner states, acknowledges, and agrees as follows:</div>
                    <div>See in browser: <a class="text-blue-600" :href="attestationUrl" target="_blank">Account_Attestation.pdf</a></div>

                    <div class="nc_pdf_container mt-2">
                      <pdf :src="attestationUrl" :page="1" />
                      <pdf :src="attestationUrl" :page="2" />
                      <pdf :src="attestationUrl" :page="3" />
                      <pdf :src="attestationUrl" :page="4" />
                      <pdf :src="attestationUrl" :page="5" />
                      <pdf :src="attestationUrl" :page="6" />
                      <pdf :src="attestationUrl" :page="7" />
                      <pdf :src="attestationUrl" :page="8" />
                      <pdf :src="attestationUrl" :page="9" />
                      <pdf :src="attestationUrl" :page="10" />
                    </div>

                  </div>
                </div>

                <!-- Attestation Checkbox -->
                <div class="relative flex items-start">
                  <div class="flex items-center h-6">
                    <input v-model="hasAttested" id="comments" aria-describedby="comments-description" name="comments" type="checkbox"
                      class="w-4 h-4 border-gray-300 rounded text-operacolor focus:ring-operacolor" />
                  </div>
                  <div class="ml-3 text-sm leading-6">
                    <label for="comments" class="font-medium text_color_secondary">I have read these terms and conditions and accept them</label>
                  </div>
                </div>

                <div class="flex flex-row-reverse items-center justify-between">
                  <button @click="handleOpenAccount()" class="button_primary self-end" :disabled="!hasAttested">Open Account</button>
                  <div @click="showPrevious()" class="button_secondary">Previous</div>
                </div>
              </div>

              <div v-else-if="page == 3" class="flex flex-col items-center p-5 gap-7">
                <div>{{ loadingMsg }}</div>
                <LoadGif ref="loadGif" />
              </div>

              <!-- Content -->
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// Essentials
import { ref, onMounted, watch } from 'vue';
import { useUserStore } from '@/stores/user';
import { usePartiesStore } from '@/stores/parties';
import { useAccountsStore } from '@/stores/accounts';
// Components
import SelectMenu from '@/components/applicationui/SelectMenu.vue';
import InfoCircle from '@/components/ui/InfoCircle.vue'
import DragDrop from "@/components/widgets/DragDrop.vue";
import LoadGif from '@/components/loading/LoadGif.vue'
// Libraries
import pdf from 'pdfvuer';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { PlusIcon, MinusIcon } from '@heroicons/vue/20/solid'
import { ArrowRightIcon, XMarkIcon } from '@heroicons/vue/24/outline'
// Props
const props = defineProps({
  user: { type: Object, required: true, default: null },
  show: { type: Boolean, default: true }
})
// Emits
const emit = defineEmits(['closed', 'accountCreated']);
// Stores
const userStore = useUserStore();
const partiesStore = usePartiesStore();
const accountsStore = useAccountsStore();
// Globals
const open = ref(false);
const attestationUrl = 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/attestationVersions/accountAttestation_v3.pdf';
const page = ref(1);
const loadingMsg = ref('Please wait while we review your account.');
const noDocuments = ref(false);
const dt = ref(new DataTransfer());
const showSecondary = ref(false);
const isAccreditedInvestor = ref(false);
const expandIndividualInstructions = ref(false);
const expandEntityInstructions = ref(false);
const hasAttested = ref(false);
const name = ref('');
const primary = ref({});
const secondary = ref({});
const registrationOptions = ref([
  { id: 1, label: 'Individual', value: 'individual' },
  { id: 2, label: 'IRA', value: 'ira' },
  { id: 3, label: 'SEP IRA', value: 'sep ira' },
  { id: 4, label: 'ROTH IRA', value: 'roth ira' }
]);
const registration = ref({ id: 1, label: 'Individual', value: 'individual' });
// Mounted
onMounted(async () => {
  open.value = props.show;

  // Set the user as the default primary
  primary.value = props.user.parties;

  // Get the users parties
  if (!partiesStore.parties) await partiesStore.setParties();

  // If the users has more then one party, show additional account type options
  if (partiesStore.parties.length > 1) registrationOptions.value.push({ id: 5, label: 'Joint', value: 'joint' }, { id: 6, label: 'JTWROS', value: 'jtwros' }, { id: 7, label: 'Joint TIC', value: 'joint tic' });
});

// Functions
const handleOpenAccount = async () => {
  //First Check that the input values are all good.
  if (!hasAttested.value) return;
  page.value = 3;
  var newMsg = 'Your information is insufficent to create an account. Please go to the settings tab and complete your profile';
  if (!primary.value.address) { loadingMsg.value = newMsg; page.value = 1; return; } //TODO handle error
  else if (!primary.value.city) { loadingMsg.value = newMsg; page.value = 1; return; } //TODO handle error
  else if (!primary.value.state) { loadingMsg.value = newMsg; page.value = 1; return; } //TODO handle error
  else if (!primary.value.zip) { loadingMsg.value = newMsg; page.value = 1; return; } //TODO handle error
  else if (!primary.value.country) { loadingMsg.value = newMsg; page.value = 1; return; } //TODO handle error
  else if (secondary.value?.id) { if (primary.value.id == secondary.value?.id) { page.value = 1; return; } } //TODO handle error
  else if (isAccreditedInvestor.value && dt.value.files.length < 1) { page.value = 1; noDocuments.value = true; return; } //TODO handle error

  // Create account in TAPI
  const tapi_account_id = await accountsStore.createAccountInTapi(primary.value, registration.value.value, secondary.value?.id)
  
  // Change loading message
  loadingMsg.value = 'Connecting the Account';

  // Create account row in supabase
  const { id } = await accountsStore.createAccountInSupabase(tapi_account_id, userStore.user.profiles_protected.partner_id, primary.value.id, registration.value.value, name.value, secondary.value?.id)

  // Create primary link in TAPI
  await accountsStore.createLinkInTapi(tapi_account_id, primary.value.tapi_party_id, id)

  // Change loading message
  loadingMsg.value = 'Creating Custody Account';

  // RequestCustodialAccount (We don't need to wait for this to return right)
  accountsStore.requestCustodialAccount(id, tapi_account_id);

  //Send accredited documents if investor is accredited
  if (isAccreditedInvestor.value) {
    // Change loading message
    loadingMsg.value = 'Checking Accreditation';
    await uploadAccreditedDocs(id, tapi_account_id, userStore.user.id, dt.value);
  }

  //Add additional account info in BARC (We do not need to wait for this to return)
  accountsStore.createCustodyAccountBarc(tapi_account_id, name.value);

  //If this is a joint account link the second party
  if (registration.value.value == 'joint' || registration.value.value == 'jtwros' || registration.value.value == 'joint tic') {
    await accountsStore.createLinkInTapi(tapi_account_id, secondary.value?.tapi_party_id, account_id, 'owner', 0)
  } 

  //Close successfully
  successfulClose();
};

async function uploadAccreditedDocs(account_id, tapi_account_id, user_id, dt) {
  // Update AI Request Status
  await accountsStore.requestAiVerification(account_id, tapi_account_id)

  const promises = [];

  for (let newFile of dt.files) {
    // Push upload to tapi promise to array
    const promise = accountsStore.uploadAccreditationDocumentToTapi(account_id, tapi_account_id, newFile)
    promises.push(promise);
    // Push upload to supabase promise to array
    const promise2 = accountsStore.uploadAccreditationDocumentToSupabase(user_id, account_id, newFile)
    promises.push(promise2);
  }

  // Wait for all promises to resolve
  await Promise.all(promises);
}

// Simple Functions
const successfulClose = async () => {
  loadingMsg.value = 'Finished, Congratulations!';
  await new Promise(resolve => setTimeout(resolve, 1000));
  open.value = false;
  emit('accountCreated');
  loadingMsg.value = 'Please wait while we review your account.';
  page.value = 1;
  hasAttested.value = false;
};

const showNext = () => {
  page.value = 2;
};

const showPrevious = () => {
  page.value = 1;
};

const updateDTObj = (dtObj) => {
  dt.value = dtObj;
  if (dt.value.files.length > 0) {
    noDocuments.value = false;
  }
};

// Watchers
watch(open, async (value) => {
  if (!value) {
    await new Promise(resolve => setTimeout(resolve, 400));
    emit('closed');
  }
});

watch(registration, (value) => {
  if (value.value == 'joint' || value.value == 'jtwros' || value.value == 'joint tic') {
    secondary.value = partiesStore.parties[1];
    showSecondary.value = true;
  } else {
    showSecondary.value = false;
  }
});
</script>