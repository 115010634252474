import { defineStore } from 'pinia'
import { supabase } from '../lib/supabaseClient'
// import { ref } from 'vue'


export const useProfilesStore = defineStore('profiles', () => {
  // State (refs)

  // Getters, (computed properties)

  // Actions, (functions)
  async function getAllProfilesForPartner(partner_id) {
    const { data, error } = await supabase.from('profiles').select('*, profiles_protected(*), parties!party_id(*), parties_protected!party_id(*)').eq('profiles_protected.partner_id', partner_id).order('created_at', { ascending: false });
    if (error) { console.log(error); return }
    return data
  }
  async function getAllProfiles() {
    const { data, error } = await supabase.from('profiles').select('*, profiles_protected(*), parties!party_id(*), parties_protected!party_id(*)').order('created_at', { ascending: false });
    if (error) { console.log(error); return }
    return data
  }
  async function updateRole(id, role) {
    const { error } = await supabase.from('profiles_protected').update({ role: role }).eq('id', id);
    if (error) console.log(error)
  }
  async function updateProfileParty(id, party_id) {
    const { error } = await supabase.from('profiles').update({ party_id: party_id }).eq('id', id);
    if (error) console.log(error)
    return true
  }
  async function addEmailToPendingPartnerAdmins(email) {
    const { error } = await supabase.from('pending_partner_admins').insert({ email: email });
    if (error) { console.log(error); return }
    return true
  }

  // Not Callable Functions

  return {
    getAllProfilesForPartner,
    getAllProfiles,
    updateRole,
    updateProfileParty,
    addEmailToPendingPartnerAdmins
  }
})