<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <NotificationPopup :type="notificationType" :title="notificationTitle" :text="notificationText" ref="notification" />
    <!-- Popups -->

    <!-- Update Role -->
    <UpdateRole v-if="open" v-model="open" :user="selectedUser" :roles="roles" :currentRole="currentRole"
      @role-updated="notify('success', 'Role Updated', `User's role was updated successfully`); setUsers()" />

    <!-- Invite User -->
    <InviteUser v-if="openInvitePopup" v-model="openInvitePopup" :currentUserRole="userStore.user.profiles_protected.role" :roles="roles" :partners="partners"
      :currentUserPartner="partnerStore.partner" @failure="notify('failure', 'Sorry', 'There was an error sending the invitation, please try again. If the issue persists contact tech support')"
      @emails-dont-match="notify('failure', 'Emails must matchEmails do not match', 'Please make sure the emails match before sending the invitation')"
      @success="notify('success', 'Email Sent', 'The invitation email has been sent. The user will need to click the link in the email to complete the registration process')" />

    <!-- End Popups -->

    <!-- Loading -->
    <div v-if="loading" class="flex flex-grow items-center justify-center h-[70vh]">
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Ready -->
    <div v-else>

      <div>
        <!-- Empty State -->
        <div v-if="users && users.length <= 0">
          <div class="text-center">
            <UserCircleIcon class="w-12 h-12 mx-auto text-gray-400 dark:text-neutral-400" />
            <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300">No Users</h3>
            <p class="mt-1 text-sm text-gray-500 dark:text-neutral-400">You have no users yet.<br /> Once a user has signed up you will see their information here.</p>
          </div>
        </div>

        <!-- Has Accounts -->
        <div v-else>
          <!-- Header -->
          <div>
            <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 flex-wrap dark:border-neutral-700">
              <!-- Tab Header -->
              <h3 class="primary_header">Users</h3>

              <div class="flex items-center gap-4">
                <!-- Search -->
                <div class="flex items-center gap-2">
                  <div>
                    <div class="relative rounded-md">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <MagnifyingGlassIcon class="w-4 h-4 text-gray-400 dark:text-neutral-400" aria-hidden="true" />
                      </div>
                      <input type="text" name="search" id="search" class="pl-8 input" placeholder="Quick Search" v-model="query" />
                    </div>
                  </div>
                </div>
                <!-- Invite User -->
                <button @click="openInvitePopup = true" type="button" class="button_primary">
                  Invite user
                  <EnvelopeIcon class="w-4 h-4" />
                </button>
              </div>
            </div>
            <div class="flex">
              <SwitchGroup as="div" class="flex items-center justify-between w-fit">
                <Switch v-model="isTable"
                  class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-operacolor focus:ring-offset-2 dark:focus:outline-none dark:focus:ring-0 dark:focus:ring-operacolor dark:focus:ring-offset-0">
                  <span class="sr-only">Use setting</span>
                  <span aria-hidden="true" class="pointer-events-none absolute h-full w-full rounded-md bg-gray-50 dark:bg-neutral-950" />
                  <span aria-hidden="true"
                    :class="[isTable ? 'bg-operacolor' : 'bg-gray-200 dark:bg-neutral-700', 'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out']" />
                  <span aria-hidden="true"
                    :class="[isTable ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out']" />
                </Switch>
                <SwitchLabel as="span" class="ml-3 text-sm">
                  <span class="font-medium text_color_primary">Table View</span>
                </SwitchLabel>
              </SwitchGroup>
            </div>
          </div>

          <!-- Users Table -->
          <TablePrimary v-if="isTable" :rows="filteredUsers" :columns="columns" :isClickable="true" @click-primary="handleUserClick" class="-mt-5" export_title="users" />

          <!-- User Cards -->
          <div v-else role="list" class="flex gap-6 flex-wrap mt-4">
            <div v-for="u in filteredUsers" :key="u.id" @click="handleUserClick(u)"
              class="divide-y divide-gray-200 rounded-lg bg-white shadow-opera cursor-pointer border w-80 border-white hover:border-gray-300 hover:border dark:bg-neutral-800 dark:border-neutral-800 dark:hover:border-neutral-600">
              <UserCard :user="u" />
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script setup>
// Essentials
import { ref, computed, onMounted } from 'vue';
import { useUserStore } from '@/stores/user';
import { usePartnerStore } from '@/stores/partner';
import { useProfilesStore } from '@/stores/profiles';
// Components
import SpinnerFlip from '@/components/loading/SpinnerFlip.vue';
import NotificationPopup from '@/components/popups/NotificationPopup.vue'
import TablePrimary from '@/components/widgets/TablePrimary.vue'
import UserCard from '@/components/cards/UserCard.vue'
import UpdateRole from '@/components/popups/UpdateRole.vue'
import InviteUser from '@/components/popups/InviteUser.vue'
// Libraries
import { UserCircleIcon, MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
import { EnvelopeIcon } from '@heroicons/vue/24/solid';
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
// Stores
const userStore = useUserStore();
const partnerStore = usePartnerStore();
const profilesStore = useProfilesStore();
// Globals
const isTable = ref(true);
if (window.innerWidth < 1024) isTable.value = false
const loading = ref(true);
const users = ref([]);
const selectedUser = ref({});
const query = ref('');
const roles = [
  { id: 1, label: 'Investor', value: 'investor' },
  { id: 3, label: 'Partner Admin', value: 'partner_admin' }
];
const currentRole = ref({ id: 2, label: 'Investor', value: 'investor' });
const open = ref(false);
const openInvitePopup = ref(false);
const newUser = ref({ role: 'investor', email: '', verify_email: '' });
const notification = ref(null);
const notificationType = ref('success');
const notificationTitle = ref('Success');
const notificationText = ref('');
const partners = ref([]);
const columns = [
  { key: 'email', label: 'Email', type: 'text' },
  { key: 'parties.first_name', label: 'Name', key2: 'parties.last_name', type: 'double' },
  { key: 'profiles_protected.role', label: 'Role', type: 'badge' },
  { key: 'parties.phone', label: 'Phone', type: 'text' },
  { key: 'created_at', label: 'Date Created', type: 'date', sorter: true }
];

// Mounted
onMounted(async () => {
  // Get Users
  await setUsers();
  // Setup
  newUser.value.partner = partnerStore.partner
  if (partnerStore.partner.allow_advisors) roles.push({ id: 3, label: 'Advisor', value: 'advisor' })
  if (userStore.user.profiles_protected.role == 'super_admin') await setPartners()
  // Ready
  loading.value = false;
});

// Computed
const filteredUsers = computed(() => {
  return query.value === '' ? users.value :
    users.value.filter((user) => {
      if (user?.parties?.first_name) if (user?.parties?.first_name?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (user?.parties?.last_name) if (user?.parties?.last_name?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (user?.email) if (user?.email?.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (user?.parties?.phone) if (user?.parties?.phone?.includes(query.value)) return true;
      if (user?.profiles_protected.role) if (user?.profiles_protected?.role?.toLowerCase()?.includes(query.value.toLowerCase())) return true;
    });
});

// Functions
async function setUsers() {
  if (userStore.user.profiles_protected.role == 'super_admin') users.value = await profilesStore.getAllProfiles()
  if (userStore.user.profiles_protected.role == 'partner_admin') users.value = await profilesStore.getAllProfilesForPartner(partnerStore.partner.id)
};

async function setPartners() {
  partners.value = await partnerStore.getAllPartners()
  // Add a label to each partner that is equal to the partner's title (For SelectMenu in InviteUser)
  partners.value.forEach(partner => { partner.label = partner.title });
}

// Simple Functions
const handleUserClick = (user) => {
  selectedUser.value = user;
  // Set Current Role
  if (user.profiles_protected.role == 'super_admin') currentRole.value = { id: 3, label: 'Partner Admin', value: 'partner_admin' }
  else if (user.profiles_protected.role == 'partner_admin') currentRole.value = { id: 3, label: 'Partner Admin', value: 'partner_admin' }
  else if (user.profiles_protected.role == 'advisor') currentRole.value = { id: 2, label: 'Advisor', value: 'advisor' }
  else if (user.profiles_protected.role == 'investor') currentRole.value = { id: 1, label: 'Investor', value: 'investor' }

  open.value = true;
};

const notify = (type, title, text) => {
  notificationType.value = type
  notificationTitle.value = title
  notificationText.value = text
  notification.value.show()
}
</script>
