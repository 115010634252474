<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl dark:bg-neutral-900 sm:my-8 sm:p-6">
              <!-- Content -->
              <XMarkIcon class="float-right w-5 h-5 cursor-pointer" @click="open = false" />
              <div id="hubspot_form_container"></div>
              <!-- Content -->
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// Essentials
import { ref, onMounted, watch } from 'vue'
// Libraries
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
// Props
const props = defineProps({
  show: { type: Boolean, required: true, default: false }
})
// Globals
const open = ref(false)
// Mounted
onMounted(async () => {
  open.value = props.show
  loadHubSpotFormScript();
})
// Functions
function loadHubSpotFormScript() {
  if (window.hbspt) {
    const hubform = window.hbspt.forms.create({
      region: "na1",
      portalId: "4042879",
      formId: "8254108a-13ba-4e6e-91bd-941fb31fcb87",
      target: "#hubspot_form_container",
    });
    hubform.onFormSubmit(() => {
      setTimeout(() => {
        emit('submitted');
      }, 1000); // Delay emission for 1 second (adjust as needed)
    });
  }
}
// Watchers
watch(open, async () => { 
  if(!open.value) { await new Promise(resolve => setTimeout(resolve, 400)); emit('closed') }
});
// Emits
const emit = defineEmits(['closed', 'submitted'])
// Expose
defineExpose({
  loadHubSpotFormScript
});
</script>