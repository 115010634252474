<template>
  <div class="flex justify-between space-x-6 p-6">
    <div class="flex-1 truncate flex flex-col gap-3">
      <div class="flex items-center">
        <h3 class="truncate secondary_header">{{ props.investor.first_name + ' ' + props.investor.last_name }}</h3>
      </div>
      <div class="flex gap-4">
        <div class="flex items-center gap-1">
          <div class="input_label !text-xs">KYC:</div>
          <span :class="getBadgeClass(props.investor.parties_protected.kyc)">{{ capitalizeFirstLetter(props.investor.parties_protected.kyc) }}</span>
        </div>
        <div class="flex items-center gap-1">
          <div class="input_label !text-xs">AML:</div>
          <span :class="getBadgeClass(props.investor.parties_protected.kyc)">{{ capitalizeFirstLetter(props.investor.parties_protected.kyc) }}</span>
        </div>
      </div>

      <p class="mt-1 truncate text-sm text-gray-500 dark:text-neutral-400">{{ props.investor.contact_email }}</p>
    </div>

    <div v-if="props.role == 'super_admin'">
      <PartnerLogo :partner="props.investor?.partners" size="logo-only" />
    </div>
  </div>
</template>

<script setup>
// Components
import PartnerLogo from '@/components/widgets/PartnerLogo.vue'
// Libraries
import { getBadgeClass, capitalizeFirstLetter } from '@/helper/helper';
// Props
const props = defineProps({
  investor: { type: Object, required: true, default: null },
  role: { type: String, required: false, default: 'investor' }
});
</script>