import { defineStore } from 'pinia'
import { supabase } from '../lib/supabaseClient'
import * as helper from '@/helper/helper';
import { logErrorMessage } from '@/helper/helper'
// import { ref, computed } from 'vue'


export const useOfferingsStore = defineStore('offerings', () => {
  // State (refs)

  // Getters, (computed properties)

  // Actions, (functions)
  async function getAllOfferings() {
    const { data, error } = await supabase.from('offerings').select('*').eq('archived', false).order('created_at', { ascending: false });
    if (error) { console.log(error); return }
    return data
  };
  async function getAllOfferingsForPartner(id) {
    const { data, error } = await supabase.from('offerings').select('*').eq('partner_id', id).eq('archived', false).order('created_at', { ascending: false });
    if (error) { console.log(error); return }
    return data
  };
  async function getOfferingById(id) {
    const { data, error } = await supabase.from('offerings').select('*').eq('id', id).limit(1);
    if (error) { console.log(error); return }
    return data[0]
  }
  async function getAllApprovedOfferingsForPartner(partner_id) {
    const { data, error } = await supabase.from('offerings').select('*').eq('partner_id', partner_id).eq('status', 'approved').eq('archived', false).order('created_at', { ascending: false });
    if (error) { console.log(error); return }
    return data
  };
  async function createOffering(offering) {
    // Format the new offering object
    delete offering.logoUrl
    delete offering.bannerUrl
    delete offering.logoImage
    delete offering.bannerImage
    offering.target_amount = helper.checkTargetAmount(helper.removeCommas(offering.target_amount))
    offering.minimum = helper.checkMinAmount(helper.removeCommas(offering.minimum))
    offering.maximum = helper.checkMaxAmount(helper.removeCommas(offering.maximum))
    offering.unit_price = helper.checkUnitPrice(helper.removeCommas(offering.unit_price))
    //TODO Clean this up??
    const { data, error } = await supabase.from('offerings').insert(offering).select('*');
    if (error) { console.log(error); return }
    return data[0]
  }
  async function updateOfferingTapiId(id, tapi_offering_id) {
    const { error } = await supabase.from('offerings').update({ tapi_offering_id: tapi_offering_id }).eq('id', id);
    if (error) { console.log(error); return }
    return true
  }
  async function updateOffering(offering) {
    // Get id
    const id = offering.id
    // Format the new offering object
    delete offering.id
    delete offering.logoUrl
    delete offering.bannerUrl
    delete offering.logoImage
    delete offering.bannerImage
    delete offering.tapi_offering_id

    offering.target_amount = helper.checkTargetAmount(helper.removeCommas(offering.target_amount))
    offering.minimum = helper.checkMinAmount(helper.removeCommas(offering.minimum))
    offering.maximum = helper.checkMaxAmount(helper.removeCommas(offering.maximum))
    offering.unit_price = helper.checkUnitPrice(helper.removeCommas(offering.unit_price))
    offering.start_date = helper.checkDate(offering.start_date)
    offering.end_date = helper.checkDate(offering.end_date)
    // Update the offering
    console.log('about to update offering', offering)
    const { error } = await supabase.from('offerings').update(offering).eq('id', id);
    if (error) { console.log(error); return }
    return true
  }
  async function createOfferingInTapi(offering) { // Expect and offering object
    let body = {
      issueName: offering.name,
      issueType: offering.offering_type,
      targetAmount: helper.checkTargetAmount(helper.removeCommas(offering.target_amount)),
      minAmount: helper.checkMinAmount(helper.removeCommas(offering.minimum)),
      maxAmount: helper.checkMaxAmount(helper.removeCommas(offering.maximum)),
      unitPrice: helper.checkUnitPrice(helper.removeCommas(offering.unit_price)),
      startDate: helper.formatStartDate(offering.start_date),
      endDate: helper.formatEndDate(offering.end_date),
      offeringText: offering.slogan,
      stampingText: '.',
      field1: 'Opera'
    };
    const { data, error } = await supabase.functions.invoke('create-offering', { body: body });
    if (error) { console.log(await logErrorMessage(error)); return }
    return data.offeringDetails[1][0].offeringId //Return the offering ID
  }
  async function updateOfferingToApproved(offering) { // Expect and offering object
    let body = {
      offeringId: offering.tapi_offering_id,
      issueName: offering.name,
      issueType: offering.offering_type,
      targetAmount: helper.checkTargetAmount(helper.removeCommas(offering.target_amount)),
      minAmount: helper.checkMinAmount(helper.removeCommas(offering.minimum)),
      maxAmount: helper.checkMaxAmount(helper.removeCommas(offering.maximum)),
      unitPrice: helper.checkUnitPrice(helper.removeCommas(offering.unit_price)),
      startDate: helper.formatStartDate(offering.start_date),
      endDate: helper.formatEndDate(offering.end_date),
      offeringText: offering.slogan,
      stampingText: '.',
      field1: 'Opera',

    };
    const { error } = await supabase.functions.invoke('update-offering', { body: body });
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function uploadImagesToSupabase(id, logoImage, bannerImage) {
    if (logoImage) {
      const { error } = await supabase.storage.from('offerings').upload(`${id}/logo`, logoImage, { cacheControl: '3600', upsert: true })
      if (error) { console.log(await logErrorMessage(error)); return }
    }
    if (bannerImage) {
      const { error } = await supabase.storage.from('offerings').upload(`${id}/banner`, bannerImage, { cacheControl: '3600', upsert: true })
      if (error) { console.log(await logErrorMessage(error)); return }
    }
    return true
  };
  async function uploadOfferingImagesToSupabase(offering_id, name, image) {
    if (!offering_id) { console.log('No Offering Id provided'); return }
    if (!name) { console.log('No image name provided'); return }
    if (!image) { return } // This is expected to be empty when the file has already been uploaded

    // Upload the image to the offerings folder
    const { error } = await supabase.storage.from('offerings').upload(`${offering_id}/${name}`, image, { cacheControl: '3600', upsert: true })
    if (error) { console.log(await logErrorMessage(error)); return }

    return true
  };
  async function uploadOfferingDocumentSupabase(offering_id, file) {
    if (!offering_id) { console.log('No Offering Id provided'); return }
    if (!file) { return }

    // Upload the image to the offering documents folder
    const { error } = await supabase.storage.from('offerings').upload(`${offering_id}/documents/${file.name}`, file, { cacheControl: '3600', upsert: true })
    if (error) { console.log(await logErrorMessage(error)); return }

    return true
  }
  async function archiveOffering(id) {
    const { error } = await supabase.from('offerings').update({ archived: true }).eq('id', id);
    if (error) { console.log(error); return }
    return true
  };
  async function deleteOfferingDocument(offering_id, file_name) {
    const { error } = await supabase.storage.from('offerings').remove([`${offering_id}/documents/${file_name}`])
    if (error) { console.log(await logErrorMessage(error)); return }
    return true
  }
  async function getOfferingFile(id, tapi_id, file) {
    // Check for image in new folder
    const url = await checkForFile(id, file)
    if (url) return url
    // Check for image in old folder
    const url2 = await checkForFile(tapi_id, file)
    if (url2) return url2
  }
  async function getOfferingDetails(tapi_offering_id) {
    const { data, error } = await supabase.functions.invoke('get-offering-details', { body: { 'offeringId': tapi_offering_id } })
    if (error) { console.log(await logErrorMessage(error)); return }
    return data['Offering purchased details'][0]
  }
  async function getOfferingDocuments(tapi_offering_id) {
    const { data, error } = await supabase.functions.invoke('get-offering-documents', { body: { 'offeringId': tapi_offering_id } })
    if (error) { console.log(await logErrorMessage(error)); return }
    return data?.document_details ?? []
  }
  async function getOfferingDocumentsSupabase(offering_id) {
    const { data, error } = await supabase.storage.from('offerings').list(`${offering_id}/documents`, { limit: 100, offset: 0, sortBy: { column: 'name', order: 'asc' } })
    if (error) { console.log(await logErrorMessage(error)); return }
    return data
  }

  // Not Callable Functions
  async function checkForFile(folder, fileName) {
    const { data, error } = await supabase.storage.from('offerings').list(folder, { limit: 100, offset: 0, sortBy: { column: 'name', order: 'asc' } })
    if (error) {
      console.error(error)
      return
    }
    const hasFile = data.find((file) => file.name === fileName)
    if (hasFile) {
      const response = supabase.storage.from('offerings').getPublicUrl(`${folder}/${fileName}`)
      return response.data.publicUrl
    }
  }
  function getPublicFileUrl(offering_id, fileName) {
    const { data, error } = supabase.storage.from('offerings').getPublicUrl(`${offering_id}/${fileName}`)
    if (error) { console.log(error); return }
    return data.publicUrl
  }

  return {
    getAllOfferings,
    getAllOfferingsForPartner,
    getOfferingById,
    createOffering,
    createOfferingInTapi,
    updateOfferingToApproved,
    updateOfferingTapiId,
    uploadImagesToSupabase,
    uploadOfferingImagesToSupabase,
    uploadOfferingDocumentSupabase,
    updateOffering,
    archiveOffering,
    deleteOfferingDocument,
    getOfferingFile,
    getAllApprovedOfferingsForPartner,
    getOfferingDetails,
    getOfferingDocuments,
    getOfferingDocumentsSupabase,
    getPublicFileUrl
  }
})