<template>
  <div> <!-- Leave this outer div empty -->
    <div class="flex flex-col w-fit max-w-[1000px]">
      <img class="w-full" :src="image" />
      <div class="flex flex-wrap items-end justify-between mt-10 gap-y-5">
        <div class="text-3xl font-bold">What is a Money Market Mutual Fund?</div>
        <div class="text-sm">by: North Capital</div>
      </div>

      <div class="flex flex-col gap-5 my-10 w-full max-w-[1000px]">
        <!-- Paragraph 1 -->
        <div>
          Money market funds are a specific type of mutual fund designed for investing short-term cash (funds that you will need access to within two years). The underlying investments in these funds
          are short-term debt issued by the U.S. Government, federal agencies, banks, and prime corporations. There are strict limits on the types and duration of investments these funds may hold,
          because the funds are designed to maintain par value. U.S. Government and Treasury money market funds are more restrictive still, limiting their credit risk to investments backed by the full
          faith and credit of the United States. Of all mutual funds, U.S. Government money market funds tend to have the lowest potential risk of loss, as measured by credit risk and duration exposure.
        </div>
        <!-- Paragraph 2 -->
        <div class="text-xl font-semibold">Potential Advantages of Money Market Funds</div>
        <div>
          Given the current market conditions, money market funds have become an attractive option for businesses and individuals looking to allocate short-term cash. Since bank deposit rates have not
          kept up with the rise in short-term rates on TBills and other money market securities, Government money market funds are more attractive now than they have been in over a decade.
          <ul class="pl-8 list-disc">
            <li>Most Government money funds offer next day subscription and redemptions (T + 1) with some providing same-day subscriptions and redemptions.</li>
            <li>The funds are designed to maintain par value of $1.00 per share.</li>
            <li>99.5% of the fund must be invested in cash, securities issued or guaranteed by the United States, U.S. government agencies, or repurchase agreements that are fully collateralized (i.e.,
              backed by cash or government securities).</li>
            <li>There are duration and maturity limits that aim to protect the funds from interest rate volatility.</li>
            <li>Risk limits, reporting, and testing requirements for money market funds are stringent and rigorously enforced.</li>
          </ul>
        </div>
        <!-- Paragraph 3 -->
        <div class="text-xl font-semibold">Possible Disadvantages of Money Market Funds</div>
        <div>
          Although money market funds can offer a relatively secure place to save short-term cash, investments in these funds are not insured or guaranteed by the Federal Deposit Insurance Corporation
          (FDIC) or any other government agency. Investors should carefully consider the fund's investment objectives, risks, charges and expenses before investing.
        </div>
        <!-- Paragraph 4 -->
        <div class="text-xl font-semibold">How to Invest in Money Market Funds</div>
        <div>
          With minimal effort, you can take advantage of the higher interest rates with the risks commensurate with money market products. Money market funds are available through most broker-dealers
          and directly through many fund managers. If you would like to learn more about money market funds or liquidity-focused separately managed accounts, please contact us at North Capital
        </div>
        <!-- Paragraph 5 -->
        <div class="text-xl font-semibold">Disclosure</div>
        <div class="italic">
          You could lose money by investing in a money market fund. Although the fund seeks to preserve the value of your investment at $1.00 per share, it cannot guarantee it will do so. An investment
          in the fund is not insured or guaranteed by the Federal Deposit Insurance Corporation or any other government agency. Investors should carefully consider the fund's investment objectives,
          risks, charges and expenses before investing.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { supabase } from '@/lib/supabaseClient'
export default {
  data() {
    return {
      image: 'https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/articles/moneyMarketMutualFund/moneyMarketMutualFund'
    }
  }
}
</script>