<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white dark:bg-neutral-900 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 flex flex-col justify-between">
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button type="button" class="rounded-md text-gray-400 dark:text-neutral-300 hover:text-gray-500 hover:dark:text-neutral-400 outline-none" @click="open = false">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div class="sm:flex sm:items-start">
                <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 dark:bg-blue-950 sm:mx-0 sm:h-10 sm:w-10">
                  <UserGroupIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <!-- Title -->
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900 dark:text-neutral-100">Set Account Group</DialogTitle>

                  <!-- Subtitle -->
                  <div class="mt-3 text-sm text-gray-600 dark:text-neutral-300">Select an existing group or create a new group by entering a new group name and then selecting it.</div>

                  <!-- Content -->
                  <div class="my-10 flex flex-col gap-8">

                    <!-- Select Group -->
                    <div v-if="groups.length > 0" class="w-52">
                      <div class="select_menu_label">Choose Account Group</div>
                      <SelectMenu v-model="selectedGroup" :items="groups" />
                    </div>

                    <!-- Create Group -->
                    <form @submit.prevent="handleNewGroup(newGroup)" class="flex gap-5 w-full items-end flex-wrap">

                      <!-- New Group Input -->
                      <div class="w-52">
                        <label for="newGroup" class="input_label">New Group Name</label>
                        <div class="input_wrapper">
                          <input v-model="newGroup" type="text" name="newGroup" id="newGroup" class="input" placeholder="Add new group" required />
                        </div>
                      </div>

                      <!-- New Group button -->
                      <button type="submit" class="button_primary h-10">Create Group</button>
                    </form>

                  </div>

                </div>
              </div>
              <div class="mt-10 sm:flex sm:flex-row-reverse gap-4">
                <button type="button" class="button_primary" @click="addGroup">Add Group</button>
                <button type="button" class="button_secondary" @click="open = false">Cancel</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// Essentials
import { ref, onMounted } from 'vue'
import { useAccountsStore } from '@/stores/accounts'
// Components
import SelectMenu from '@/components/applicationui/SelectMenu.vue'
// Libraries
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { UserGroupIcon, XMarkIcon } from '@heroicons/vue/24/outline'
// Props
const props = defineProps({
  user_id: { type: String, required: true, default: '' },
})
// Emits
const emit = defineEmits(['update-group'])
// Stores
const accountsStore = useAccountsStore()
// Globals
const open = ref(false)
const newGroup = ref('')
const selectedGroup = ref(null)
const groups = ref([])
const account = ref(null)
// Mounted
onMounted(async () => {
  await setGroups(props.user_id)
})

// Functions
async function setGroups(user_id) {
  const response = await accountsStore.getExistingAccountGroups(user_id)
  if (!response) { groups.value = []; return }

  groups.value = response
  selectedGroup.value = response[0]
}

const addGroup = () => {
  emit('update-group', { group: selectedGroup.value, selectedAccount: account.value })
  open.value = false
}

// Simple Functions
const handleNewGroup = (group) => {
  // Check if group is empty
  if (!group) return

  // Check if group already exists, if so set as selected
  if (groups.value.includes(group)) {
    selectedGroup.value = group
    return
  }

  // If group does not exist, add to groups and set as selected
  groups.value.push(group)
  selectedGroup.value = group
}

// Exposed Functions
const show = (selectedAccount) => {
  open.value = true
  account.value = selectedAccount
}
const hide = () => {
  open.value = false
}

// Expose
defineExpose({
  show,
  hide
});
</script>