<template>
  <div class="flex flex-1 h-screen min-h-full bg-white dark:bg-neutral-950">
    <!-- Popups -->
    <NotificationPopup ref="notification" :type="notificationType" :title="notificationTitle" :text="notificationText" />
    <!-- Popups -->

    <!-- Loading -->
    <div v-if="loading" class="flex items-center justify-center w-screen h-screen">
      <LoadGif />
    </div>

    <!-- Content -->
    <div v-else class="flex flex-1 w-full h-full">

      <div v-if="!showOtp" class="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">

        <div class="w-full max-w-sm mx-auto lg:w-96">
          <div>
            <!-- Partner Logo -->
            <PartnerLogo :partner="partnerStore.partner" size="large" />
            <!-- Sing in Header -->
            <h2 class="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white" v-html="titleText"></h2>
            <p v-if="showToggle" class="mt-2 text-sm leading-6 text-gray-500 dark:text-neutral-400">
              {{ `${toggleText} ` }}
              <a @click="toggleSignInOrLogin(showLoginView)" class="font-semibold cursor-pointer text-operacolor hover:text-operacolorlight">{{ toggleLink }}</a>
            </p>
          </div>

          <div class="mt-10">
            <div>
              <form @submit.prevent="signInWithEmail" class="space-y-6">
                <div>
                  <label for="email" class="block text-sm font-medium leading-6 text-gray-900 dark:text-neutral-100">Email address</label>
                  <div class="mt-2">
                    <input v-model="email" id="email" name="email" type="email" autocomplete="email" required="" class="input" />
                  </div>
                </div>

                <div class="flex items-center justify-between">
                  <div v-if="showLoginView" class="flex items-center">
                    <input id="remember-me" name="remember-me" type="checkbox" class="w-4 h-4 border-gray-300 rounded text-operacolor focus:ring-operacolor" />
                    <label for="remember-me" class="block ml-3 text-sm leading-6 text-gray-700 dark:text-neutral-300">Remember me</label>
                  </div>
                  <div v-else>
                    <div class="flex items-center">
                      <input name="terms" id="terms" v-model="terms" type="checkbox" class="w-4 h-4 border-gray-300 rounded text-operacolor focus:ring-operacolor" />
                      <label for="terms" class="block ml-3 text-sm leading-6 text-gray-700 dark:text-neutral-300">I agree to the <a class="text-operacolor" target="_blank" :href="termsOfUse">Terms and
                          Conditions</a> </label>
                    </div>
                    <div class="flex items-center">
                      <input name="privacy" id="privacy" v-model="privacy" type="checkbox" class="w-4 h-4 border-gray-300 rounded text-operacolor focus:ring-operacolor" />
                      <label for="privacy" class="block ml-3 text-sm leading-6 text-gray-700 dark:text-neutral-300">I agree to the <a class="text-operacolor" target="_blank"
                          href="https://app.hubspot.com/documents/4042879/view/473022400?accessId=2a9568">Privacy Policy</a></label>
                    </div>
                  </div>
                </div>

                <div>
                  <button type="submit" class="flex items-center gap-2.5" :class="isEnabled">
                    {{ buttonText }}
                    <LoadGifButton v-if="showButtonSpinner" />
                  </button>
                </div>
              </form>
            </div>

            <!-- <div class="mt-10">
              <div class="relative">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="w-full border-t border-border" />
                </div>
                <div class="relative flex justify-center text-sm font-medium leading-6">
                  <span class="px-6 text-gray-900 bg-white dark:bg-neutral-950 dark:text-white">Or continue with</span>
                </div>
              </div>

              <div class="grid grid-cols-2 gap-4 mt-6">

                <a @click="signInWithGoogle()"
                  class="cursor-pointer inline-flex items-center gap-x-1.5 justify-center rounded-md bg-white px-2.5 py-1.5 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  <img class="w-5 h-5" src="@/assets/icons/google.svg">
                  <span class="text-sm leading-6">Google</span>
                </a>

                <div @click="signInWithApple()"
                  class="cursor-pointer flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] hover:bg-[#393838] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]">
                  <img class="icon" src="@/assets/icons/apple.svg">
                  <span class="text-sm leading-6">Apple</span>
                </div>
              </div>
            </div> -->

          </div>
        </div>
      </div>

      <!-- OTP -->
      <div v-else class="absolute w-screen h-screen bg-transparent antialiased">

        <main class="relative h-full flex flex-col justify-center overflow-hidden z-10">
          <div class="w-full max-w-6xl mx-auto px-4 md:px-6 py-24">
            <div class="flex justify-center">

              <div class="max-w-lg mx-auto text-center bg-white px-4 sm:px-10 py-12 rounded-xl shadow">
                <header class="mb-10">
                  <h1 class="text-2xl font-bold mb-3 text-gray-900">One Time Passcode</h1>
                  <p class="text-[15px] text-gray-500">Enter the 6-digit verification code that was sent to <br /> <span class="text-gray-800">{{ email ?? 'No Email Enter' }}</span></p>
                  <div class="text-sm text-gray-500 mt-2">Back to sign in page? <a @click="showOtp = false" class="font-medium text-operacolor hover:text-operacolor cursor-pointer">Sign in</a></div>
                </header>
                <form id="otp-form" @submit.prevent="handleOtpSubmit">
                  <div class="flex items-center justify-center gap-2">
                    <input type="text" v-model="otp_1" @input="handleInput(1)" @paste="handlePaste"
                      class="w-14 h-14 text-center text-2xl shadow font-extrabold text-gray-900 bg-gray-100 border border-transparent hover:border-gray-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-operacolor focus:ring-2 focus:ring-operacolorlight"
                      pattern="\d*" maxlength="1" />
                    <input type="text" v-model="otp_2" @input="handleInput(2)"
                      class="w-14 h-14 text-center text-2xl shadow font-extrabold text-gray-900 bg-gray-100 border border-transparent hover:border-gray-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-operacolor focus:ring-2 focus:ring-operacolorlight"
                      maxlength="1" />
                    <input type="text" v-model="otp_3" @input="handleInput(3)"
                      class="w-14 h-14 text-center text-2xl shadow font-extrabold text-gray-900 bg-gray-100 border border-transparent hover:border-gray-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-operacolor focus:ring-2 focus:ring-operacolorlight"
                      maxlength="1" />
                    <input type="text" v-model="otp_4" @input="handleInput(4)"
                      class="w-14 h-14 text-center text-2xl shadow font-extrabold text-gray-900 bg-gray-100 border border-transparent hover:border-gray-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-operacolor focus:ring-2 focus:ring-operacolorlight"
                      maxlength="1" />
                    <input type="text" v-model="otp_5" @input="handleInput(5)"
                      class="w-14 h-14 text-center text-2xl shadow font-extrabold text-gray-900 bg-gray-100 border border-transparent hover:border-gray-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-operacolor focus:ring-2 focus:ring-operacolorlight"
                      maxlength="1" />
                    <input type="text" v-model="otp_6" @input="handleInput(6)"
                      class="w-14 h-14 text-center text-2xl shadow font-extrabold text-gray-900 bg-gray-100 border border-transparent hover:border-gray-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-operacolor focus:ring-2 focus:ring-operacolorlight"
                      maxlength="1" />
                  </div>
                  <div class="max-w-[420px] mt-10">
                    <button type="submit" class="button_primary w-full justify-center">
                      Verify Email
                    </button>
                  </div>
                </form>
                <div class="text-sm text-gray-500 mt-8">Didn't receive code? <a @click="signInWithEmail" class="font-medium text-operacolor hover:text-operacolor cursor-pointer">Resend</a></div>
              </div>

            </div>
          </div>
        </main>

      </div>

      <!-- Background Image -->
      <div class="relative flex-1 hidden w-0 lg:block">
        <img class="absolute inset-0 object-cover w-full h-full bg-gray-200" :src="partnerStore.getBackground" alt="Background Image" />
      </div>

    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, watch, onMounted } from 'vue';
import { supabase } from '@/lib/supabaseClient';
import { useRouter } from 'vue-router';
import { usePartnerStore } from '@/stores/partner';
// Components
import PartnerLogo from '@/components/widgets/PartnerLogo.vue';
import LoadGifButton from '@/components/loading/LoadGifButton.vue'
import LoadGif from '@/components/loading/LoadGif.vue';
import NotificationPopup from '@/components/popups/NotificationPopup.vue'
// Libraries
// Stores
const partnerStore = usePartnerStore();
// Globals
const router = useRouter();
const loading = ref(true);
const isEnabled = ref('disabled');
const email = ref('');
const showButtonSpinner = ref(false);
const showOtp = ref(false);

const notification = ref(null);
const notificationType = ref('success');
const notificationTitle = ref('Email Sent');
const notificationText = ref("Please check your inbox. If you don't see the email, please check your spam inbox.");

const showToggle = ref(true)
const showLoginView = ref(true);
const titleText = ref('Sign in to your account');
const toggleText = ref("Don't have an account?");
const toggleLink = ref('Sign up');
const buttonText = ref('Sign in');

const termsOfUse = ref('https://gaosehoviibwiqrqqewu.supabase.co/storage/v1/object/public/opera_public/termsOfUse/termsofuse.pdf');
const privacy = ref(false);
const terms = ref(false);

// Mounted
onMounted(async () => {
  // Get partner meta name from url parameter
  const meta_name = router.currentRoute.value.query.partner ?? 'opera'
  // Set partner
  await partnerStore.setPartnerByMetaName(meta_name)
  // Get user theme
  if (localStorage.getItem('theme') == 'dark') document.documentElement.classList.add('dark')
  else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) document.documentElement.classList.add('dark')

  // One Time Passcode Setup
  if (partnerStore.partner.partners_protected.allowed_signin_methods && partnerStore.partner.partners_protected.allowed_signin_methods.includes('otp')) {
    buttonText.value = 'Verify Email';
    showToggle.value = false;
  }

  loading.value = false;
});

// Functions
async function signInWithEmail() {
  if (!email.value) {
    showOtp.value = false;
    showButtonSpinner.value = false;
    return
  }
  showButtonSpinner.value = true;
  const options = {
    redirectTo: `${window.location.origin}/landing`,
    data: { partner_id: partnerStore.partner.id }
  }
  var body = {
    email: email.value,
    options: options,
    partner: partnerStore.partner,
    siteUrl: window.location.origin,
    logoUrl: partnerStore.getLogo
  }
  const { error } = await supabase.functions.invoke('magic-link', { body });
  if (error) {
    let errorParsed = null;
    if (error.context && error.context.body instanceof ReadableStream) {
      const response = new Response(error.context.body);
      const text = await response.text();
      errorParsed = JSON.parse(text);
    }
    if (errorParsed.code == 402) {
      buttonText.value = "Retry Email";
      showButtonSpinner.value = false;
      notify('failure', 'Sign up disabled', 'Sign up is currently disabled for this partner. Please contact support for more information.');
      return;
    }
    buttonText.value = "Retry Email";
    showButtonSpinner.value = false;
    notify('failure', 'Sorry', 'It looks like there was an issue sending your email. Please make sure your email is correct, if the issue persists contact tech support.');
    return
  }
  // OTP naviagte to verify page
  if (partnerStore.partner.partners_protected.allowed_signin_methods && partnerStore.partner.partners_protected.allowed_signin_methods.includes('otp')) {
    showOtp.value = true;
    showButtonSpinner.value = false;
    notify('success', 'Email Sent', "Please check your inbox. If you don't see the email, please check your spam inbox.");
    return
  }
  buttonText.value = "Resend Email";
  showButtonSpinner.value = false;
  notify('success', 'Email Sent', "Please check your inbox. If you don't see the email, please check your spam inbox.");
}

// Computed
const toggleSignInOrLogin = (loginOrSignup) => {
  if (loginOrSignup) {
    titleText.value = 'Sign up for an account';
    toggleText.value = "Already have an account?";
    toggleLink.value = "Sign in";
    buttonText.value = 'Sign up';
    showLoginView.value = false;
    checkReady();
  } else {
    titleText.value = 'Sign in to your account';
    toggleText.value = "Don't have an account?";
    toggleLink.value = "Sign up";
    buttonText.value = 'Sign in';
    showLoginView.value = true;
  }
}

const checkReady = () => {
  if (showLoginView.value) {
    if (email.value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      isEnabled.value = 'enabled';
    } else {
      isEnabled.value = 'disabled';
    }
  } else {
    if (terms.value && privacy.value) {
      if (email.value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        isEnabled.value = 'enabled';
      } else {
        isEnabled.value = 'disabled';
      }
    } else {
      isEnabled.value = 'disabled';
    }
  }
}

// OTP
const otp_1 = ref('');
const otp_2 = ref('');
const otp_3 = ref('');
const otp_4 = ref('');
const otp_5 = ref('');
const otp_6 = ref('');

const handleOtpSubmit = async () => {
  const otp = otp_1.value + otp_2.value + otp_3.value + otp_4.value + otp_5.value + otp_6.value;
  if (otp.length != 6) {
    notify('failure', 'Invalid OTP', 'Please enter a valid 6 digit OTP');
    otp_1.value = '';
    otp_2.value = '';
    otp_3.value = '';
    otp_4.value = '';
    otp_5.value = '';
    otp_6.value = '';
    return;
  }
  const { error } = await supabase.auth.verifyOtp({ email: email.value, token: otp, type: 'email' })
  if (error) {
    notify('failure', 'Incorrect OTP', 'Incorrect OTP entered. Please try again.');
    return;
  }
  router.push('/landing');
}

const handlePaste = (e) => {
  e.preventDefault();
  const text = e.clipboardData.getData('text');
  if (text.length == 6) {
    otp_1.value = text[0];
    otp_2.value = text[1];
    otp_3.value = text[2];
    otp_4.value = text[3];
    otp_5.value = text[4];
    otp_6.value = text[5];
    handleOtpSubmit();
  }
}

const handleInput = (index) => {
  if (index == 1) {
    if (otp_1.value.length > 0) {
      otp_2.value = '';
      document.getElementById('otp-form').elements[1].focus();
    }
  } else if (index == 2) {
    if (otp_2.value.length > 0) {
      otp_3.value = '';
      document.getElementById('otp-form').elements[2].focus();
    }
  } else if (index == 3) {
    if (otp_3.value.length > 0) {
      otp_4.value = '';
      document.getElementById('otp-form').elements[3].focus();
    }
  } else if (index == 4) {
    if (otp_4.value.length > 0) {
      otp_5.value = '';
      document.getElementById('otp-form').elements[4].focus();
    }
  } else if (index == 5) {
    if (otp_5.value.length > 0) {
      otp_6.value = '';
      document.getElementById('otp-form').elements[5].focus();
    }
  } else if (index == 6) {
    if (otp_6.value.length > 0) {
      handleOtpSubmit()
    }
  }
}


const notify = (type, title, text) => {
  notificationType.value = type
  notificationTitle.value = title
  notificationText.value = text
  notification.value.show()
}

// Watchers
watch([email, terms, privacy], () => {
  checkReady();
})
</script>

<style scoped>
.enabled {
  @apply flex w-full justify-center rounded-md bg-operacolor px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-operacolorlight focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-operacolor
}

.disabled {
  @apply flex w-full justify-center rounded-md bg-gray-200 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-400 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 cursor-not-allowed dark:bg-neutral-700 dark:text-neutral-500
}
</style>