<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white dark:bg-neutral-800 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-neutral-100 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-0 hover:bg-gray-50">
        {{ props.title }}
        <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400 dark:text-neutral-300" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="absolute right-0 z-10 mt-2 w-56 max-h-56 origin-top-right rounded-md bg-white dark:bg-neutral-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none overflow-y-scroll">
        <div class="py-1">
          <MenuItem v-for="item in props.items" v-slot="{ active }">
            <div @click="handleClick(item)" :class="[active ? 'bg-gray-100 text-gray-900 dark:bg-neutral-700 dark:text-neutral-100' : 'text-gray-700 dark:text-neutral-300', 'block px-4 py-2 text-sm cursor-pointer']">{{ capitalizeFirstLetter(item) }}</div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
// Libraries
import { capitalizeFirstLetter } from '@/helper/helper';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
// Props
const props = defineProps({
  title: { type: String, required: true, default: 'Select'},
  items: { type: Array, required: true, default: null },
})
// Emits
const emit = defineEmits(['selected'])
// Simple Functions
const handleClick = (item) => { emit('selected', item) }
</script>