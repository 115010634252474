<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75 dark:bg-neutral-500 dark:bg-opacity-75" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex justify-center min-h-full p-4 text-center items-center">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl dark:bg-neutral-900 my-8 w-fit max-w-lg p-6">
              <div class="absolute top-0 right-0 pt-4 pr-4 block">
                <button type="button" class="text-gray-400 rounded-md hover:text-gray-500 outline-none dark:hover:text-neutral-300 dark:text-neutral-400" @click="open = false">
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="w-6 h-6" aria-hidden="true" />
                </button>
              </div>

              <form @submit.prevent="sendNewUserInvitation()">
                <div class="flex items-start w-fit">
                  <div class="flex items-center justify-center flex-shrink-0 mx-auto bg-green-100 dark:bg-green-950 rounded-full h-10 w-10">
                    <UserPlusIcon class="w-6 h-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div class="flex-shrink mt-2 mr-8 ml-4 text-left">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 dark:text-white">New Users Role</DialogTitle>

                    <div class="my-5 flex flex-col gap-5 w-80">

                      <!-- Partner -->
                      <div v-if="props.currentUserRole == 'super_admin'">
                        <div class="select_menu_label">Associated Partner</div>
                        <SelectMenu v-model="selectedPartner" :items="partners" />
                      </div>

                      <!-- Role -->
                      <div>
                        <div class="select_menu_label">Role</div>
                        <SelectMenu v-model="selectedRole" :items="roles" />
                      </div>

                      <!-- Email -->
                      <div class="w-full">
                        <label for="email" class="select_menu_label">Email</label>
                        <div class="input_wrapper">
                          <input v-model="email" type="email" autocomplete="email" name="email" id="email" class="input" placeholder="user@example.com" required="" />
                        </div>
                      </div>

                      <!-- Verify Email -->
                      <div class="w-full">
                        <label for="verify_email" class="input_label">Verify Email</label>
                        <div class="input_wrapper">
                          <input v-model="verify_email" type="email" autocomplete="email" name="verify_email" id="verify_email" class="input" placeholder="user@example.com" required="" />
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div class="flex gap-4 w-full justify-end items-center mt-5">
                  <button type="button" class="button_secondary" @click="open = false">Close</button>
                  <button type="submit" class="button_primary">
                    Send Invitation
                    <LoadGifButton v-if="buttonSpinner" />
                  </button>
                </div>
              </form>

            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
// Essentials
import { ref } from 'vue'
import { useUserStore } from '@/stores/user';
import { useProfilesStore } from '@/stores/profiles';
import { usePartnerStore } from '@/stores/partner';
// Components
import SelectMenu from '@/components/applicationui/SelectMenu.vue'
import LoadGifButton from '../loading/LoadGifButton.vue';
// Libraries
import { XMarkIcon, UserPlusIcon } from '@heroicons/vue/24/outline'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
// Props
const props = defineProps({
  currentUserRole: { type: String, required: null },
  roles: { type: Array, required: true },
  partners: { type: Array, required: false },
  currentUserPartner: { type: Object, required: true }
})
// Models
const open = defineModel()
// Emits
const emit = defineEmits(['failure', 'success', 'emails-dont-match'])
// Stores
const userStore = useUserStore();
const profilesStore = useProfilesStore();
const partnerStore = usePartnerStore();
// Globals
const selectedPartner = ref({ ...props.currentUserPartner, label: props.currentUserPartner.title })
const selectedRole = ref(props.roles[0])
const email = ref('')
const verify_email = ref('')
const buttonSpinner = ref(false)

// Functions
async function sendNewUserInvitation() {
  buttonSpinner.value = true

  // Check that emails match
  if (email.value !== verify_email.value) {
    emit('emails-dont-match')
    buttonSpinner.value = false
    return
  }

  // If they selected a partner admin role, add the email to the pending list
  if (selectedRole.value.value == 'partner_admin') {
    const successful = await profilesStore.addEmailToPendingPartnerAdmins(email.value)
    if (!successful) {
      emit('failure')
      buttonSpinner.value = false
      return
    }
  }
  

  // Send the magic link
  const error = await userStore.sendMagicLink(email.value, `${window.location.origin}/landing`, selectedPartner.value, window.location.origin, partnerStore.getLogoByMetaName(selectedPartner.value.meta_name))

  // Handle error
  if (error) {
    buttonSpinner.value = false
    emit('failure')
    return
  }

  // Handle sucess
  buttonSpinner.value = false
  emit('success')
  open.value = false;
}
</script>