<template>
  <div class="w-full">
    <!-- Popup Archive Offering -->
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-50" @close="open = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </TransitionChild>
        <!-- Content -->
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl dark:bg-neutral-800 sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div class="sm:flex sm:items-start">
                  <div class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon class="w-6 h-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 dark:text-neutral-300">Archive Offering</DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500 dark:text-neutral-400">Are you sure you want to archive your offering? Doing so will mean this offering will not appear for investors and advisors
                        in the invest tab. Holdings and transactions previously made for this offering will still be visible.</p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button type="button" class="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-red-600 rounded-md shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    @click="archiveOffering()">Archive</button>
                  <button type="button" class="button_secondary" @click="open = false" ref="cancelButtonRef">Cancel</button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Loading -->
    <div v-if="loading" class="flex flex-grow items-center justify-center h-[70vh]">
      <div class="w-32 h-32 -mt-10">
        <SpinnerJump />
      </div>
    </div>

    <!-- Offering Edit/Create -->
    <div v-else class="space-y-10 divide-y divide-gray-900/10 w-full">
      <div class="relative flex flex-wrap justify-start gap-8 w-full">

        <div class="flex flex-col gap-8">
          <!-- Back Button -->
          <div @click="handleBackButton()" class="button_secondary w-fit h-fit">
            <ChevronLeftIcon class="w-4 h-4 text-black dark:text-white -mx-1" />
            Back
          </div>

          <!-- Preview Card -->
          <div class="">
            <h2 v-if="type == 'create'" class="primary_header">Create Offering</h2>
            <h2 v-else class="primary_header">Edit Offering</h2>
            <p class="mt-1 text w-80">
              Some fields such as subcription documents can only be edited in Transact API.
            </p>

            <div v-if="o.visibility == 'public'" @click="copyOfferingLink(o.id)" class="button_secondary mt-5">Copy Public Link
              <ClipboardIcon class="size-4" />
            </div>

            <p class="mb-4 tertiary_header mt-5">Preview your offering</p>
            <div class="shadow-opera dark:shadow-none h-fit w-80 box-border flex justify-between flex-col background_secondary rounded-lg dark:text-neutral-300">

              <!-- Card Header Logo -->
              <img v-if="o.bannerUrl" class="flex items-center object-cover h-20 gap-2 text-lg bg-gray-200 dark:bg-neutral-700 rounded-t-lg" :src="o.bannerUrl" />
              <div v-else class="flex items-center h-20 gap-2 text-lg bg-gray-200 dark:bg-neutral-600 rounded-t-lg"></div>

              <div class="mx-5 -mt-5 overflow-hidden bg-white rounded-full ring-1 ring-border dark:ring-neutral-950 dark:bg-neutral-950 w-fit">
                <img v-if="o.logoUrl" :src="o.logoUrl" class="w-10 h-10 object-fit" />
                <div v-else class="w-10 h-10 bg-gray-300 dark:bg-neutral-700"></div>
              </div>

              <!-- Card Name & Slogan -->
              <div class="h-[160px]">
                <div class="px-5 py-4 overflow-hidden font-semibold max-h-[80px]">{{ o.name }}</div>
                <div class="pb-2 px-5 max-h-[80px] text-sm grow">
                  <div>{{ truncateText(o.slogan) }}</div>
                </div>
              </div>

              <!-- Card Info -->
              <div class="flex flex-col px-5 py-2 border-t divide-y divide-dashed divide-border border-border dark:border-neutral-600 dark:divide-neutral-600">
                <div class="flex justify-between py-4 text-sm"><span>Market Sector</span><span>{{ o.market_sector }}</span></div>
                <div v-if="partnerStore.partner.partners_protected.show_scores && o.show_score && o.score" class="flex justify-between py-4 text-sm"><span>Opera Score</span>

                  <div v-if="o.score && o.score != 'Not yet available'" class="flex items-center gap-1">
                    <span class="mr-1 text-xs text-gray-400">({{ o.score }})</span>
                    <OperaScore :score="o.score" />
                  </div>
                  <span v-else class="text-operacolor">{{ o.score }}</span>

                </div>
              </div>
              <!-- Button -->
              <div class="flex px-5 pb-5">
                <div
                  class="justify-center w-full cursor-pointer inline-flex items-center gap-x-1.5 rounded-md font-medium bg-white dark:bg-operacolor dark:hover:bg-neutral-700 dark:hover:text-white dark:text-white px-2.5 py-1.5 text-sm text-operacolor shadow-sm ring-1 ring-inset ring-operacolor dark:ring-0 hover:bg-operacolor hover:text-white">
                  Learn More
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Offering Form -->
        <form @submit.prevent="handleSubmit()" class="flex-1 flex-grow h-[75vh] overflow-y-scroll bg-white dark:bg-neutral-900 shadow-opera rounded-xl">
          <div class="w-full">
            <div class="flex flex-col gap-x-6 gap-y-8 w-full p-8">

              <!-- Section One Card Info -->
              <div class="flex flex-col gap-x-6 gap-y-8">

                <!-- Row 1 -->
                <div class="flex flex-wrap w-full gap-10">
                  <!-- Name -->
                  <div class="flex-grow min-w-52">
                    <label for="name" class="input_label">Offering Name</label>
                    <div class="input_wrapper">
                      <input type="text" name="name" class="input" placeholder="New Offering Name" v-model="o.name" required />
                    </div>
                  </div>

                  <!-- Status -->
                  <div class="min-w-52 max-w-72">
                    <div class="select_menu_label">Status</div>
                    <SelectMenu v-model="o.status" :items="offeringStatusOptions" />
                  </div>

                  <!-- Offering Type -->
                  <div class="min-w-52 max-w-72">
                    <div class="select_menu_label">Offering Type</div>
                    <SelectMenu v-model="o.offering_type" :items="offeringTypeOptions" />
                  </div>

                  <!-- Visibility -->
                  <div class="min-w-52 max-w-72">
                    <div class="select_menu_label">Visibility</div>
                    <SelectMenu v-model="o.visibility" :items="offeringVisibilityOptions" :disabled="o.use_regcf_protocols" />
                  </div>
                </div>

                <!-- Row 2 -->
                <div class="flex">
                  <div class="flex-grow">
                    <label for="slogan" class="!flex items-center gap-2 input_label">
                      Slogan
                      <InfoCircle info="Transact API does not support apostrophes or quotes. Avoid special characters if possible." />
                    </label>
                    <div class="input_wrapper">
                      <textarea name="slogan" rows="2" v-model="o.slogan" class="input" required></textarea>
                    </div>
                  </div>
                </div>

                <!-- Row 3 -->
                <div class="flex flex-wrap gap-10">
                  <!-- Logo Image -->
                  <div>
                    <label for="logo" class="!flex items-center gap-2 input_label">
                      Logo Image
                      <InfoCircle info="For best results use a square image, height and width as close to the same as possible" />
                    </label>
                    <div class="flex items-center mt-2 gap-x-3">
                      <PhotoIcon v-if="!o.logoUrl" class="w-12 h-12 text-gray-300" aria-hidden="true" />
                      <img v-else :src="o.logoUrl" alt="Logo Image" class="w-12 h-12 rounded-full object-fit">
                      <label for="circle-image-upload-edit" type="button" class="button_secondary">Change</label>
                      <input id="circle-image-upload-edit" name="circle-image-upload-edit" type="file" class="sr-only" accept="image/*" @change="logoImageChanged" />
                    </div>
                  </div>

                  <!-- Banner Image -->
                  <div>
                    <label for="banner" class="!flex items-center gap-2 input_label">
                      Banner Image
                      <InfoCircle info="The smaller this file is the faster it will load when displayed to investors, we recommend a file size of 1 MB or less." />
                    </label>
                    <div class="flex items-center mt-2 gap-x-3">
                      <PhotoIcon v-if="!o.bannerUrl" class="w-12 h-12 text-gray-300" aria-hidden="true" />
                      <img v-else :src="o.bannerUrl" alt="Banner Image" class="w-24 h-12 rounded-lg object-fit">
                      <label for="banner-image-upload-edit" type="button" class="button_secondary">Change</label>
                      <input id="banner-image-upload-edit" name="banner-image-upload-edit" type="file" class="sr-only" accept="image/*" @change="bannerImageChanged" />
                    </div>
                  </div>

                  <!-- Market Sector -->
                  <div class="flex-grow min-w-52">
                    <label for="sector" class="input_label">Market Sector</label>
                    <div class="input_wrapper">
                      <input type="text" name="sector" class="input" placeholder="Technology" v-model="o.market_sector" required />
                    </div>
                  </div>

                  <!-- Opera Score -->
                  <div v-if="userStore.user.role == 'super-admin' || partnerStore.partner.partner_scores" class="min-w-52 max-w-72">
                    <label for="sector" class="!flex items-center gap-2 input_label">Opera Score
                      <InfoCircle info="Use an integer 1 through 5, or use the key phrase 'Not yet available'" />
                    </label>
                    <div class="input_wrapper">
                      <input type="text" name="sector" class="input" placeholder="0-5" v-model="o.score" />
                    </div>
                  </div>
                </div>

                <!-- Row 4 -->
                <div class="flex">
                  <!-- Description -->
                  <div class="flex-grow">
                    <label for="description" class="!flex items-center gap-2 input_label">
                      Description
                      <InfoCircle info="Transact API does not support apostrophes ' or quotes. Please avoid special characters" />
                    </label>
                    <div class="input_wrapper">
                      <textarea name="description" rows="3" v-model="o.description" class="input" required></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Links -->
              <div>
                <div class="select_menu_label">Links</div>
                <div class="bg-gray-100 dark:bg-neutral-950 rounded-xl p-5 flex flex-col gap-5 w-full">
                  <div v-if="o?.links?.length > 0" class="flex flex-col gap-5">
                    <div v-for="(link, i) in o.links" :key="i" class="flex flex-wrap gap-5">
                      <!-- Link Name -->
                      <div class="min-w-40 max-w-60">
                        <label for="website" class="!flex justify-between items-end input_label">
                          Display Name
                          <span class="text-xs text-gray-400 dark:text-neutral-400">Optional</span>
                        </label>
                        <div class="input_wrapper">
                          <input type="text" name="website" id="website" class="input" placeholder="ex. Offering Info" v-model="link.name" />
                        </div>
                      </div>
                      <!-- Link URL -->
                      <div class="flex-grow min-w-52">
                        <label for="website" class="!flex justify-between items-end input_label">URL</label>
                        <div class="input_wrapper">
                          <input type="text" name="website" id="website" class="input" placeholder="https://example.com" v-model="link.url" />
                        </div>
                      </div>
                      <!-- Link Type -->
                      <div class="w-40">
                        <div class="select_menu_label">Type</div>
                        <SelectMenu v-model="link.type" :items="['website', 'facebook', 'linkedin', 'twitter']" />
                      </div>
                      <!-- Link Demo -->
                      <div class="w-52">
                        <div class="select_menu_label">Preview</div>
                        <div class="text-blue-600 flex gap-2 items-center cursor-pointer w-fit hover:text-blue-700 mt-3">
                          <LinkIcon v-if="link.type == 'website'" class="w-6 h-6" />
                          <svg v-else-if="link.type == 'twitter'" class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                            <path
                              d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                          </svg>
                          <svg v-else-if="link.type == 'facebook'" class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                            <path fill-rule="evenodd"
                              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                              clip-rule="evenodd" />
                          </svg>
                          <svg v-else-if="link.type == 'instagram'" class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                            <path fill-rule="evenodd"
                              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                              clip-rule="evenodd" />
                          </svg>
                          <svg v-else-if="link.type == 'linkedin'" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                            <path
                              d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                              fill-rule="evenodd" clip-rule="evenodd" />
                          </svg>
                          <span class="w-48 truncate underline font-medium">{{ link.name }}</span>
                        </div>
                      </div>
                      <!-- Link Delete -->
                      <div class="self-end">
                        <TrashIcon @click="o.links.splice(i, 1)" class="size-5 mb-3 cursor-pointer hover:text-red-700 transition-all ease-in-out duration-300" />
                      </div>
                    </div>
                  </div>

                  <div class="button_primary w-fit" @click="() => { o.links.push({ id: `${uuidv4()}`, name: '', url: '', type: 'website' }) }">Add Link
                    <LinkIcon class="size-4" />
                  </div>
                </div>
              </div>

              <!-- Overview Key Values -->
              <div>
                <div class="select_menu_label">Overview Key Info Section</div>
                <div class="bg-gray-100 dark:bg-neutral-950 rounded-xl p-5 flex flex-col gap-5 w-full">
                  <div v-if="o?.key_values?.length > 0" class="flex flex-col gap-5">
                    <div v-for="(key_value, i) in o?.key_values" :key="i" class="flex gap-5">
                      <!-- Key Value Name -->
                      <div class="flex-grow w-52">
                        <label for="keyName" class="!flex justify-between items-end input_label">
                          Name
                        </label>
                        <div class="input_wrapper">
                          <input type="text" name="keyName" id="keyName" class="input" placeholder="ex. Ticker" v-model="key_value.name" :disabled="(key_value.type == 'curated')" />
                        </div>
                      </div>
                      <!-- Key Value Value -->
                      <div class="flex-grow w-52">
                        <label for="keyValue" class="!flex justify-between items-end input_label">Value</label>
                        <div class="input_wrapper">
                          <input type="text" name="keyValue" id="keyValue" class="input" placeholder="ex. APPL" v-model="key_value.value" :disabled="(key_value.type == 'curated')" />
                        </div>
                      </div>
                      <!-- Key Value Type -->
                      <div v-if="key_value.type != 'curated'" class="w-40">
                        <div class="select_menu_label">Type</div>
                        <SelectMenu v-model="key_value.type"
                          :items="['text', 'badge blue', 'badge red', 'badge yellow', 'badge green', 'badge purple', 'badge pink', 'badge teal', 'badge indigo', 'badge orange', 'badge gray']" />
                      </div>
                      <!-- Key Value Delete -->
                      <div class="self-end">
                        <TrashIcon @click="o?.key_values?.splice(i, 1)" class="size-5 mb-3 cursor-pointer hover:text-red-700 transition-all ease-in-out duration-300" />
                      </div>
                    </div>
                  </div>


                  <div class="flex flex-wrap gap-5">
                    <!-- Add Custom Info -->
                    <div @click="o?.key_values?.push({ name: '', value: '', type: 'text' })" class="button_primary w-fit">
                      Add Custom Info
                      <TagIcon class="size-4" />
                    </div>
                    <!-- Add Curated Info -->
                    <div class="w-52">
                      <Dropdown title="Add From Curated List"
                        :items="['security type', 'market sector', 'price per share', 'raise goal', 'ticker', 'start date', 'end date', 'funded amount', 'pending amount', 'gross fees', 'net fees', 'minimum investment', 'maximum investment',]"
                        @selected="handleCuratedInfoSelection" />
                    </div>
                  </div>

                  <!-- Preview Overview Key Info -->
                  <div v-if="o?.key_values?.length > 0">
                    <div class="select_menu_label">Preview</div>
                    <div class="bg-white dark:bg-neutral-900 shadow dark:shadow-none !rounded-xl w-full">
                      <dl class="divide-y divide-gray-100 dark:divide-neutral-800">
                        <div v-for="key_value in o?.key_values" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                          <dt class="tertiary_subheader dark:text-white">{{ key_value.name }}</dt>
                          <dd :class="[(key_value.type.includes('badge')) ? getBadgeClass(key_value.type) + ' w-fit' : 'mt-1 text sm:col-span-2 sm:mt-0']" class="">{{ key_value.value }}</dd>
                        </div>
                      </dl>
                    </div>
                  </div>

                </div>
              </div>

              <!-- Overview Additional Content -->
              <div>
                <div class="select_menu_label">Overview Additional Content</div>
                <div class="bg-gray-100 dark:bg-neutral-950 rounded-xl p-5 flex flex-col gap-5 w-full">

                  <div v-if="filteredContent?.length > 0" class="flex flex-col gap-5">
                    <div v-for="(added_content) in filteredContent" :key="added_content.id">

                      <div v-if="added_content.type == 'content'" class="relative bg-white dark:bg-neutral-900 shadow p-5 rounded-lg flex flex-col gap-5">
                        <!-- Key Value Name -->
                        <div class="flex-grow w-96">
                          <label for="contentHeader" class="!flex justify-between items-end input_label">
                            Header
                          </label>
                          <div class="input_wrapper">
                            <input type="text" name="contentHeader" id="contentHeader" class="input" placeholder="ex. Mission Statement" v-model="added_content.header" />
                          </div>
                        </div>
                        <!-- Added Content Textarea -->
                        <div class="flex-grow">
                          <label for="contentText" class="!flex items-center gap-2 input_label">
                            Content
                            <InfoCircle info="Transact API does not support apostrophes ' or quotes. Please avoid special characters" />
                          </label>
                          <div class="input_wrapper">
                            <textarea name="contentText" rows="3" v-model="added_content.text" class="input" required></textarea>
                          </div>
                        </div>
                        <!-- Added Content Delete -->
                        <div class="absolute top-5 right-5">
                          <TrashIcon @click="o?.content?.splice(o.content.findIndex(item => item.id === added_content.id), 1)"
                            class="size-5 mb-3 cursor-pointer hover:text-red-700 transition-all ease-in-out duration-300" />
                        </div>
                      </div>


                      <div v-if="added_content.type == 'image'" class="relative bg-white dark:bg-neutral-900 shadow p-5 rounded-lg">
                        <div>
                          <label class="!flex items-center gap-2 input_label">
                            Image
                            <InfoCircle info="The smaller this file is the faster it will load when displayed to investors, we recommend a file size of 1 MB or less." />
                          </label>
                          <div class="flex items-center mt-2 gap-x-3">
                            <img v-if="added_content.url" :src="added_content.url" alt="Uploaded Image" class="w-24 h-12 rounded-lg object-fit">
                            <PhotoIcon v-else class="w-12 h-12 text-gray-300" aria-hidden="true" />
                            <label :for="`content-image-upload-edit-${added_content.id}`" type="button" class="button_secondary">Change</label>
                            <input :id="`content-image-upload-edit-${added_content.id}`" :name="`content-image-upload-edit-${added_content.id}`" type="file" class="sr-only" accept="image/*"
                              @change="handleContentImagesArrayChanged($event, added_content.id)" />
                          </div>
                        </div>
                        <!-- Image Delete -->
                        <div class="absolute top-5 right-5">
                          <TrashIcon @click="o?.content?.splice(o.content.findIndex(item => item.id === added_content.id), 1)"
                            class="size-5 mb-3 cursor-pointer hover:text-red-700 transition-all ease-in-out duration-300" />
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="flex gap-5">
                    <div @click="o?.content?.push({ id: `${uuidv4()}`, header: '', text: '', type: 'content' })" class="button_primary w-fit">
                      Add Additional Content
                      <ListBulletIcon class="size-4" />
                    </div>

                    <div @click="o?.content?.push({ id: `${uuidv4()}`, name: '', type: 'image' })" class="button_primary w-fit">
                      Add Image
                      <PhotoIcon class="size-4" />
                    </div>
                  </div>

                </div>
              </div>

              <!-- Overview Design -->
              <div>
                <div class="select_menu_label">Order Overview Content</div>
                <div class="bg-gray-100 dark:bg-neutral-950 rounded-xl p-5 flex flex-col gap-5 w-full">
                  <SlickDrag v-model="o.content" />
                </div>
              </div>

              <!-- Offering Documents -->
              <div>
                <div class="select_menu_label">Offering Documents</div>
                <div v-if="type == 'edit'" class="bg-gray-100 dark:bg-neutral-950 rounded-xl p-5 flex flex-col gap-5 w-full">
                  <DocumentsList v-if="offeringDocuments?.length > 0" :documents="offeringDocuments" @remove="deleteFile($event, o.id)" />
                  <!-- Add Offering Document Button -->
                  <label class="button_primary w-fit" for="offering-doc">
                    Add Document
                    <LoadGifButton v-if="uploadingDocument" />
                    <DocumentPlusIcon v-else class="size-4" />
                  </label>
                  <!-- Hidden File Input Field -->
                  <input name="offering-doc" id="offering-doc" type="file" class="sr-only hidden" accept="*" @change="handleOfferingDocumentUpload($event, o.id)" />
                </div>

                <!--  -->
                <div v-else class="bg-gray-100 dark:bg-neutral-950 rounded-xl p-5 flex flex-col gap-5 w-full">
                  <div class="text-sm font-medium italic">Please create the offering first then add documents</div>
                </div>

              </div>

              <!-- Checkout Form Colors -->
              <div>
                <div class="select_menu_label">Checkout Form Colors</div>
                <div class="bg-gray-100 dark:bg-neutral-950 rounded-xl p-5 flex flex-col gap-5 w-full">
                  <div class="flex flex-wrap gap-10 w-full">
                    <!-- Checkout Form Gradient Start Color -->
                    <div class="flex flex-col gap-8">
                      <div class="w-72">
                        <label for="start_color" class="!flex items-center gap-2 input_label">
                          Checkout Form Gradient Start Color
                          <InfoCircle
                            info="The checkout form has a gradient background starting with one color and fading to another. We often recommend using your partner primary color for this value" />
                        </label>
                        <div class="input_wrapper">
                          <input type="color" name="start_color" id="start_color"
                            class="p-1 h-10 w-14 dark:bg-transparent dark:border-neutral-700 bg-white border border-gray-200 cursor-pointer rounded-lg" placeholder="#00000"
                            v-model="o.gradient_start_color" />
                        </div>
                      </div>
                      <!-- Checkout Form Gradient End Color -->
                      <div class="w-72">
                        <label for="end_color" class="!flex items-center gap-2 input_label">
                          Checkout Form Gradient End Color
                          <InfoCircle
                            info="The checkout form has a gradient background starting with one color and fading to another. We often recommend using your partner light primary color for this value" />
                        </label>
                        <div class="input_wrapper">
                          <input type="color" name="end_color" id="end_color"
                            class="p-1 h-10 w-14 dark:bg-transparent dark:border-neutral-700 bg-white border border-gray-200 cursor-pointer rounded-lg" placeholder="#FFFFFF"
                            v-model="o.gradient_end_color" />
                        </div>
                      </div>
                      <!-- Restore Defualt Button -->
                      <div @click="restoreDefault()" class="button_secondary w-fit">
                        Restore Default
                        <EyeDropperIcon class="w-4 h-4" />
                      </div>
                    </div>

                    <div>
                      <div class="select_menu_label">Preview</div>
                      <div class="w-80 h-52 flex flex-col rounded-lg p-4" :style="`background: linear-gradient(to right, ${o.gradient_start_color}, ${o.gradient_end_color})`">
                        <div class="bg-white opacity-25 h-3 w-16 rounded-md mb-2"></div>
                        <div class="bg-white opacity-25 h-5 w-full rounded-md mb-6"></div>
                        <div class="bg-white opacity-25 h-3 w-16 rounded-md mb-2"></div>
                        <div class="bg-white opacity-25 h-5 w-full rounded-md mb-6"></div>
                        <div class="border-t-[1px] border-white w-full border-dashed border-opacity-40"></div>
                        <div class="bg-white opacity-25 h-8 w-full rounded-md mt-auto"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Edit Scores -->
              <div v-if="partnerStore.partner.partners_protected.show_scores">
                <!-- Scores Section Header -->
                <div class="font-semibold cursor-pointer w-fit" @click="showScoreScetion = !showScoreScetion">
                  <div v-if="showScoreScetion" class="flex items-center gap-2">
                    Hide Scores Section
                    <MinusIcon class="w-4 h-4" />
                  </div>
                  <div v-else class="flex items-center gap-2">
                    Edit Scores Section
                    <PlusIcon class="w-4 h-4" />
                  </div>
                </div>

                <!-- Scores Section -->
                <div v-if="showScoreScetion" class="flex flex-col gap-10 sm:p-10 p-5 rounded-lg bg-gray-100 dark:bg-neutral-950 mt-3">
                  <!-- Financials -->
                  <div class="flex flex-col gap-5">
                    <div class="w-56">
                      <label for="finance_score" class="!flex justify-between items-end input_label">
                        <span class="flex items-center gap-2">Financial Score
                          <InfoCircle info="Leave blank or put 'Not yet available'." />
                        </span>
                        <span class="text-xs text-gray-400 dark:text-neutral-400">Optional</span>
                      </label>
                      <div class="input_wrapper">
                        <input type="text" name="finance_score" class="input" placeholder="0-5" v-model="o.financials" />
                      </div>
                    </div>
                    <div class="w-2xl">
                      <label for="financials_explanation" class="!flex justify-between items-end input_label">
                        <span class="flex items-center gap-2">
                          Financial Score Explanation
                          <InfoCircle info="A brief explanation of the reasons for the given score." />
                        </span>
                        <span class="text-xs text-gray-400 dark:text-neutral-400">Optional</span>
                      </label>
                      <div class="input_wrapper">
                        <textarea name="financials_explanation" rows="3" v-model="o.financials_explanation" class="input"></textarea>
                      </div>
                    </div>
                  </div>

                  <!-- AUM -->
                  <div class="flex flex-col gap-5">
                    <div class="w-56">
                      <label for="aum" class="!flex justify-between items-end input_label">
                        <span class="flex items-center gap-2">AUM Score
                          <InfoCircle info="Leave blank or put 'Not yet available'." />
                        </span>
                        <span class="text-xs text-gray-400 dark:text-neutral-400">Optional</span>
                      </label>
                      <div class="input_wrapper">
                        <input type="text" name="aum" class="input" placeholder="0-5" v-model="o.aum" />
                      </div>
                    </div>
                    <div class="w-2xl">
                      <label for="aum_explanation" class="!flex justify-between items-end input_label">
                        <span class="flex items-center gap-2">
                          AUM Score Explanation
                          <InfoCircle info="A brief explanation of the reasons for the given score." />
                        </span>
                        <span class="text-xs text-gray-400 dark:text-neutral-400">Optional</span>
                      </label>
                      <div class="input_wrapper">
                        <textarea name="aum_explanation" rows="3" v-model="o.aum_explanation" class="input"></textarea>
                      </div>
                    </div>
                  </div>

                  <!-- Management Team -->
                  <div class="flex flex-col gap-5">
                    <div class="w-56">
                      <label for="management_score" class="!flex justify-between items-end input_label">
                        <span class="flex items-center gap-2">Management Score
                          <InfoCircle info="Leave blank or put 'Not yet available'." />
                        </span>
                        <span class="text-xs text-gray-400 dark:text-neutral-400">Optional</span>
                      </label>
                      <div class="input_wrapper">
                        <input type="text" name="management_score" class="input" placeholder="0-5" v-model="o.management" />
                      </div>
                    </div>
                    <div class="w-2xl">
                      <label for="management_explanation" class="!flex justify-between items-end input_label">
                        <span class="flex items-center gap-2">
                          Management Score Explanation
                          <InfoCircle info="A brief explanation of the reasons for the given score." />
                        </span>
                        <span class="text-xs text-gray-400 dark:text-neutral-400">Optional</span>
                      </label>
                      <div class="input_wrapper">
                        <textarea name="management_explanation" rows="3" v-model="o.management_explanation" class="input"></textarea>
                      </div>
                    </div>
                  </div>

                  <!-- Longevity -->
                  <div class="flex flex-col gap-5">
                    <div class="w-56">
                      <label for="longevity_score" class="!flex justify-between items-end input_label">
                        <span class="flex items-center gap-2">Fund Longevity Score
                          <InfoCircle info="Leave blank or put 'Not yet available'." />
                        </span>
                        <span class="text-xs text-gray-400 dark:text-neutral-400">Optional</span>
                      </label>
                      <div class="input_wrapper">
                        <input type="text" name="longevity_score" class="input" placeholder="0-5" v-model="o.longevity" />
                      </div>
                    </div>
                    <div class="w-2xl">
                      <label for="longevity_explanation" class="!flex justify-between items-end input_label">
                        <span class="flex items-center gap-2">
                          Fund Longevity Score Explanation
                          <InfoCircle info="A brief explanation of the reasons for the given score." />
                        </span>
                        <span class="text-xs text-gray-400">Optional</span>
                      </label>
                      <div class="input_wrapper">
                        <textarea name="longevity_explanation" rows="3" v-model="o.longevity_explanation" class="input"></textarea>
                      </div>
                    </div>
                  </div>

                  <!-- Documents -->
                  <div class="flex flex-col gap-5">
                    <div class="w-56">
                      <label for="documents_score" class="!flex justify-between items-end input_label">
                        <span class="flex items-center gap-2">Documents Score
                          <InfoCircle info="Leave blank or put 'Not yet available'." />
                        </span>
                        <span class="text-xs text-gray-400 dark:text-neutral-400">Optional</span>
                      </label>
                      <div class="input_wrapper">
                        <input type="text" name="documents_score" class="input" placeholder="0-5" v-model="o.documents_score" />
                      </div>
                    </div>
                    <div class="w-2xl">
                      <label for="documents_explanation" class="!flex justify-between items-end input_label">
                        <span class="flex items-center gap-2">
                          Documents Score Explanation
                          <InfoCircle info="A brief explanation of the reasons for the given score." />
                        </span>
                        <span class="text-xs text-gray-400 dark:text-neutral-400">Optional</span>
                      </label>
                      <div class="input_wrapper">
                        <textarea name="documents_explanation" rows="3" v-model="o.documents_explanation" class="input"></textarea>
                      </div>
                    </div>
                  </div>

                  <!-- Liquidity -->
                  <div class="flex flex-col gap-5">
                    <div class="w-56">
                      <label for="liquidity_score" class="!flex justify-between items-end input_label">
                        <span class="flex items-center gap-2">Liquidity Score
                          <InfoCircle info="Leave blank or put 'Not yet available'." />
                        </span>
                        <span class="text-xs text-gray-400 dark:text-neutral-400">Optional</span>
                      </label>
                      <div class="input_wrapper">
                        <input type="text" name="liquidity_score" class="input" placeholder="0-5" v-model="o.liquidity" />
                      </div>
                    </div>
                    <div class="w-2xl">
                      <label for="liquidity_explanation" class="!flex justify-between items-end input_label">
                        <span class="flex items-center gap-2">
                          Liquidity Score Explanation
                          <InfoCircle info="A brief explanation of the reasons for the given score." />
                        </span>
                        <span class="text-xs text-gray-400 dark:text-neutral-400">Optional</span>
                      </label>
                      <div class="input_wrapper">
                        <textarea name="liquidity_explanation" rows="3" v-model="o.liquidity_explanation" class="input"></textarea>
                      </div>
                    </div>
                  </div>

                  <!-- Fees -->
                  <div class="flex flex-col gap-5">
                    <div class="w-56">
                      <label for="fee_score" class="!flex justify-between items-end input_label">
                        <span class="flex items-center gap-2">Fees & Expenses Score
                          <InfoCircle info="Leave blank or put 'Not yet available'." />
                        </span>
                        <span class="text-xs text-gray-400 dark:text-neutral-400">Optional</span>
                      </label>
                      <div class="input_wrapper">
                        <input type="text" name="fee_score" class="input" placeholder="0-5" v-model="o.fee_score" />
                      </div>
                    </div>
                    <div class="w-2xl">
                      <label for="fee_explanation" class="!flex justify-between items-end input_label">
                        <span class="flex items-center gap-2">
                          Fees & Expenses Score Explanation
                          <InfoCircle info="A brief explanation of the reasons for the given score." />
                        </span>
                        <span class="text-xs text-gray-400 dark:text-neutral-400">Optional</span>
                      </label>
                      <div class="input_wrapper">
                        <textarea name="fee_explanation" rows="3" v-model="o.fee_explanation" class="input"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Edit Tapi -->
              <div>
                <!-- Tapi Section Header -->
                <div class="flex gap-2 items-center">
                  <!-- Tapi Section Button -->
                  <div class="font-semibold cursor-pointer" @click="showTapiSection = !showTapiSection">
                    <div v-if="showTapiSection" class="flex items-center gap-2">
                      Hide TAPI Section
                      <MinusIcon class="w-4 h-4" />
                    </div>
                    <div v-else class="flex items-center gap-2">
                      Edit TAPI Section
                      <PlusIcon class="w-4 h-4" />
                    </div>
                  </div>
                  <InfoCircle
                    info="These fields are required for the Transact API, however if you leave them empty Opera will omit that information from it's displayed offering information and use default values for Transact API." />
                </div>

                <!-- Tapi Section -->
                <div v-if="showTapiSection" class="flex flex-wrap gap-y-10 gap-x-10 p-5 rounded-lg bg-gray-100 dark:bg-neutral-950 mt-3">

                  <div class="flex flex-wrap gap-10">
                    <!-- Start Date -->
                    <div class="w-56">
                      <label for="inception" class="!flex justify-between items-end input_label">
                        <span class="flex items-center gap-2">Start Date
                          <InfoCircle info="Leave empty to have Opera omit this information from the offering. Transact API will be given default values." />
                        </span>
                      </label>
                      <div class="input_wrapper">
                        <input v-model="start_date" type="date" name="inception" class="input" placeholder="mm/dd/yyyy" />
                      </div>
                    </div>
                    <!-- End Date -->
                    <div class="w-56">
                      <label for="end_date" class="!flex justify-between items-end input_label">
                        <span class="flex items-center gap-2">End Date
                          <InfoCircle info="Leave empty to have Opera omit this information from the offering. Transact API will be given default values." />
                        </span>
                      </label>
                      <div class="input_wrapper">
                        <input v-model="end_date" type="date" name="end_date" class="input" placeholder="mm/dd/yyyy" />
                      </div>
                    </div>
                  </div>

                  <!-- Minimum Investment Amount -->
                  <div class="w-72">
                    <label for="minimum" class="input_label !flex items-center gap-2">
                      Minimum Investment Amount
                      <InfoCircle info="The minimum amount that can be invested at one time" />
                    </label>
                    <div class="relative mt-2 rounded-md shadow-sm">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <span class="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input type="text" name="minimum" v-model="minimum"
                        class="dark:bg-neutral-800 dark:text-neutral-300 dark:ring-neutral-800 dark:focus:ring-2 dark:focus:ring-inset dark:focus:ring-operacolor dark:disabled:bg-neutral-800 dark:disabled:text-neutral-500 block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-operacolor sm:text-sm sm:leading-6"
                        placeholder="0" aria-describedby="price-currency" />
                      <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <span class="text-gray-500 dark:text-neutral-400 sm:text-sm">USD</span>
                      </div>
                    </div>
                  </div>

                  <!-- Maximum Investment Amount -->
                  <div class="w-72">
                    <label for="maximum" class="input_label !flex items-center gap-2">
                      Maximum Amount
                      <InfoCircle info="The maximum amount of the offering (cap amount)" />
                    </label>
                    <div class="relative mt-2 rounded-md shadow-sm">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <span class="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input type="text" name="maximum" v-model="maximum"
                        class="dark:bg-neutral-800 dark:text-neutral-300 dark:ring-neutral-800 dark:focus:ring-2 dark:focus:ring-inset dark:focus:ring-operacolor dark:disabled:bg-neutral-800 dark:disabled:text-neutral-500 block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-operacolor sm:text-sm sm:leading-6"
                        placeholder="0" aria-describedby="price-currency" />
                      <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <span class="text-gray-500 dark:text-neutral-400 sm:text-sm">USD</span>
                      </div>
                    </div>
                  </div>

                  <!-- Target Amount -->
                  <div class="w-72">
                    <label for="target_amount" class="input_label !flex items-center gap-2">
                      Target Amount
                      <InfoCircle info="The target amount of the raise (must be less than the maximum amount)" />
                    </label>
                    <div class="relative mt-2 rounded-md shadow-sm">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <span class="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input type="text" name="target_amount" v-model="target_amount"
                        class="dark:bg-neutral-800 dark:text-neutral-300 dark:ring-neutral-800 dark:focus:ring-2 dark:focus:ring-inset dark:focus:ring-operacolor dark:disabled:bg-neutral-800 dark:disabled:text-neutral-500 block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-operacolor sm:text-sm sm:leading-6"
                        placeholder="0" aria-describedby="price-currency" />
                      <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <span class="text-gray-500 dark:text-neutral-400 sm:text-sm">USD</span>
                      </div>
                    </div>
                  </div>

                  <!-- Unit Price -->
                  <div class="w-72">
                    <label for="unit_price" class="input_label !flex items-center gap-2">
                      Unit Price
                      <InfoCircle info="Price per unit (unit/share price). An investment can only be made in increments of this number." />
                    </label>
                    <div class="relative mt-2 rounded-md shadow-sm">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <span class="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input type="text" name="unit_price" v-model="unit_price"
                        class="dark:bg-neutral-800 dark:text-neutral-300 dark:ring-neutral-800 dark:focus:ring-2 dark:focus:ring-inset dark:focus:ring-operacolor dark:disabled:bg-neutral-800 dark:disabled:text-neutral-500 block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-operacolor sm:text-sm sm:leading-6"
                        placeholder="0" aria-describedby="price-currency" />
                      <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <span class="text-gray-500 dark:text-neutral-400 sm:text-sm">USD</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-col gap-5">
                <!-- Show Scores -->
                <Switch v-if="partnerStore.partner.partners_protected.show_scores" v-model="o.show_score" label="Show Opera Score"
                  description="Do you want to display the Opera score for this offering users?" />
                <!-- Accredited Investors Only -->
                <Switch v-model="o.require_accreditation" label="Accredited Investors Only" description="Require all investors to be accredited before their investments can be finalized?" />
                <!-- Show Factright Report -->
                <Switch v-if="isSandbox" v-model="o.show_factright_report" label="Show Factright Report" description="Show the Factright report to potential investors?" />

                <!-- Use RegCF -->
                <Switch v-model="o.use_regcf_protocols" label="Use RegCF Protocols" description="Enabling this will enable all RegCF compliant behavior" />
                <!-- Show Forum Section -->
                <Switch v-model="o.show_forum" :disabled="o.use_regcf_protocols" label="Show Forum Section" description="Do you want to include a public forum section for this offering?" />


                <!-- Only Accept Custody Payments -->
                <Switch v-if="partnerStore.partner.allow_custody_accounts" v-model="o.is_custody_only" label="Only Accept Custody Payments"
                  description="Will this offering only accept payments via custody?" />
                <!-- Check Instructions for Custody -->
                <div v-if="o.is_custody_only" class="flex flex-col flex-wrap gap-5 p-5 rounded-lg shadow bg-gray-100 dark:bg-neutral-950">
                  <div class="flex gap-2 items-center">
                    <span class="font-medium leading-6 text-gray-900 dark:text-neutral-300 text-md">Check Instructions for Funding Account</span>
                  </div>
                  <div class="flex flex-wrap gap-5">
                    <!-- UPS and Fedex -->
                    <div class="w-full">
                      <label for="custody_fedex" class="!flex items-center gap-2 input_label">
                        Fedex or UPS
                      </label>
                      <div class="input_wrapper">
                        <textarea name="custody_fedex" rows="2" v-model="o.custody_fedex_instructions" class="input" required></textarea>
                      </div>
                    </div>
                    <!-- Regular Mail -->
                    <div class="w-full">
                      <label for="custody_mail" class="!flex items-center gap-2 input_label">
                        Regular Mail
                      </label>
                      <div class="input_wrapper">
                        <textarea name="custody_mail" rows="2" v-model="o.custody_mail_instructions" class="input" required></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Wire Instruction for Custody -->
                <div v-if="o.is_custody_only" class="flex flex-col flex-wrap gap-5 p-5 rounded-lg shadow bg-gray-100 dark:bg-neutral-950">
                  <div class="flex gap-2 items-center">
                    <span class="font-medium leading-6 text-gray-900 dark:text-neutral-300 text-md">Wire Instructions for Funding Account</span>
                  </div>

                  <div class="flex flex-wrap gap-5">
                    <!-- Receiving Bank -->
                    <div class="flex-grow">
                      <label for="custody_bank" class="input_label">Receiving Bank</label>
                      <div class="input_wrapper">
                        <input type="text" name="custody_bank" class="input" placeholder="Chase Bank" v-model="o.custody_bank_instructions" required />
                      </div>
                    </div>

                    <!-- Account Number -->
                    <div class="flex-grow">
                      <label for="custody_account_number" class="input_label">Account Number</label>
                      <div class="input_wrapper">
                        <input type="text" name="custody_account_number" class="input" placeholder="123456789" v-model="o.custody_account_number_instructions" required />
                      </div>
                    </div>

                    <!-- Routing Number -->
                    <div class="flex-grow">
                      <label for="custody_routing_number" class="input_label">Routing Number</label>
                      <div class="input_wrapper">
                        <input type="text" name="custody_routing_number" class="input" placeholder="123456789" v-model="o.custody_routing_number_instructions" required />
                      </div>
                    </div>

                    <!-- Reference -->
                    <div class="flex-grow">
                      <label for="custody_reference" class="input_label">Reference</label>
                      <div class="input_wrapper">
                        <input type="text" name="custody_reference" class="input" placeholder="NC-[YOUR PARTNER NAME] AND 123456789" v-model="o.custody_reference_instructions" required />
                      </div>
                    </div>

                    <!-- Address -->
                    <div class="w-full">
                      <label for="custody_address_instructions" class="!flex items-center gap-2 input_label">
                        Address
                      </label>
                      <div class="input_wrapper">
                        <textarea name="custody_address_instructions" rows="2" v-model="o.custody_address_instructions" class="input" required></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Accept Payment Methods -->
                <Switch v-model="o.accept_ach" label="Accept ACH Payment" description="Will this offering accept payment via ACH?" />

                <!-- Accept Check Payment -->
                <Switch v-model="o.accept_cc" label="Accept Credit Card" description="Will this offering accept payment via Credit Card?" />

                <!-- Accept Wire Payment -->
                <Switch v-model="o.accept_wire" label="Accept Wire Payment" description="Will this offering accept payment via wire transfer?" />
                <!-- Wire Instruction -->
                <div v-if="o.accept_wire" class="flex flex-col flex-wrap gap-5 p-5 rounded-lg shadow bg-gray-100 dark:bg-neutral-950">
                  <div class="flex gap-2 items-center">
                    <span class="font-medium leading-6 text-gray-900 dark:text-neutral-300 text-md">Wire Instructions</span>
                  </div>

                  <div class="flex flex-wrap gap-5">
                    <!-- Receiving Bank -->
                    <div class="flex-grow">
                      <label for="bank" class="input_label">Receiving Bank</label>
                      <div class="input_wrapper">
                        <input type="text" name="bank" class="input" placeholder="Chase Bank" v-model="o.bank_instructions" required />
                      </div>
                    </div>

                    <!-- Account Number -->
                    <div class="flex-grow">
                      <label for="account_number" class="input_label">Account Number</label>
                      <div class="input_wrapper">
                        <input type="text" name="account_number" class="input" placeholder="123456789" v-model="o.account_number_instructions" required />
                      </div>
                    </div>

                    <!-- Routing Number -->
                    <div class="flex-grow">
                      <label for="routing_number" class="input_label">Routing Number</label>
                      <div class="input_wrapper">
                        <input type="text" name="routing_number" class="input" placeholder="123456789" v-model="o.routing_number_instructions" required />
                      </div>
                    </div>

                    <!-- Reference -->
                    <div class="flex-grow">
                      <label for="reference" class="input_label">Reference</label>
                      <div class="input_wrapper">
                        <input type="text" name="reference" class="input" placeholder="NC-[YOUR PARTNER NAME] AND 123456789" v-model="o.reference_instructions" required />
                      </div>
                    </div>

                    <!-- Address -->
                    <div class="w-full">
                      <label for="address_instructions" class="!flex items-center gap-2 input_label">
                        Address
                      </label>
                      <div class="input_wrapper">
                        <textarea name="address_instructions" rows="2" v-model="o.address_instructions" class="input" required></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Accept Check Payment -->
                <Switch v-model="o.accept_check" label="Accept Check Payment" description="Will this offering accept payment via check?" />
                <!-- Check Instructions -->
                <div v-if="o.accept_check" class="flex flex-col flex-wrap gap-5 p-5 rounded-lg shadow bg-gray-100 dark:bg-neutral-950">
                  <div class="flex gap-2 items-center">
                    <span class="font-medium leading-6 text-gray-900 dark:text-neutral-300 text-md">Check Instructions</span>
                  </div>
                  <div class="flex flex-wrap gap-5">
                    <!-- UPS and Fedex -->
                    <div class="w-full">
                      <label for="fedex" class="!flex items-center gap-2 input_label">
                        Fedex or UPS
                      </label>
                      <div class="input_wrapper">
                        <textarea name="fedex" rows="2" v-model="o.fedex_instructions" class="input" required></textarea>
                      </div>
                    </div>
                    <!-- Regular Mail -->
                    <div class="w-full">
                      <label for="mail" class="!flex items-center gap-2 input_label">
                        Regular Mail
                      </label>
                      <div class="input_wrapper">
                        <textarea name="mail" rows="2" v-model="o.mail_instructions" class="input" required></textarea>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="flex items-center justify-end px-4 py-4 border-t gap-x-6 border-gray-900/10 sm:px-8 flex-grow">
            <button v-if="type == 'edit'" @click="open = true" type="button" class="button_red mr-auto">Archive</button>
            <button @click="handleBackButton()" type="button" class="button_secondary">Cancel</button>
            <button v-if="type == 'edit'" type="submit" class="button_primary">
              Save Changes & Publish
              <LoadGifButton v-if="isPublishing" />
            </button>
            <button v-else type="submit" class="button_primary">
              Save & Publish
              <LoadGifButton v-if="isPublishing" />
            </button>
          </div>

        </form>
      </div>

    </div>
  </div>
</template>

<script setup>
// Essentials
import { ref, watch, computed, onMounted } from 'vue'
import { v4 as uuidv4 } from 'uuid';
import { useUserStore } from '@/stores/user';
import { usePartnerStore } from '@/stores/partner';
import { useOfferingsStore } from '@/stores/offerings';
// Components
import SpinnerJump from '@/components/loading/SpinnerJump.vue'
import SlickDrag from '@/components/applicationui/SlickDrag.vue'
import Switch from '@/components/applicationui/Switch.vue'
import SelectMenu from '@/components/applicationui/SelectMenu.vue'
import Dropdown from '@/components/applicationui/Dropdown.vue'
import OperaScore from '@/components/ui/OperaScore.vue'
import InfoCircle from '@/components/ui/InfoCircle.vue'
import LoadGifButton from '@/components/loading/LoadGifButton.vue'
import DocumentsList from '@/components/ui/DocumentsList.vue';
// Libraries
import { formatMoney, truncateText, getBadgeClass } from '@/helper/helper'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { PhotoIcon, ChevronLeftIcon, PlusIcon, MinusIcon, ListBulletIcon } from '@heroicons/vue/24/solid'
import { ExclamationTriangleIcon, LinkIcon, TrashIcon, TagIcon, DocumentPlusIcon, EyeDropperIcon, ClipboardIcon } from '@heroicons/vue/24/outline'
// Stores
const partnerStore = usePartnerStore();
const userStore = useUserStore();
const offeringsStore = useOfferingsStore();
// Props
const props = defineProps({
  offering: {
    type: Object,
    required: true,
    default: null
  }
})
// Globals
const o = ref({});
const open = ref(false);
const loading = ref(true);
const uploadingDocument = ref(false);
const showScoreScetion = ref(false);
const showTapiSection = ref(false);
const offeringTypeOptions = ['Equity', 'Debt', 'Hybrid', 'Fund'];
const offeringStatusOptions = ['approved', 'pending', 'disapproved'];
const offeringVisibilityOptions = ['public', 'private', 'advisor', 'accredited'];
const offeringDocuments = ref([]);
const type = ref('create');
const minimum = ref('');
const maximum = ref('');
const target_amount = ref('');
const unit_price = ref('');
const isPublishing = ref(false);
const start_date = ref(null);
const end_date = ref(null);
// Mounted
onMounted(async () => {
  // Check Stores
  if (!userStore.user) await userStore.setUser();
  if (!partnerStore.partner) await partnerStore.setPartner();
  if (props.offering) {
    o.value = props.offering
    if (o.value.id) {
      type.value = 'edit'
      if (o.value.end_date) end_date.value = o.value.end_date.split("T")[0]
      if (o.value.start_date) start_date.value = o.value.start_date.split("T")[0]
      if (o.value.minimum) minimum.value = o.value.minimum.toString()
      if (o.value.maximum) maximum.value = o.value.maximum.toString()
      if (o.value.target_amount) target_amount.value = o.value.target_amount.toString()
      if (o.value.unit_price) unit_price.value = o.value.unit_price.toString()
      if (o.value.content) {
        o.value.content.forEach(async obj => {
          if (obj.type === 'image' && obj.name) {
            obj.url = await offeringsStore.getOfferingFile(o.value.id, o.value.tapi_offering_id, obj.name);
          }
        });
      }
      await setOfferingDocuments(o.value.id)
    }
  }
    // This case should hopefully never happen
  else o.value = { offeringType: 'Equity', status: 'pending', visibility: 'public', accept_ach: true, show_score: false }
  // Ready
  loading.value = false
})
// Computed
const filteredContent = computed(() => {
  if (!o.value.content) return []
  return o.value.content.filter(obj => obj.type == 'content' || obj.type == 'image')
});
const isSandbox = computed(() => {
  if (window.location.hostname == 'sandbox.operacore.com') return true
  return false
});

// Functions
async function handleOfferingDocumentUpload(event, offering_id) {
  uploadingDocument.value = true
  const file = event.target.files[0];
  if (!file) return;
  await offeringsStore.uploadOfferingDocumentSupabase(offering_id, file)
  await setOfferingDocuments(o.value.id)
  uploadingDocument.value = false
}

async function setOfferingDocuments(offering_id) {
  offeringDocuments.value = await offeringsStore.getOfferingDocumentsSupabase(offering_id)
}

async function deleteFile(file_name, offering_id) {
  await offeringsStore.deleteOfferingDocument(offering_id, file_name)
  await setOfferingDocuments(o.value.id)
}

// Simple Functions
const handleSubmit = () => {
  isPublishing.value = true
  o.value.target_amount = target_amount.value
  o.value.minimum = minimum.value
  o.value.maximum = maximum.value
  o.value.unit_price = unit_price.value
  o.value.start_date = getStartDate(start_date.value)
  o.value.end_date = getEndDate(end_date.value)
  emit('submitted', o.value)
}

const handleBackButton = () => {
  emit('back')
}

const copyOfferingLink = (offering_id) => {
  navigator.clipboard.writeText(`${window.location.origin}/public/${offering_id}`);
};

const restoreDefault = () => {
  o.value.gradient_start_color = partnerStore.partner.primary_color
  o.value.gradient_end_color = partnerStore.partner.primary_color_light
}

const archiveOffering = () => {
  open.value = false
  emit('archive', o.value)
}

const logoImageChanged = (event) => {
  o.value.logoImage = event.target.files[0]
  if (o.value.logoImage.type != 'image/jpeg' && o.value.logoImage.type != 'image/png') {
    o.value.logoUrl = {}
  } else {
    o.value.logoUrl = URL.createObjectURL(o.value.logoImage)
  }
}

const bannerImageChanged = (event) => {
  o.value.bannerImage = event.target.files[0]
  if (o.value.bannerImage.type != 'image/jpeg' && o.value.bannerImage.type != 'image/png') {
    o.value.bannerUrl = {}
  } else {
    o.value.bannerUrl = URL.createObjectURL(o.value.bannerImage)
  }
}

const handleContentImagesArrayChanged = (event, id) => {
  if (!event || (event.target.files[0].type != 'image/jpeg' && event.target.files[0].type != 'image/png')) {
    // Handle error (Show a error message here)
    return
  }

  const obj_index = o.value.content.findIndex(item => item.id === id)
  o.value.content[obj_index].name = event.target.files[0].name  
  o.value.content[obj_index].file = event.target.files[0]
  o.value.content[obj_index].url = URL.createObjectURL(event.target.files[0])
}

const handleCuratedInfoSelection = (selection) => {
  if (selection == 'start date') { o.value.key_values.push({ id: 'start-date', name: 'Raise Start Date', value: '(Generated by Opera)', type: 'curated' }); return }
  if (selection == 'end date') { o.value.key_values.push({ id: 'end-date', name: 'Raise End Date', value: '(Generated by Opera)', type: 'curated' }); return }
  if (selection == 'funded amount') { o.value.key_values.push({ id: 'funded-amount', name: 'Funded Amount', value: '(Generated by Opera)', type: 'curated' }); return }
  if (selection == 'pending amount') { o.value.key_values.push({ id: 'pending-amount', name: 'Pending Amount', value: '(Generated by Opera)', type: 'curated' }); return }
  if (selection == 'minimum investment') { o.value.key_values.push({ id: 'minimum-investment', name: 'Minimum Investment', value: '(Generated by Opera)', type: 'curated' }); return }
  if (selection == 'maximum investment') { o.value.key_values.push({ id: 'maximum-investment', name: 'Maximum Investment', value: '(Generated by Opera)', type: 'curated' }); return }
  if (selection == 'raise goal') { o.value.key_values.push({ id: 'raise-goal', name: 'Raise Goal', value: '(Generated by Opera)', type: 'curated' }); return }
  if (selection == 'security type') { o.value.key_values.push({ id: 'security-type', name: 'Security Type', value: '(Generated by Opera)', type: 'curated' }); return }
  if (selection == 'price per share') { o.value.key_values.push({ id: 'unit-price', name: 'Price per Share', value: '(Generated by Opera)', type: 'curated' }); return }

  if (selection == 'gross fees') { o.value.key_values.push({ name: 'Gross Fees', value: '', type: 'text' }); return }
  if (selection == 'net fees') { o.value.key_values.push({ name: 'Net Fees', value: '', type: 'text' }); return }
  if (selection == 'ticker') { o.value.key_values.push({ name: 'Ticker', value: '', type: 'text' }); return }
  if (selection == 'market sector') { o.value.key_values.push({ name: 'Market Sector', value: '', type: 'text' }); return }
}

const getStartDate = (inputDate) => {
  var dateObject = new Date()
  if (inputDate) dateObject = new Date(inputDate)
  return dateObject
}

const getEndDate = (inputDate) => {
  var dateObject = new Date('01-01-2099')
  if (inputDate) dateObject = new Date(inputDate)
  return dateObject
}

// Emits
const emit = defineEmits(['submitted', 'back', 'archive'])

watch(() => o.value.is_custody_only, () => {
  if (o.value.is_custody_only) {
    o.value.accept_ach = false
    o.value.accept_cc = false
    o.value.accept_wire = false
    o.value.accept_check = false
  }
}, { deep: true })

watch(() => o.value.accept_ach, () => {
  if (o.value.accept_ach) o.value.is_custody_only = false
}, { deep: true })

watch(() => o.value.accept_cc, () => {
  if (o.value.accept_cc) o.value.is_custody_only = false
}, { deep: true })

watch(() => o.value.accept_wire, () => {
  if (o.value.accept_wire) o.value.is_custody_only = false
}, { deep: true })

watch(() => o.value.accept_check, () => {
  if (o.value.accept_check) o.value.is_custody_only = false
}, { deep: true })

watch(() => o.value.use_regcf_protocols, () => {
  if (o.value.use_regcf_protocols) {
    o.value.show_forum = true
    o.value.visibility = 'public'
  }
}, { deep: true })

watch(() => o.value.show_forum, () => {
  if (o.value.use_regcf_protocols && !o.value.show_forum) o.value.show_forum = true
}, { deep: true })

watch(() => o.value.links, (newLinksArray) => {
  if (!newLinksArray) return
  if (newLinksArray?.length > 0) {
    // If the links array is not empty and a links object already exists in the additional content array do nothing
    if (o.value.content.some(obj => obj.type === 'links')) return
    // If the links array is not empty and a links object does not exist in the additional content array add it
    o.value.content.push({ id: 'links', name: 'Links Section', type: 'links' })
  }
  // If the links array is empty remove the links object from the additional content array
  if (newLinksArray?.length === 0) o.value.content = o.value.content.filter(obj => obj.type !== 'links')
}, { deep: true })

watch(() => o.value.key_values, (newKeyValue) => {
  if (!newKeyValue) return
  if (newKeyValue?.length > 0) {
    // If the key_values array is not empty and a key_values object already exists in the additional content array do nothing
    if (o.value.content.some(obj => obj.type === 'key_values')) return
    // If the links array is not empty and a links object does not exist in the additional content array add it
    o.value.content.push({ id: 'key_values', name: 'Overview Key Info Section', type: 'key_values' })
  }
  // If the links array is empty remove the links object from the additional content array
  if (newKeyValue?.length === 0) o.value.content = o.value.content.filter(obj => obj.type !== 'key_values')
}, { deep: true })
</script>