<template>
  <div class="shadow ring-1 ring-black ring-opacity-5 background relative">
    <!-- Popups -->
    <NotificationPopup ref="notification" :type="notificationType" :title="notificationTitle" :text="notificationText" />
    <!-- Popups -->

    <div class="text-base font-semibold leading-6 text_color_primary p-4 border-gray-300 border-b-[1px] dark:border-b-[1px] dark:border-neutral-700 bg-gray-100 dark:bg-neutral-800 rounded-t-lg">
      <!-- Header -->
      <div class="flex items-center justify-between flex-wrap gap-y-3">
        <span class="flex items-center flex-wrap gap-2">
          <div>Accreditation Documents</div>
          <div :class="getBadgeClass(accredited_status)" class="flex items-center gap-1">
            {{ capitalizeFirstLetter(accredited_status) }}
            <CheckBadgeIcon v-if="accredited_status == 'Accredited' || accredited_status == 'Manually Accredited' || accredited_status == 'Verified Accredited'" class="w-4 h-4" />
            <ClockIcon v-else-if="accredited_status == 'Under Review'" class="w-4 h-4" />
            <ExclamationTriangleIcon v-else-if="accredited_status == 'Action Required'" class="w-4 h-4" />
            <XCircleIcon v-else-if="accredited_status == 'Not Accredited'" class="w-4 h-4" />
          </div>
          <div v-if="props?.account?.accounts_protected?.ai_notes">
            <InfoCircle :info="props?.account?.accounts_protected?.ai_notes" />
          </div>
        </span>
        <div v-if="accredited_status == 'Accredited' || accredited_status == 'Manually Accredited' || accredited_status == 'Verified Accredited'" class="button_primary"
          @click="getAccreditationLetter(props?.account?.id, props?.account?.tapi_account_id)">
          Accreditation Verification Letter
          <LoadGifButton v-if="loadingAiLetter == true" />
          <ArrowDownTrayIcon v-else class="w-4 h-4" />
        </div>
        <label v-else for="accredited-doc" type="button" class="button_primary">
          Upload
          <LoadGifButton v-if="uploading == true" />
          <CloudArrowUpIcon v-else class="size-5" />
        </label>


        <!-- Hidden input field -->
        <input name="accredited-doc" id="accredited-doc" type="file" class="sr-only" accept="*"
          @change="handleUpload($event, props?.user_id, props?.account?.tapi_account_id, props?.account?.user_id, props?.account?.accounts_protected?.ai_request_id, props?.account?.id)" />
      </div>
    </div>

    <!-- Loading -->
    <div v-if="loading" class="h-20 rounded-b-lg w-full flex justify-center items-center dark:bg-neutral-900">
      <div class="w-10 h-10">
        <SpinnerBounce />
      </div>
    </div>

    <!-- Documents list -->
    <ul v-else-if="documents.length > 0" role="list" class="divide-y divide-gray-200 dark:divide-neutral-700 dark:bg-neutral-900 max-h-72 overflow-y-scroll rounded-b-lg bg-white">
      <li v-for="doc in documents" :key="doc.id" class="flex justify-between sm:gap-x-6 py-3 mx-3 first:pt-3 last:pb-3">
        <div class="flex min-w-0 gap-x-4 items-center">
          <div class="bg-white ring-1 ring-gray-300 p-1.5 rounded-lg text-xs dark:bg-neutral-800 dark:ring-neutral-700">
            <DocumentTextIcon class="text-operacolor w-5 h-5" />
          </div>
          <div class="min-w-0 flex items-center w-40">
            <p class="text-sm font-semibold leading-6 text-gray-900 dark:text-neutral-300 truncate">{{ doc.name }}</p>
          </div>
        </div>
        <div class="shrink-0 flex flex-col items-start justify-center w-40">
          <div class="text-sm text-gray-500 dark:text-neutral-400">{{ formatDate(doc.created_at) }}</div>
        </div>
        <div class="flex gap-5 items-center">
          <div @click="downloadFile(`${doc.userFolder}/accredited/${doc.accountFolder}/${doc.name}`)"
            class="dark:text-[rgb(102,183,237)] text-[rgba(1,108,178)] px-3.5 py-1.5 rounded-md bg-[rgba(1,108,178,0.1)] flex items-center gap-2 cursor-pointer hover:bg-[rgba(1,108,178)] hover:text-white transition ease-in-out duration-700">
            Download
            <ArrowDownTrayIcon class="w-4 h-4" />
          </div>
          <div class="mr-2">
            <TrashIcon class="w-6 h-6 text-neutral-900 dark:text-neutral-400 hover:text-red-600 dark:hover:text-red-600 transition ease-in-out duration-500 cursor-pointer"
              @click="archiveFile(doc, props?.account?.id, props?.account?.user_id, props?.user_id, props?.account?.tapi_account_id)" />
          </div>
        </div>
      </li>
    </ul>
    <div v-else class="p-4 flex justify-center secondary_header rounded-b-lg dark:bg-neutral-900">No accreditation documents</div>
  </div>
</template>

<script setup>
// Essentials
import { ref, onMounted } from 'vue';
import { useAccountsStore } from '@/stores/accounts';
// Components
import InfoCircle from '@/components/ui/InfoCircle.vue';
import LoadGifButton from '@/components/loading/LoadGifButton.vue'
import NotificationPopup from '@/components/popups/NotificationPopup.vue'
// Libraries
import { capitalizeFirstLetter, formatDate, getBadgeClass } from '@/helper/helper';
import { ArrowDownTrayIcon, TrashIcon, CloudArrowUpIcon, CheckBadgeIcon, ClockIcon, ExclamationTriangleIcon, XCircleIcon } from '@heroicons/vue/24/outline'
import { DocumentTextIcon } from '@heroicons/vue/24/solid';
import SpinnerBounce from '@/components/loading/SpinnerBounce.vue';
// Props
const props = defineProps({
  account: { type: Object, required: true, default: () => null }, //Expects account object
  user_id: { type: String, required: true, default: null }, //Expects the current user id
  partner_type: { type: String, required: false, default: '' }
})
// Stores
const accountsStore = useAccountsStore();
// Globals
const loading = ref(true);
const uploading = ref(false);
const loadingAiLetter = ref(false)
const notification = ref(null);
const notificationType = ref('success');
const notificationTitle = ref('File Uploaded');
const notificationText = ref('Your file was uploaded successfully, refresh this page to access the new file');
const documents = ref([]);
const accredited_status = ref(props?.account?.accounts_protected?.accredited_status);

// Mounted
onMounted(async () => {
  // Set documents array
  await setAccreditationDocuments(props?.account?.user_id, props?.user_id, props?.account?.id, props?.account?.tapi_account_id);
  // Ready
  loading.value = false;
});

// Functions
async function setAccreditationDocuments(account_user_id, current_user_id, account_id, tapi_account_id) {
  // Set default values
  let docs = []
  let docs2 = []
  // Get all accredited documents
  docs = await accountsStore.getAccreidationDocuments(account_user_id, account_id, tapi_account_id)
  if (account_user_id != current_user_id) docs2 = await accountsStore.getAccreidationDocuments(current_user_id, account_id, tapi_account_id)
  if (!docs && !docs2) { notify('failure', 'Sorry', 'We were unable to load your files. Please try again later, if the issue persists please contact tech support'); return }
  // Set documents
  documents.value = docs.concat(docs2);
}

async function getAccreditationLetter(account_id, tapi_account_id) {
  loadingAiLetter.value = true;
  const accreditationLetter = await accountsStore.getAccreditationLetter(account_id, tapi_account_id);
  if (!accreditationLetter) { notify('failure', 'Sorry', 'We were unable to download your accreditation letter. Please try again later, if the issue persists please contact tech support'); return }
  window.open(accreditationLetter, '_blank');
  loadingAiLetter.value = false;
}

async function handleUpload(event, current_user_id, tapi_account_id, account_user_id, ai_request_id, account_id) {
  uploading.value = true;
  const newFile = event.target.files[0];
  // Request AI Verification from TAPI if not requested
  if (!ai_request_id) {
    const successful = await accountsStore.requestAiVerification(account_id, tapi_account_id);
    if(!successful) { notify('failure', 'Sorry', 'We were unable to request your accreditation check. Please try again later, if the issue persists please contact tech support'); return }
  }
  // If already requested AI Verification from TAPI update the request status to 'New Info Added'
  else {
    const successful = await accountsStore.updateAiRequest(account_id, ai_request_id);
    if(!successful) { notify('failure', 'Sorry', 'We were unable to update your accreditation status. Please try again later, if the issue persists please contact tech support'); return }
  }
  // Upload the file to TAPI, don't wait for the response
  accountsStore.uploadAccreditationDocumentToTapi(account_id, tapi_account_id, newFile);
  // Upload the file to Supabase
  const successful = await accountsStore.uploadAccreditationDocumentToSupabase(current_user_id, account_id, newFile)
  // Set loading to false
  uploading.value = false;
  // If unsuccessful notify the user
  if(!successful) { notify('failure', 'Sorry', 'We were unable to upload your file. Please try again later, if the issue persists please contact tech support'); return }
  // Notify the user of the successful upload
  notify('success', 'File Uploaded', 'Your file was uploaded successfully, you may need to refresh this page to view the new file.')
  // Set accreditation status to 'Under Review'
  accredited_status.value = 'Under Review'
  // Reset all files (to include newly uploaded file)
  setAccreditationDocuments(account_user_id, current_user_id, account_id, tapi_account_id)
}

async function downloadFile(filePath) {
  const url = await accountsStore.downloadAccreditationFile(filePath)
  window.open(url, '_blank');
}

async function archiveFile(doc, account_id, account_user_id, current_user_id, tapi_account_id) {
  const timestamp = Date.now();
  const successful = await accountsStore.archiveAccreditationFile(`${doc.userFolder}/accredited/${account_id}/${doc.name}`, `${doc.userFolder}/archived/${account_id}/${timestamp}_${doc.name}`)
  if (!successful) { notify('failure', 'Sorry', 'We were unable to archive your file. Please try again later, if the issue persists please contact tech support'); return }
  notify('success', 'File Archived', 'Your file was archived successfully')
  setAccreditationDocuments(account_user_id, current_user_id, account_id, tapi_account_id)
}

// Simple Functions
const notify = (type, title, text) => {
  notificationType.value = type
  notificationTitle.value = title
  notificationText.value = text
  notification.value.show()
}
</script>