<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <!-- Popups -->
    <AlphaFlow v-if="showFlow" v-model="showFlow" need="create-account" @completed="handleAccountCreated" @closed="showFlow = false" />

    <NotificationPopup :type="notificationType" :title="notificationTitle" :text="notificationText" ref="notification" />

    <AddGroup ref="group" :user_id="userStore.user.id" @update-group="handleNewGroup" />
    <!-- End Popups -->

    <!-- Loading -->
    <div v-if="loading" class="w-full md:h-[70vh] h-[50vh] flex items-center justify-center">
      <div class="w-32 h-32 -mt-10">
        <SpinnerFlip />
      </div>
    </div>

    <!-- Ready -->
    <div v-else>

      <div v-if="showAccount">
        <!-- Sub Navigation -->
        <div>
          <!-- Back -->
          <div>
            <button class="button_secondary -mt-5 mb-5" @click="showAccount = false">
              <ChevronLeftIcon class="w-4 h-4 text-black dark:text-white -mx-1" />
              Back
            </button>
          </div>
          <!-- Mobile -->
          <div class="md:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <select id="tabs" name="tabs" class="block w-full rounded-md border-border focus:border-operacolor focus:ring-operacolor" @change="setTab($event.target.value)">
              <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
            </select>
          </div>
          <!-- Desktop -->
          <div class="hidden md:block">
            <div class="border-b border-gray-300 dark:border-neutral-500">
              <nav class="flex -mb-px space-x-8" aria-label="Tabs">
                <div v-for="tab in tabs" :key="tab.name" @click="setTab(tab.name)"
                  :class="[tab.current ? 'border-operacolor text-operacolor' : 'border-transparent text-gray-700 dark:text-neutral-100 dark:hover:text-operacolorlight hover:border-operacolorlight hover:text-operacolorlight', 'cursor-pointer group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium']"
                  :aria-current="tab.current ? 'page' : undefined">
                  <component :is="tab.icon"
                    :class="[tab.current ? 'text-operacolor' : 'text-gray-700 dark:text-neutral-100 dark:hover:text-operacolorlight group-hover:text-operacolorlight', '-ml-0.5 mr-2 h-5 w-5']"
                    aria-hidden="true" />
                  <span>{{ tab.name }}</span>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div class="mt-10">
          <AccountOverview v-if="currentTab == 'Overview'" :account="account" />
          <AccountPositions v-else-if="currentTab == 'Positions'" :account="account" />
          <AccountTransactions v-else-if="currentTab == 'Transactions'" :account="account" />
          <AccountTransfer v-else-if="currentTab == 'Transfers'" :account="account" />
          <AccountDocuments v-else-if="currentTab == 'Documents'" :account="account" />
        </div>

      </div>

      <div v-else>
        <!-- Empty State -->
        <div v-if="!accountsStore?.myAccounts || accountsStore?.myAccounts?.length <= 0">
          <div class="text-center">
            <svg class="w-12 h-12 mx-auto text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
            </svg>
            <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-neutral-300">No Accounts</h3>
            <p class="mt-1 text-sm text-gray-500">Get started by creating a new account.<br />You must fill out your profile information first.</p>
            <div class="mt-6">
              <div class="button_primary" @click="showFlow = true">
                Create Account
                <PlusIcon class="w-5 h-5" aria-hidden="true" />
              </div>
            </div>
          </div>
        </div>

        <!-- Has Accounts -->
        <div v-else>
          <!-- Header -->
          <div>
            <div class="border-b border-gray-200 pb-5 mb-5 flex items-center justify-between gap-5 flex-wrap dark:border-neutral-700">
              <!-- Tab Header -->
              <h3 class="primary_header">Accounts</h3>

              <div class="flex items-center gap-4">
                <!-- Search -->
                <div class="flex items-center gap-2">
                  <div>
                    <div class="relative rounded-md">
                      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <MagnifyingGlassIcon class="w-4 h-4 text-gray-400 dark:text-neutral-400" aria-hidden="true" />
                      </div>
                      <input type="text" name="search" id="search" class="pl-8 input" placeholder="Quick Search" v-model="query" />
                    </div>
                  </div>
                </div>
                <!-- Invite User -->
                <button @click="handleNewAccount" type="button" class="button_primary">
                  New Account
                  <PlusIcon class="w-4 h-4" />
                </button>
              </div>
            </div>
            <!-- Toggle -->
          </div>

          <!-- Accounts Table -->
          <AccountsTable :accounts="filteredAccounts" @click-primary="setAccount" @click-secondary="handeGroupClicked" />
        </div>
      </div>

    </div>

  </div>
</template>

<script setup>
// Essentials
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { useAccountsStore } from '@/stores/accounts';
// Components
import NotificationPopup from '@/components/popups/NotificationPopup.vue'
import SpinnerFlip from '@/components/loading/SpinnerFlip.vue'
import AlphaFlow from '@/components/flows/AlphaFlow.vue'
import AddGroup from '@/components/popups/AddGroup.vue'
import AccountsTable from '@/components/widgets/AccountsTable.vue'
import AccountOverview from '@/components/accounts/AccountOverview.vue';
import AccountPositions from '@/components/accounts/AccountPositions.vue';
import AccountTransactions from '@/components/accounts/AccountTransactions.vue';
import AccountTransfer from '@/components/accounts/AccountTransfer.vue';
import AccountDocuments from '@/components/accounts/AccountDocuments.vue';
// Libraries
import { PlusIcon } from '@heroicons/vue/20/solid'
import { ArrowLongRightIcon, MagnifyingGlassIcon, BuildingOfficeIcon, ChartBarIcon, ListBulletIcon, ArrowsRightLeftIcon, DocumentChartBarIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline'
// Stores
const route = useRoute()
const userStore = useUserStore();
const accountsStore = useAccountsStore();
// Globals
const loading = ref(true);
const showFlow = ref(false);
const query = ref('');
const notification = ref(null)
const notificationType = ref('success');
const notificationTitle = ref('Success');
const notificationText = ref('');
const group = ref(null)
const account = ref(null)
// accounts
const tabs = ref([
  { name: 'Overview', icon: ChartBarIcon, current: true },
  { name: 'Positions', icon: ListBulletIcon, current: false },
  { name: 'Transactions', icon: ArrowsRightLeftIcon, current: false },
  { name: 'Transfers', icon: BuildingOfficeIcon, current: false },
  { name: 'Documents', icon: DocumentChartBarIcon, current: false, },
]);
const showAccount = ref(false)
const currentTab = ref('Overview')
// Mounted
onMounted(async () => {
  // Check if user
  if (!userStore.user) { loading.value = false; return }
  // Set Accounts
  await accountsStore.setMyAccounts()
  // If the query parameters are passed go to the passed page for the passed account
  routeToTab()
  // Ready
  loading.value = false;
});

// Computed
const filteredAccounts = computed(() => {
  return query.value === ''
    ? accountsStore.myAccounts
    : accountsStore.myAccounts.filter((account) => {
      if (account.name && account.name.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (account.type && account.type.toLowerCase().includes(query.value.toLowerCase())) return true;
      if (account.group && account.group.toLowerCase().includes(query.value.toLowerCase())) return true;
    });
});

// Functions
async function handleNewGroup({ group, selectedAccount }) {
  // Check if group or account is empty
  if (!group || !selectedAccount) return;
  // Update Group
  const successful = await accountsStore.updateGroup(group, selectedAccount.id);
  if (!successful) notify('failure', 'Sorry', 'Something went wrong. Please try again. If the issue persists, contact tech support');
  // Refresh
  await accountsStore.setMyAccounts()
}

// Simple Funtions
const routeToTab = () => {
  if (route.query.page) {
    if (route.query.account_id) {
      const passedAccount = accountsStore.myAccounts.find(account => account.id === route.query.account_id);
      if (passedAccount) {
        setTab(route.query.page)
        setAccount(passedAccount)
      }
    }
  }
}

const setTab = (tab) => {
  tabs.value.forEach(item => { item.current = item.name === tab });
  currentTab.value = tab
}

const handeGroupClicked = (account) => {
  group.value.show(account)
}

const setAccount = (a) => {
  account.value = a
  showAccount.value = true
}

const handleNewAccount = () => {
  if (!userStore.user) {
    notify('failure', 'Sorry', 'Something went wrong. Please try again. If the issue persists, contact tech support');
    return;
  }

  if (!userStore.user.party_id) {
    notify('failure', 'Sorry', 'Something went wrong. Please try again. If the issue persists, contact tech support');
    return;
  }

  showFlow.value = true;
}

const handleAccountCreated = () => {
  showFlow.value = false;
  notify('success', 'Success', 'Account created successfully');
  accountsStore.setMyAccounts()
}

const notify = (type, title, text) => {
  notificationType.value = type
  notificationTitle.value = title
  notificationText.value = text
  notification.value.show()
}
</script>
